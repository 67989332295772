import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { ImgOrSvg, Link } from 'components';

const VerticalItem = ({
  data: {
    title,
    field_description,
    field_discover_more_link,
    field_image: { alt },
    relationships: { field_image },
  },
  isEven,
}) => (
  <div
    itemScope
    itemType="https://schema.org/Thing"
    className={CN('vertical-list__item', isEven ? 'even' : 'odd')}
  >
    <div className="vertical-list__image">
      <ImgOrSvg itemProp="image" image={field_image} className="vertical-image" alt={alt} />
    </div>
    <div className={CN('vertical-list__description')}>
      <h3 itemProp="name">{title}</h3>
      <div itemProp="description" className="vertical-list__description__text">
        {parser(sanitize(field_description.processed))}
      </div>
      {field_discover_more_link && (
        <div className="vertical-button">
          <Link className="btn--arrow" to={field_discover_more_link.uri}>
            {field_discover_more_link.title}
          </Link>
        </div>
      )}
    </div>
  </div>
);

VerticalItem.propTypes = {
  isEven: PropTypes.number,
  data: PropTypes.shape({
    title: PropTypes.string,
    field_description: PropTypes.object,
    field_discover_more_link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
  }).isRequired,
};

VerticalItem.defaultProps = {
  isEven: false,
};

export default VerticalItem;
