import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

const FeatureItem = ({ data: { field_title, field_description }, index }) => (
  <div className={`features-item ${index % 2 ? 'even' : 'odd'}`}>
    <h3>{field_title}</h3>
    {field_description && (
      <div className="description">{parser(sanitize(field_description.processed))}</div>
    )}
  </div>
);

FeatureItem.propTypes = {
  data: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    index: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
};

export default FeatureItem;
