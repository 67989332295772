import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { sendData } from 'api';
import { trackFormSuccess, validate } from 'utils';
import { FAILED_FORM_MESSAGE } from 'globals';

import { FormPopUp } from 'components';

const EmailForm = ({ successMessage, formId, file, title, buttonText, description }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handlePopupClose = () => setPopupMessage('');

  const onChange = ({ target: { value } }) => {
    setError('');
    setEmail(value);
    setPopupMessage('');
  };

  const onBlur = ({ target: { value } }) => {
    setError(validate(value, 'email'));
    setPopupMessage('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(validate(email, 'email'));

    if (error) {
      setError('Please fill the form correctly');
      setLoading(false);
      return;
    }

    const emailData = {
      webform_id: formId,
      email,
      file_id: file?.drupal_internal__fid || null,
      subject_title: title?.toLowerCase() || null,
    };

    sendData(emailData)
      .then(() => {
        setEmail('');
        setError('');
        trackFormSuccess(formId);
        setPopupMessage(successMessage);
      })
      .catch(() => {
        setPopupMessage(FAILED_FORM_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={onSubmit} className="anyforsoft-form subscribe">
        {description && <div className="description">{description}</div>}
        <div className="actions">
          <div
            className={CN('form-item item-type-input', {
              error,
            })}
          >
            <input value={email} type="text" name="email" onChange={onChange} onBlur={onBlur} />
            {error && <div className="error-message">{error}</div>}
            <label
              className={CN({
                fixed: email,
              })}
            >
              Your Email
            </label>
            <div className="on-focus" />
          </div>
          <button
            className="submit animation-helper"
            type="submit"
            value="Subscribe"
            disabled={isLoading}
          >
            <span className="animation-event" />
            <span className="helper-1">{buttonText}</span>
          </button>
        </div>
      </form>
      {popupMessage && <FormPopUp message={popupMessage} handlePopupClose={handlePopupClose} />}
    </>
  );
};

EmailForm.propTypes = {
  successMessage: PropTypes.string,
  formId: PropTypes.string,
  file: PropTypes.shape({
    drupal_internal__fid: PropTypes.number,
    filename: PropTypes.string,
    filesize: PropTypes.number,
    localFile: PropTypes.shape({
      extension: PropTypes.string,
    }),
    uri: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  buttonText: PropTypes.string,
  description: PropTypes.string,
};

export default EmailForm;
