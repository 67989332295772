import axios from 'axios';

const GEO_API_KEY = 'dEa2phKHsXqUXR0oUhYF';
const GEO_API_URL = 'https://extreme-ip-lookup.com/json/';

export const getGeoInfo = () =>
  axios.get(GEO_API_URL, {
    params: {
      key: GEO_API_KEY,
    },
  });

export const getGeoCountry = async () =>
  axios.get('https://reallyfreegeoip.org/json/').then((response) => response.data.country_name);

export const getAnimationJsonData = (data) =>
  axios.get(data).then((response) => ({ data: response.data, status: response.status }));

export const sendData = async (data) =>
  axios.post(`${process.env.GATSBY_SSL_URL}webform_rest/submit`, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
