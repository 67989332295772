import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { Link } from 'components';

import { Arrow } from 'icons';

const AlternativeStoryHeader = ({
  data: {
    title,
    field_headline,
    field_description,
    field_country,
    field_industry,
    field_discover_more_link,
    field_industry_link,
  },
}) => (
  <div className="story-header--alternative">
    <span className="story-case">Case Study</span>
    <div className="story-info">
      <div className="main-info">
        <h1 className="main-info-title">{field_headline}</h1>
        {field_description && (
          <div className="main-info-description">
            {parser(sanitize(field_description.processed))}
          </div>
        )}
        {field_discover_more_link && (
          <Link to={field_discover_more_link.uri} className="link-with-arrow">
            <>
              {field_discover_more_link.title}
              <Arrow color="#00786E" />
            </>
          </Link>
        )}
      </div>
      <div className="additional-info">
        <div className="additional-info-content">
          <span className="additional-info-title">Client</span>
          <span className="additional-info-text">{title}</span>
        </div>
        <div className="additional-info-content">
          <span className="additional-info-title">Country</span>
          <span className="additional-info-text">{field_country}</span>
        </div>
        <div className="additional-info-content">
          <span className="additional-info-title">Industry</span>
          <Link to={field_industry_link?.uri || '<nolink>'} className="additional-info-text">
            {field_industry}
          </Link>
        </div>
      </div>
    </div>
  </div>
);

AlternativeStoryHeader.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    field_headline: PropTypes.string.isRequired,
    field_country: PropTypes.string.isRequired,
    field_industry: PropTypes.string.isRequired,
    field_industry_link: PropTypes.object,
    field_discover_more_link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    field_description: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }).isRequired,
};

export default AlternativeStoryHeader;
