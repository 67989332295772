const getRandomNumber = (min, max) => Math.round(Math.random() * (max - min) + min);

const generateCaptcha = () => {
  const x = getRandomNumber(1, 5);
  const y = getRandomNumber(1, 5);
  const total = x + y;

  return {
    x,
    y,
    total,
  };
};

export default generateCaptcha;
