import React from 'react';
import PropTypes from 'prop-types';

import { sanitize, parser } from 'utils';

import { Form } from 'components';

const CTAWithForm = ({ webforms, dataItem }) => (
  <div className="paragraph-cta-form">
    <div className="paragraph-cta-form__left">
      {dataItem.field_subtitle && <div className="subtitle">{dataItem.field_subtitle}</div>}
      <h3 className="title">{parser(sanitize(dataItem.field_title))}</h3>

      {dataItem.field_description && (
        <div className="description">{parser(sanitize(dataItem.field_description.value))}</div>
      )}
    </div>

    <div className="paragraph-cta-form__right">
      <div className={`item wrapper-${dataItem.field_contact_us} `}>
        <Form
          formId={dataItem.field_contact_us.drupal_internal__target_id}
          webforms={webforms}
          inputId="privacy_policy-secondary"
          btnText="Send"
          fileBtnText="Attach files"
          withRemoveFileBtn
        />
      </div>
    </div>
  </div>
);

CTAWithForm.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    field_subtitle: PropTypes.string,
    field_contact_us: PropTypes.object,
  }),
  webforms: PropTypes.object,
};

export default CTAWithForm;
