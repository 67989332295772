import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';

const Functionality = ({
  dataItem: {
    field_title,
    field_functionality,
    internal: { type },
  },
}) => (
  <div className="functionality">
    <div className={type}>
      <div className="paragraph__functionality-top">
        <h2>{field_title}</h2>
      </div>
      <div className="paragraph__functionality-bottom">
        {field_functionality &&
          field_functionality.map((item) => (
            <div key={uid(item)} className="functionality-item">
              {item}
            </div>
          ))}
      </div>
    </div>
  </div>
);

Functionality.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_functionality: PropTypes.arrayOf(PropTypes.string),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default Functionality;
