import React from 'react';
import PropTypes from 'prop-types';

import { scrollToHash } from 'utils';

import { Link } from 'components';

const Navigation = ({ primaryMenu }) => {
  const handleClick = (url) => () => {
    if (url.includes('#')) {
      scrollToHash(url.replace('/', ''), -150);
    }
  };

  return (
    <nav className="main-menu__wrapper">
      <ul className="main-menu">
        {primaryMenu.map(
          ({ link }) =>
            link.enabled && (
              <li key={link.title} className="main-menu__item_main" role="presentation">
                <Link to={link.url} onClick={handleClick(link.url)} className="main-menu__link">
                  {link.title}
                </Link>
              </li>
            ),
        )}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  primaryMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Navigation;
