import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import { ScrollBar } from 'components';

const parseOptions = {
  replace: ({ name, children }) => {
    switch (name) {
      case 'table':
        return (
          <ScrollBar>
            <table>{domToReact(children)}</table>
          </ScrollBar>
        );
      case 'blockquote':
        return (
          <blockquote>
            <div className="quote-wrap">
              <span className="quote-icon" />
              {domToReact(children)}
            </div>
          </blockquote>
        );
      default:
        return children;
    }
  },
};

const parser = (content) => parse(content, parseOptions);
export default parser;
