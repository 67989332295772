import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components';

const BlogBannerWithButton = ({
  dataItem: {
    field_link: { uri, title },
    internal: { type },
    field_title,
  },
}) => (
  <div className={type}>
    <div id={type} className="anchor-for-menu" />
    <div className="banner-info">
      {field_title && <div className="banner-title">{field_title}</div>}
      {uri && (
        <div className="banner-link">
          <Link to={uri} className="btn white">
            {title}
          </Link>
        </div>
      )}
    </div>
  </div>
);

BlogBannerWithButton.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_link: PropTypes.shape({
      uri: PropTypes.string,
      title: PropTypes.string,
    }),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default BlogBannerWithButton;
