import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg, Link } from 'components';

const TechnologyStackItem = ({
  data: {
    name,
    field_technology_image: { alt },
    relationships: { field_technology_image },
    field_link,
  },
}) => (
  <div className="technology-item">
    {field_link && <Link to={field_link.uri} className="technology-stuck-discover-more" />}
    <div className="box-item">
      <div className="image">
        <ImgOrSvg
          image={field_technology_image}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={alt}
        />
      </div>
      <span className="technology-name">{name}</span>
    </div>
  </div>
);

TechnologyStackItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    field_technology_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_technology_image: PropTypes.object,
    }),
    field_link: PropTypes.object,
  }),
};

export default TechnologyStackItem;
