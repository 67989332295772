import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';

import { parser, sanitize } from 'utils';
import { useForceUpdate } from 'hooks';

import { Arrow } from 'icons';
import { ImgOrSvg, Link } from 'components';

const ServiceItem = ({
  data: {
    title,
    field_description,
    field_image: { alt },
    relationships: { field_image },
    field_discover_more_link,
  },
}) => {
  // Used as force component update (related with TextTruncate initial render bug)
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <div itemScope itemType="https://schema.org/Service" className="service-item">
      <div className="service-item-background-element" />

      {field_image && (
        <div className="service-icon-box">
          <ImgOrSvg itemProp="image" image={field_image} className="service-icon" alt={alt} />
        </div>
      )}

      <div className="service-title">
        <TextTruncate
          containerClassName="title"
          line={2}
          element="h3"
          truncateText=" …"
          text={title}
        />
      </div>

      <div itemProp="description" className="service-description">
        {field_description?.processed && <div>{parser(sanitize(field_description.processed))}</div>}
      </div>

      {field_discover_more_link && (
        <div className="service-discover-more">
          <Link to={field_discover_more_link.uri}>
            {field_discover_more_link.title || 'See More'} <Arrow />
          </Link>
        </div>
      )}
    </div>
  );
};

ServiceItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    field_description: PropTypes.object,
    field_discover_more_link: PropTypes.object,
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }),
};

export default ServiceItem;
