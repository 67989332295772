import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/Link';

import { Arrow } from 'icons';

const CaseSummaryTags = ({ field_case_summary_tags, field_team, field_year_launched }) => (
  <div className="case-summary-tags">
    {field_case_summary_tags.map(
      ({ field_title, field_discover_more, relationships: { field_tags } }) => (
        <div className="case-summary-tag" key={field_title}>
          <h2 className="case-summary-tag-title title">{field_title}</h2>
          <div className="case-summary-tag-names">
            {field_tags.map(({ field_discover_more: { title, uri } }) => (
              <Link className="case-summary-tag-name" key={title} to={uri}>
                {title}
              </Link>
            ))}
          </div>
          {field_discover_more && (
            <div className="case-summary-tag-link">
              <Link className="link-with-arrow" to={field_discover_more.uri}>
                <>
                  {field_discover_more.title}
                  <Arrow color="#00786E" />
                </>
              </Link>
            </div>
          )}
        </div>
      ),
    )}
    {field_team && field_year_launched && (
      <div className="case-summary-info-wrapper">
        <div className="case-summary-info">
          <span className="case-summary-info-title title">Team</span>
          <span className="case-summary-info-text">{field_team}</span>
        </div>
        <div className="case-summary-info">
          <span className="case-summary-info-title title">Year Launched</span>
          <span className="case-summary-info-text">{field_year_launched}</span>
        </div>
      </div>
    )}
  </div>
);

CaseSummaryTags.propTypes = {
  field_case_summary_tags: PropTypes.arrayOf(
    PropTypes.shape({
      field_title: PropTypes.string.isRequired,
      field_discover_more: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        field_tags: PropTypes.arrayOf(
          PropTypes.shape({
            field_discover_more: PropTypes.shape({
              title: PropTypes.string.isRequired,
              uri: PropTypes.string.isRequired,
            }).isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }),
  ),
  field_team: PropTypes.string,
  field_year_launched: PropTypes.string,
};

export default CaseSummaryTags;
