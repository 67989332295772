import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';
import { useWindowSize } from 'hooks';

import { ImgOrSvg, Link } from 'components';
import { PopUpWrapper } from './components';

const PopUp = ({
  dataItem: {
    field_title,
    field_button,
    relationships: { field_image: image },
    field_description,
    field_appearance_delay,
    drupal_id,
    field_image,
    field_show_on_exit_intent,
  },
}) => {
  const [isOpened, setOpened] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const { width } = useWindowSize();

  const body = field_description?.processed ? sanitize(field_description.processed) : null;
  const isShowOnExit = field_show_on_exit_intent;
  const button = field_button || {
    title: 'Contact Us',
    uri: '/contact-us/',
  };

  useEffect(() => {
    setMobile(width < 768);
  }, [width]);

  useEffect(() => {
    const isPopupOpened = localStorage.getItem(`popup:${drupal_id}`);

    if (!isPopupOpened) {
      const timeout = field_appearance_delay ? field_appearance_delay * 1000 : 60000;

      switch (true) {
        case isShowOnExit && !isMobile:
          const handleMouseLeave = () => {
            setOpened(!localStorage.getItem(`popup:${drupal_id}`));
            document.removeEventListener('mouseleave', handleMouseLeave);
          };

          document.addEventListener('mouseleave', handleMouseLeave);
          break;
        case isMobile:
          const handleScroll = () => {
            if (window.pageYOffset === 0) {
              setOpened(!localStorage.getItem(`popup:${drupal_id}`));
              window.removeEventListener('scroll', handleScroll);
            }
          };

          window.addEventListener('scroll', handleScroll);
          break;
        default:
          setTimeout(() => {
            setOpened(true);
          }, timeout);
          break;
      }
    }
  }, [isMobile]);

  const onClose = () => {
    setOpened(false);
    localStorage.setItem(`popup:${drupal_id}`, 'closed');
  };

  const onSubmit = () => {
    localStorage.setItem(`popup:${drupal_id}`, 'link opened');
  };

  return (
    <PopUpWrapper isOpened={isOpened}>
      <button type="button" id="popUpClose" onClick={onClose}>
        Close
      </button>
      <div className={field_description?.processed ? 'pop-up--text' : 'pop-up--text title-only'}>
        {field_title && <h3>{field_title}</h3>}
        {body && <div>{parser(body)}</div>}
        <Link className="btn" to={button.uri} onClick={onSubmit}>
          {button.title}
        </Link>
      </div>
      {image?.length ? (
        <div className="pop-up--image">
          <ImgOrSvg image={image[0]} alt={field_image[0].alt} />
        </div>
      ) : (
        <></>
      )}
    </PopUpWrapper>
  );
};

PopUp.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_button: PropTypes.object,
    field_show_on_exit_intent: PropTypes.bool,
    processed: PropTypes.string,
    field_appearance_delay: PropTypes.number,
    drupal_id: PropTypes.string,
    relationships: PropTypes.shape({
      field_image: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }).isRequired,
    field_description: PropTypes.object,
    field_image: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default PopUp;
