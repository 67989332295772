import React from 'react';
import PropTypes from 'prop-types';

import { ContactUs } from 'components';
import { HeaderParagraph, HeroBanner } from '../HeaderParagraphs';

const HeaderInfo = ({ type, showHeaderForm, headerData, nodeTitle, isAlternativeHeader }) => {
  const renderHeaderData = (data, index) => {
    const { internal: { type: dataType } = {} } = data;
    const isNotAlternativeTechnology = !isAlternativeHeader && !type.includes('technology-page');
    const isAlternativeNotTechnology = isAlternativeHeader && !type.includes('technology-page');

    if (dataType === 'paragraph__hero_banner') {
      return isAlternativeNotTechnology ? <HeroBanner key={index} dataItem={data} /> : null;
    }

    if (dataType === 'paragraph__header_paragraph') {
      return type.includes('technology-page') || isNotAlternativeTechnology ? (
        <HeaderParagraph
          key={index}
          data={data}
          showHeaderForm={showHeaderForm}
          type={type}
          nodeTitle={nodeTitle}
        />
      ) : null;
    }
    return null;
  };

  if (showHeaderForm === 'contact') {
    return <ContactUs />;
  }

  return headerData.length ? headerData.map(renderHeaderData) : null;
};

HeaderInfo.propTypes = {
  type: PropTypes.string,
  showHeaderForm: PropTypes.string,
  headerData: PropTypes.array,
  nodeTitle: PropTypes.string,
  isAlternativeHeader: PropTypes.bool,
};

export default HeaderInfo;
