import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import { getURLWithSlash } from 'utils';

// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...rest }) => {
  let link = to;

  const isAnchorLink = link.includes('#');

  if (link.includes('internal:')) {
    link = link.replace('internal:', '');
  }
  const internal = /^\/(?!\/)/.test(link);

  // Use Gatsby Link for internal links, and <a> for others

  if (link.includes('<nolink>') || link.length === 0) {
    return <span {...rest}>{children}</span>;
  }

  if (isAnchorLink || internal) {
    const url = isAnchorLink ? link : getURLWithSlash(link);

    return (
      <GatsbyLink
        to={url}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...rest}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={link} target="_blank" rel="noreferrer nofollow" {...rest}>
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.string,
};

export default Link;
