import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import Drawer from 'react-modern-drawer';

import { Link } from 'components/Link';
import AccordionDrawerItem from 'components/Header/components/DrawerNavigation/AccordionDrawerItem';

import ArrowBottom from 'icons/ArrowBottom';

const NestedDrawerNavigation = ({ item, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleCloseAll = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <>
      <li className="item" role="presentation">
        <Link to={item.link.url} onClick={onClose} className="item-link">
          {item.link.title}
        </Link>

        <div>
          <button className="next-btn" onClick={toggleDrawer} type="button">
            <ArrowBottom />
          </button>
          <Drawer
            open={isOpen}
            lockBackgroundScroll
            overlayOpacity={1}
            onClose={toggleDrawer}
            direction="left"
            className="nested-drawer"
          >
            <div>
              <div className="back-item">
                <button className="next-btn" onClick={toggleDrawer} type="button">
                  <ArrowBottom />
                </button>

                <Link to={item.link.url} onClick={onClose} className="item-link">
                  {item.link.title}
                </Link>

                <div className={CN('burger-menu', 'active')}>
                  <button
                    className={CN('burger-btn', 'open')}
                    onClick={handleCloseAll}
                    onKeyDown={handleCloseAll}
                    type="button"
                    tabIndex={0}
                  >
                    <div className="menu-icon">
                      <div className="top" />
                      <div className="middle" />
                      <div className="middle" />
                      <div className="bottom" />
                    </div>
                  </button>
                </div>
              </div>

              <ul>
                {item.subtree.map((nestedItem) => {
                  const isNestedItem = nestedItem.subtree?.length > 0;

                  return isNestedItem ? (
                    <AccordionDrawerItem
                      key={nestedItem.link.title}
                      onClose={onClose}
                      item={nestedItem}
                    />
                  ) : (
                    <li className="single" key={nestedItem.link.title}>
                      <Link to={nestedItem.link.url} onClick={onClose} className="item-link">
                        {nestedItem.link.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Drawer>
        </div>
      </li>
    </>
  );
};

NestedDrawerNavigation.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NestedDrawerNavigation;
