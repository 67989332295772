import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick/lib/slider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SliderBtn } from 'components/SliderBtn';
import { ReviewItem } from '../index';

const SLICK_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
};

const ReviewSlider = ({
  dataItem: {
    relationships: {
      field_reference,
      field_reference: { length },
    },
  },
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const slider = useRef(null);
  const isSliderExist = length > 1;

  const handleNext = () => slider.current.slickNext();

  const handlePrev = () => slider.current.slickPrev();

  const settings = {
    ...SLICK_SETTINGS,
    beforeChange: (currentIndex) => {
      setTimeout(() => setSlideIndex(currentIndex), 100);
    },
    customPaging(i) {
      return (
        <span>
          <span className="current">{i + 1}</span>/{length}
        </span>
      );
    },
  };

  return (
    <>
      <Slider {...settings} ref={slider}>
        {field_reference &&
          field_reference.map((item) => (
            <ReviewItem key={item.id} data={item} slideIndex={slideIndex} />
          ))}
      </Slider>

      {isSliderExist && (
        <div className="slider-btns">
          <SliderBtn prevBtn onClick={handlePrev} />
          <SliderBtn onClick={handleNext} />
        </div>
      )}
    </>
  );
};

ReviewSlider.propTypes = {
  dataItem: PropTypes.shape({
    relationships: PropTypes.shape({
      field_reference: PropTypes.array,
    }),
  }),
};

export default ReviewSlider;
