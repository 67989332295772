import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import Slider from 'react-slick/lib/slider';

import { useWindowSize } from 'hooks';

import { Arrow } from 'icons';
import { Link, SliderBtn } from 'components';
import { CaseStudiesItem } from './components';

const SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CaseStudies = ({
  dataItem: {
    internal: { type },
    field_title,
    relationships: { field_reference },
  },
}) => {
  const slider = useRef(null);
  const [isMobile, setMobile] = useState(false);
  const { width } = useWindowSize();

  const isAnotherDesign = field_reference.length <= 2;

  const handleNext = () => slider.current.slickNext();

  const handlePrev = () => slider.current.slickPrev();

  useEffect(() => {
    setMobile(width < 767);
  }, [width]);

  return (
    <div className={type}>
      <h2 className="case-studies-title">{field_title}</h2>

      {isMobile && isAnotherDesign ? (
        <div className="case-studies-mobile-box">
          {field_reference.map((item) => (
            <CaseStudiesItem data={item} />
          ))}
        </div>
      ) : (
        <Slider {...SLIDER_SETTINGS} ref={slider} className="case-studies-box">
          {field_reference.map((item) => (
            <CaseStudiesItem data={item} key={item.id} />
          ))}
        </Slider>
      )}
      <div
        className={CN('case-studies-navigation', {
          'case-studies-another-navigation': !isAnotherDesign && isMobile,
        })}
      >
        <Link
          to="https://anyforsoft.com/stories-list/"
          className="case-studies-see-more"
          target="_self"
        >
          <span>See all cases</span>
          <div className="see-more-icon">
            <Arrow color="rgba(9, 21, 67, 0.75)" />
          </div>
        </Link>

        {!isAnotherDesign && (
          <div className="slider-buttons">
            <SliderBtn prevBtn onClick={handlePrev} />
            <SliderBtn onClick={handleNext} />
          </div>
        )}
      </div>
    </div>
  );
};

CaseStudies.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    internal: PropTypes.shape({ type: PropTypes.string }),
    relationships: PropTypes.shape({ field_reference: PropTypes.arrayOf(PropTypes.object) }),
  }),
};

export default CaseStudies;
