import React from 'react';
import PropTypes from 'prop-types';

const StatisticItem = ({
  dataItem: {
    internal: { type },
    field_title,
    field_subtitle,
  },
}) => (
  <div className={type}>
    {field_title && (
      <div className="item-title">
        <h2>{field_title}</h2>
      </div>
    )}

    {field_subtitle && (
      <div className="item-subtitle">
        <p>{field_subtitle}</p>
      </div>
    )}
  </div>
);

StatisticItem.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    internal: PropTypes.shape({ type: PropTypes.string }),
  }),
};

export default StatisticItem;
