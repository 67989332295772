import React from 'react';
import PropTypes from 'prop-types';

const Success = (props) => {
  const { color } = props;
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.6307 10.2404C22.0885 10.6982 22.0885 11.4402 21.6307 11.8977L13.7691 19.7596C13.3113 20.2171 12.5695 20.2171 12.1117 19.7596L8.36929 16.0169C7.91153 15.5594 7.91153 14.8174 8.36929 14.3598C8.82683 13.9021 9.56886 13.9021 10.0264 14.3598L12.9403 17.2737L19.9734 10.2404C20.4311 9.78287 21.1732 9.78287 21.6307 10.2404ZM30 15C30 23.2912 23.2901 30 15 30C6.70876 30 0 23.2901 0 15C0 6.70876 6.7099 0 15 0C23.2912 0 30 6.7099 30 15ZM27.6562 15C27.6562 8.00423 21.9949 2.34375 15 2.34375C8.00423 2.34375 2.34375 8.00514 2.34375 15C2.34375 21.9958 8.00514 27.6562 15 27.6562C21.9958 27.6562 27.6562 21.9949 27.6562 15Z"
        fill="#00786E"
      />
    </svg>
  );
};

Success.propTypes = {
  color: PropTypes.string,
};

Success.defaultProps = {
  color: '#ffffff',
};

export default Success;
