import React from 'react';
import PropTypes from 'prop-types';
import Obfuscate from 'react-obfuscate';

import { ImgOrSvg, Link } from 'components';

const SalesBlock = ({
  innerRef,
  blockTitle,
  salesInfo: {
    name,
    field_author_position: position,
    field_email: email,
    field_email_s_button_text: buttonText,
    field_link,
    field_author_image,
    relationships: { field_author_image: image },
  },
}) => (
  <div className="sales-block" ref={innerRef}>
    {blockTitle && (
      <div className="title">
        <h2>{blockTitle}</h2>
      </div>
    )}

    <div className="sales">
      <div className="sale">
        <div className="sale-image">
          {image?.localFile && (
            <ImgOrSvg image={image} alt={field_author_image?.alt || 'AnyforSoft'} />
          )}
        </div>

        <div className="sale-details">
          <div className="sale-name">{name}</div>
          <div className="sale-position">{position}</div>
        </div>

        {(field_link || email) && (
          <div className="sale-buttons">
            {field_link && (
              <Link className="btn inverted sale-email" to={field_link.uri}>
                {field_link.title || 'Contact Us'}
              </Link>
            )}

            {email && (
              <Obfuscate
                className="btn inverted sale-email"
                email={email}
                obfuscateChildren={false}
                linkText=" "
              >
                {buttonText}
              </Obfuscate>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

SalesBlock.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  ]),
  blockTitle: PropTypes.string.isRequired,
  salesInfo: PropTypes.shape({
    name: PropTypes.string,
    field_author_position: PropTypes.string,
    field_email: PropTypes.string,
    field_email_s_button_text: PropTypes.string,
    field_link: PropTypes.shape({
      uri: PropTypes.string,
      title: PropTypes.string,
    }),
    field_author_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_author_image: PropTypes.object,
    }),
  }).isRequired,
};

export default SalesBlock;
