import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

const TwoColumnText = ({
  dataItem: {
    field_first_column,
    field_second_column,
    internal: { type },
  },
}) => (
  <div className={type}>
    <div className="column-wrapper">
      <div className="column first-column">{parser(sanitize(field_first_column.processed))}</div>
      <div className="column second-column">{parser(sanitize(field_second_column.processed))}</div>
    </div>
  </div>
);

TwoColumnText.propTypes = {
  dataItem: PropTypes.shape({
    field_first_column: PropTypes.object,
    field_second_column: PropTypes.object,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default TwoColumnText;
