import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components';

const ClutchWidget = ({ id }) => (
  <div className="afs-clutch-widget">
    <div
      className="clutch-widget"
      data-url="https://widget.clutch.co"
      data-widget-type="1"
      data-expandifr="true"
      data-height="40"
      data-nofollow="true"
      data-snippets="true"
      data-clutchcompany-id={id}
      data-primary-color="#1d746b"
      data-secondary-color="#1d746b"
    />
    <p>
      Check our <Link to="https://clutch.co/profile/anyforsoft">Clutch profile</Link> profile to see
      more reviews
    </p>
  </div>
);

ClutchWidget.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ClutchWidget;
