import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { useWindowSize } from 'hooks';

import { Link } from 'components';
import { Accordion } from './Accordion';
import { TabSlider } from './TabSlider';

const Industries = ({
  dataItem: {
    field_link,
    field_display_link,
    field_title,
    field_subtitle,
    field_grey_background,
    relationships: { field_reference },
    internal: { type },
  },
}) => {
  const [isMobile, setMobile] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    setMobile(width < 1280);
  }, [width]);

  return (
    <div className={CN(type, isMobile ? 'accordion' : 'tab-slider')}>
      <div id={type} className="anchor-for-menu" />
      {field_grey_background ? <span className="green-light-bg__inner" /> : ''}
      <div>
        <h2 className="paragraph-title">{field_title}</h2>
        <div className="industries-list">
          {isMobile && <Accordion items={field_reference} />}
          {!isMobile && <TabSlider items={field_reference} />}
        </div>
        <div className="industry-cta">
          {field_subtitle && <span className="cta-text">{field_subtitle}</span>}
          {field_link?.uri && field_display_link && (
            <Link className="btn inverted" to={field_link.uri}>
              {field_link.title || 'See more'}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

Industries.propTypes = {
  dataItem: PropTypes.shape({
    field_link: PropTypes.object,
    field_display_link: PropTypes.bool,
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    field_grey_background: PropTypes.bool,
    relationships: PropTypes.shape({
      field_reference: PropTypes.array,
    }),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default Industries;
