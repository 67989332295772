import React, { useRef, useState } from 'react';
import { SubMenu, applyStatics } from '@szhsin/react-menu';
import PropTypes from 'prop-types';

import { Link } from 'components';
import { ArrowBottom } from 'icons';

const SubMenuItem = ({ item, ...rest }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const subMenuItemRef = useRef(null);

  const handleToggleMenu = (e) => {
    const currentRef = subMenuItemRef.current;
    e.preventDefault();
    e.stopPropagation();

    if (isMenuOpen) {
      return currentRef?.closeMenu();
    }
    return currentRef?.openMenu();
  };

  return (
    <SubMenu
      {...rest}
      label={
        <>
          <Link variant="grayUppercase" to={item.link.url}>
            <span>{item.link.title}</span>
          </Link>
          <button className="nested-btn" type="button" onClick={handleToggleMenu}>
            <ArrowBottom />
          </button>
        </>
      }
      instanceRef={subMenuItemRef}
      className="subMenu"
      onMenuChange={(state) => {
        setIsMenuOpen(state.open);
      }}
    >
      <div className="subMenuContainer">
        {item.subtree.map((nestedItem) => (
          <Link key={nestedItem.link.url} variant="grayUppercase" to={nestedItem.link.url}>
            {nestedItem.link.title}
          </Link>
        ))}
      </div>
    </SubMenu>
  );
};

applyStatics(SubMenu)(SubMenuItem);

SubMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
};
export default SubMenuItem;
