import React from 'react';
import PropTypes from 'prop-types';

import { getPicturesColumns } from 'utils';

import { ImgOrSvg } from 'components';

const Community = ({
  dataItem: {
    field_title,
    relationships: { field_community_image: communityImages },
    internal: { type },
    field_community_image: communityImageAlts,
  },
}) => {
  const { bigColumn, smallColumn } = getPicturesColumns(communityImages, communityImageAlts);

  return (
    <div className={type}>
      <div className="small-column">
        {communityImages &&
          smallColumn.map(({ image, id, alt }) => (
            <div key={id} className={`${type}-images item item-img`}>
              <ImgOrSvg image={image} alt={alt} />
            </div>
          ))}
      </div>

      <div className="big-column">
        <div className={`${type}-title-description item`}>
          <h2 className="h2">{field_title}</h2>
        </div>

        {communityImages &&
          bigColumn.map(({ image, id, alt }) => (
            <div key={id} className={`${type}-images item item-img`}>
              <ImgOrSvg image={image} alt={alt} />
            </div>
          ))}
      </div>
    </div>
  );
};

Community.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    relationships: PropTypes.shape({
      field_community_image: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_community_image: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string,
      }),
    ),
  }),
};

export default Community;
