import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg, Link } from 'components';
import { Arrow } from 'icons';

const StoryItem = ({
  data: {
    path: { alias },
    title,
    field_sub_title,
    field_image_header,
    field_image,
    field_country,
    field_industry,
    relationships: { field_image: image, field_image_header: headerImage },
  },
}) => (
  <div className="story-item">
    {(headerImage || image) && (
      <div className="story-image">
        <ImgOrSvg
          image={headerImage || image}
          alt={field_image_header?.alt || field_image?.alt || 'Project image'}
        />
        <Link to={alias} className="link-overlay" />
      </div>
    )}

    <div className="inner-box">
      <div className="title">
        {field_sub_title && <h2 className="story-title">{field_sub_title}</h2>}
      </div>

      <div className="story-information">
        {title && (
          <div className="information-item">
            <span className="item-title">Client</span>
            <span className="item-content">{title}</span>
          </div>
        )}

        {field_country && (
          <div className="information-item">
            <span className="item-title">Country</span>
            <span className="item-content">{field_country}</span>
          </div>
        )}

        {field_industry && (
          <div className="information-item">
            <span className="item-title">Industry</span>
            <span className="item-content">{field_industry}</span>
          </div>
        )}
      </div>

      <div className="more-details">
        <Link to={alias} className="case-details">
          <span>case details</span>
          <div className="see-more-icon">
            <Arrow color="#091543" />
          </div>
        </Link>
      </div>
    </div>
  </div>
);

StoryItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    field_sub_title: PropTypes.string,
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
    field_image_header: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_country: PropTypes.string,
    field_industry: PropTypes.string,
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_image_header: PropTypes.object,
    }),
  }),
};

export default StoryItem;
