import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import Slider from 'react-slick/lib/slider';

import { StoriesOneCol, StoriesTwoCol } from './components';

const Stories = ({
  slides,
  sliderLayout,
  data: {
    relationships: { field_reference: stories },
  },
}) => {
  const slider = useRef(null);

  const handleNext = () => slider.current.slickNext();
  const handlePrev = () => slider.current.slickPrev();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging(i) {
      return (
        <>
          <span className="current">{`${i + 1} `}</span>/{' '}
          <span className="total">{`${stories.length}`}</span>
        </>
      );
    },
  };

  return (
    <div
      className={CN('slider-wrapper', {
        'slider-one-col-wrapper': sliderLayout === 'sliderOneCol',
        'slider-two-col-wrapper': sliderLayout === 'sliderTwoCol',
      })}
    >
      <Slider {...settings} ref={slider}>
        {stories.map((item) => (
          <div
            key={item.drupal_id}
            className={CN('element_to_animate slider-item', {
              'slider-one-col': sliderLayout === 'sliderOneCol',
              'slider-two-col': sliderLayout === 'sliderTwoCol',
            })}
          >
            {sliderLayout === 'sliderOneCol' && <StoriesOneCol item={item} />}
            {sliderLayout === 'sliderTwoCol' && <StoriesTwoCol item={item} />}
          </div>
        ))}
      </Slider>

      {stories.length > 1 && (
        <div className="navigation-list">
          <button className="slick-arrow slick-prev" type="button" onClick={handlePrev}>
            previous
          </button>
          <button className="slick-arrow slick-next" type="button" onClick={handleNext}>
            next
          </button>
        </div>
      )}
    </div>
  );
};

Stories.propTypes = {
  slides: PropTypes.number,
  sliderLayout: PropTypes.string,
  data: PropTypes.shape({
    relationships: PropTypes.shape({
      field_reference: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default Stories;
