import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

const TitleWithDescription = ({
  dataItem: {
    field_title,
    field_description,
    field_layout_grey_bg,
    internal: { type },
  },
}) => (
  <div className="title-with-description">
    <div className={CN(type, field_layout_grey_bg && 'grey-bg')}>
      {field_title && (
        <div className="title">
          <div>
            <h2>{field_title}</h2>
          </div>
        </div>
      )}

      {field_description && (
        <div className="description">
          <div>
            <div>{parser(sanitize(field_description.processed))}</div>
          </div>
        </div>
      )}
    </div>
  </div>
);

TitleWithDescription.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    field_layout_grey_bg: PropTypes.bool,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default TitleWithDescription;
