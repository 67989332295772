import React, { useState, useCallback } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import { graphql, useStaticQuery } from 'gatsby';
import CN from 'classnames';

import { Arrow, CloseIcon, SearchIcon } from 'icons';

import { Link } from 'components';

const query = graphql`
  query {
    localSearchArticles {
      index
      store
    }
  }
`;

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isActive, setIsActive] = useState(false);

  const {
    localSearchArticles: { index, store },
  } = useStaticQuery(query);

  const results = useFlexSearch(searchQuery, index, JSON.parse(store));

  const handleCloseSearch = () => {
    if (isActive) {
      setIsActive(false);
      setSearchQuery('');
    }
  };

  const handleOpenSearch = () => {
    if (!isActive) {
      setIsActive(true);
      setSearchQuery('');
    }
  };

  const handleSearch = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  return (
    <div className="search-bar blog">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        tabIndex="0"
        onClick={handleOpenSearch}
        className={CN('search-wrapper', {
          expanded: isActive,
        })}
      >
        <input
          value={searchQuery}
          type="text"
          onChange={handleSearch}
          className={CN({ active: searchQuery })}
          name="query"
          placeholder="Search"
          autoComplete="off"
        />
        {isActive ? <CloseIcon onClick={handleCloseSearch} /> : <SearchIcon />}
      </div>

      <ul className={CN('search-result', { active: searchQuery })}>
        {results.length === 0 && searchQuery && <li className="no-results">No results.</li>}
        {results.slice(0, 10).map((result) => (
          <li key={result.id}>
            <Link to={result.path}>{result.title}</Link>
            <Arrow />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchBar;
