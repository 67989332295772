import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick/lib/slider';
import CN from 'classnames';

import { useWindowSize } from 'hooks';

import { SliderBtn } from 'components/SliderBtn';
import { ServiceItem } from './ServiceItem';

const SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ServicesList = ({
  dataItem: {
    field_title,
    field_grey_background,
    field_title_left_align,
    relationships: { field_reference },
    internal: { type },
  },
}) => {
  const slider = useRef(null);
  const [currentDevice, setCurrentDevice] = useState('');
  const { width } = useWindowSize();

  const disableDesktopSlider = field_reference.length <= 3 && currentDevice === 'desktop';
  const disableTableSlider = field_reference.length <= 2 && currentDevice === 'tablet';
  const disableMobileSlider = field_reference.length <= 1 && currentDevice === 'mobile';

  const shouldHideSliderBtn = disableDesktopSlider || disableTableSlider || disableMobileSlider;

  const handleNext = () => slider.current.slickNext();

  const handlePrev = () => slider.current.slickPrev();

  useEffect(() => {
    if (width < 940 && width > 650) {
      setCurrentDevice('tablet');
    } else if (width < 650) {
      setCurrentDevice('mobile');
    } else {
      setCurrentDevice('desktop');
    }
  }, [width]);

  return (
    <div className="paragraph-service col-3">
      {field_grey_background && <span className="green-light-bg__inner" />}

      <div className={type}>
        <h2 className={CN('paragraph-title', field_title_left_align ? 'left-title' : '')}>
          {field_title}
        </h2>
        <div className="service-list">
          <Slider {...SLIDER_SETTINGS} ref={slider} className="case-studies-box">
            {field_reference.map((item) => (
              <ServiceItem data={item} key={item} />
            ))}
          </Slider>
        </div>

        {!shouldHideSliderBtn && (
          <div className="slider-buttons">
            <SliderBtn prevBtn onClick={handlePrev} />
            <SliderBtn onClick={handleNext} />
          </div>
        )}
      </div>
    </div>
  );
};

ServicesList.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_grey_background: PropTypes.bool,
    field_title_left_align: PropTypes.bool,
    relationships: PropTypes.shape({
      field_reference: PropTypes.array,
    }),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default ServicesList;
