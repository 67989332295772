import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg, VideoPlayer } from 'components';

const DesktopMedia = ({
  field_what_to_display,
  data: {
    field_image,
    image,
    field_video_autoplay_status,
    field_video_url,
    field_video_file_preview,
    field_video,
  },
}) => {
  if (!field_what_to_display) {
    return null;
  }

  return (
    <div className="case-summary-media">
      {field_what_to_display === 'with_image' && <ImgOrSvg image={image} alt={field_image.alt} />}
      {field_what_to_display === 'with_video' && (
        <VideoPlayer
          id={field_video?.id}
          video_file={field_video?.localFile?.publicURL}
          video_preview={field_video_file_preview?.localFile?.publicURL}
          autoplay_status={field_video_autoplay_status}
          field_link={field_video_url?.uri}
        />
      )}
    </div>
  );
};

DesktopMedia.propTypes = {
  field_what_to_display: PropTypes.string,
  data: PropTypes.shape({
    field_video_autoplay_status: PropTypes.bool,
    field_video_url: PropTypes.shape({
      uri: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    image: PropTypes.object,
    field_video_file_preview: PropTypes.object,
    field_video: PropTypes.object,
  }),
};

export default DesktopMedia;
