import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

const TextGridItem = ({ data: { field_title, field_description } }) => (
  <div className="text-grid-item">
    <div className="box-wrapper">
      {field_title && <h3 className="item-title">{field_title}</h3>}
      {field_description && (
        <div className="item-description">{parser(sanitize(field_description.processed))}</div>
      )}
    </div>
  </div>
);

TextGridItem.propTypes = {
  data: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
  }).isRequired,
};

export default TextGridItem;
