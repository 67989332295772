import sanitizeHtml from 'sanitize-html';

const OPTIONS = {
  allowedTags: [
    'a',
    'em',
    'strong',
    'cite',
    'blockquote',
    'code',
    'div',
    'ul',
    'ol',
    'li',
    'dl',
    'dt',
    'dd',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    's',
    'sup',
    'sub',
    'img',
    'table',
    'caption',
    'tbody',
    'thead',
    'tfoot',
    'th',
    'td',
    'tr',
    'hr',
    'h1',
    'pre',
    'span',
    'p',
    'br',
    'u',
  ],
  allowedAttributes: {
    a: ['href', 'hreflang', 'class', 'rel', 'target', 'style'],
    blockquote: ['cite', 'style'],
    div: ['class', 'style'],
    ul: ['type', 'style'],
    ol: ['type', 'start', 'style'],
    h1: ['id', 'style'],
    h2: ['id', 'style'],
    h3: ['id', 'style'],
    h4: ['id', 'style'],
    h5: ['id', 'style'],
    h6: ['id', 'style'],
    img: [
      'src',
      'alt',
      'data-entity-type',
      'data-entity-uuid',
      'data-align',
      'data-caption',
      'style',
    ],
    span: ['class', 'id', 'style'],
    th: [
      'style',
      'colspan',
      'abbr',
      'align',
      'axis',
      'char',
      'charoff',
      'valign',
      'headers',
      'nowrap',
      'rowspan',
      'scope',
    ],
    td: [
      'style',
      'colspan',
      'abbr',
      'align',
      'axis',
      'char',
      'charoff',
      'valign',
      'headers',
      'nowrap',
      'rowspan',
      'scope',
      'border-*',
      'border',
    ],
    table: ['align', 'style', 'cellpadding', 'border-*', 'border'],
    tbody: ['align', 'char', 'charoff', 'valign', 'style'],
    thead: ['align', 'char', 'charoff', 'valign', 'style'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (content) => sanitizeHtml(content, OPTIONS);
export default sanitize;
