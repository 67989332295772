import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg } from 'components';

const MobileMedia = ({
  field_mobile_images: {
    field_image_1: { alt: alt1 },
    field_image_2: { alt: alt2 },
    field_image_3: { alt: alt3 },
    field_image_4: { alt: alt4 },
    field_image_5,
    relationships: {
      field_image_1: image1,
      field_image_2: image2,
      field_image_3: image3,
      field_image_4: image4,
      field_image_5: image5,
    },
  },
}) => (
  <div className="case-summary-mobile-images">
    <div className="case-summary-mobile-images-wrapper">
      {image5 ? (
        <>
          <div className="mobile-image-wrapper small">
            <ImgOrSvg image={image1} alt={alt1} />
          </div>
          <div className="mobile-image-wrapper medium">
            <ImgOrSvg image={image2} alt={alt2} />
          </div>
          <div className="mobile-image-wrapper large">
            <ImgOrSvg image={image3} alt={alt3} />
          </div>
          <div className="mobile-image-wrapper medium">
            <ImgOrSvg image={image4} alt={alt4} />
          </div>
          <div className="mobile-image-wrapper small">
            <ImgOrSvg image={image5} alt={field_image_5.alt} />
          </div>
        </>
      ) : (
        <>
          <div className="mobile-image-wrapper extra-large">
            <ImgOrSvg image={image1} alt={alt1} />
          </div>
          <div className="mobile-image-wrapper extra-large">
            <ImgOrSvg image={image2} alt={alt2} />
          </div>
          <div className="mobile-image-wrapper extra-large">
            <ImgOrSvg image={image3} alt={alt3} />
          </div>
          <div className="mobile-image-wrapper extra-large">
            <ImgOrSvg image={image4} alt={alt4} />
          </div>
        </>
      )}
    </div>
  </div>
);

MobileMedia.propTypes = {
  field_mobile_images: PropTypes.shape({
    field_image_1: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image_2: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image_3: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image_4: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image_5: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image_1: PropTypes.object,
      field_image_2: PropTypes.object,
      field_image_3: PropTypes.object,
      field_image_4: PropTypes.object,
      field_image_5: PropTypes.object,
    }),
  }),
};

export default MobileMedia;
