import React from 'react';

const ContactIcon = (props) => {
  const icon = props.iconType;

  switch (icon) {
    case 'call':
      return (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.4064 15.4119L17.4758 12.4813C16.4291 11.4346 14.6498 11.8533 14.2311 13.2139C13.9172 14.1559 12.8705 14.6793 11.9285 14.4699C9.83524 13.9466 7.00929 11.2253 6.48597 9.02732C6.17197 8.0853 6.79996 7.03865 7.74194 6.7247C9.10258 6.30604 9.52124 4.52675 8.4746 3.4801L5.54399 0.549489C4.70667 -0.183163 3.45069 -0.183163 2.71804 0.549489L0.729415 2.53812C-1.25921 4.63141 0.938744 10.1786 5.85798 15.0979C10.7772 20.0171 16.3244 22.3198 18.4177 20.2264L20.4064 18.2378C21.1391 17.4005 21.1391 16.1445 20.4064 15.4119Z"
            fill="#001344"
          />
        </svg>
      );
    case 'email':
      return (
        <svg
          width="30"
          height="21"
          viewBox="0 0 30 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.6036 1.50037L20.5456 10.5L29.6036 19.4996C29.7673 19.1573 29.8667 18.779 29.8667 18.375V2.62497C29.8667 2.2209 29.7673 1.84261 29.6036 1.50037Z"
            fill="#001344"
          />
          <path
            d="M27.2417 0H2.62499C2.22091 0 1.84262 0.0993418 1.50038 0.263083L13.0774 11.7818C14.101 12.8054 15.7656 12.8054 16.7893 11.7818L28.3663 0.263083C28.024 0.0993418 27.6457 0 27.2417 0Z"
            fill="#001344"
          />
          <path
            d="M0.263083 1.50037C0.0993417 1.84261 0 2.2209 0 2.62497V18.375C0 18.779 0.0993417 19.1574 0.263083 19.4996L9.32103 10.5L0.263083 1.50037Z"
            fill="#001344"
          />
          <path
            d="M19.3083 11.7373L18.0266 13.0191C16.321 14.7247 13.5456 14.7247 11.84 13.0191L10.5583 11.7373L1.50038 20.7369C1.84262 20.9007 2.22091 21 2.62499 21H27.2417C27.6457 21 28.024 20.9007 28.3663 20.7369L19.3083 11.7373Z"
            fill="#001344"
          />
        </svg>
      );
    default:
      return false;
  }
};

export default ContactIcon;
