import React from 'react';
import PropTypes from 'prop-types';

const Attachment = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" color={color} viewBox="0 0 16 15" {...props}>
      <path
        fill="currentColor"
        d="M2.8 14.7c-.7 0-1.4-.3-2-.8a2.8 2.8 0 010-4l8.8-8.8a3.7 3.7 0 012.6-1c1 0 2 .3 2.7 1a3.7 3.7 0 011 2.7c0 1-.3 2-1 2.6l-7 7a.5.5 0 11-.7-.8l7-7a2.7 2.7 0 000-3.7 2.7 2.7 0 00-2-.8c-.7 0-1.3.3-1.8.8l-8.8 8.7a1.8 1.8 0 000 2.6c.7.6 1.8.6 2.5 0l5.9-6a.9.9 0 00.2-.5.9.9 0 00-1.4-.6l-4 4a.5.5 0 11-.8-.8l4-4a2 2 0 013.3 1.4c0 .5-.2 1-.6 1.3l-5.9 6a2.8 2.8 0 01-2 .7z"
      />
    </svg>
  );
};

Attachment.propTypes = {
  color: PropTypes.string,
};

Attachment.defaultProps = {
  color: '#ffffff',
};

export default Attachment;
