const setFileData = (file, data) => ({
  filename: [{ value: file.name }],
  filemime: [{ value: file.mime }],
  filesize: [{ value: file.size }],
  uri: [{ value: `public://${file.name}` }],
  type: [{ target_id: file.type }],
  data: [{ value: data }],
});

export default setFileData;
