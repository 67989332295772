import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

import './ShareButtons.scss';

const ShareButtons = ({ title, url, twitterHandle, tags }) => (
  <div className="social-list">
    <FacebookShareButton url={url}>
      <FacebookIcon size={40} round />
    </FacebookShareButton>
    <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
      <TwitterIcon size={40} round />
    </TwitterShareButton>
    <LinkedinShareButton url={url}>
      <LinkedinIcon size={40} round />
    </LinkedinShareButton>
    <EmailShareButton url={url} title={title}>
      <EmailIcon size={40} round />
    </EmailShareButton>
  </div>
);

ShareButtons.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  twitterHandle: PropTypes.string,
  tags: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default ShareButtons;
