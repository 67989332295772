import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { SocialIconRedesign, DefaultAvatar } from 'icons';
import { ImgOrSvg, Link } from 'components';

import BackgroundLeftElement from './Images/left_element.svg';
import BackgroundRightElement from './Images/right_element.svg';

const AuthorPreview = ({
  author: {
    description,
    field_author_image: { alt },
    field_author_position,
    field_facebook_profile_url,
    field_linkedin_profile_url,
    field_twitter_profile_url,
    name,
    relationships: { field_author_image },
  },
}) => (
  <div className="author">
    <div className="author-box">
      <div className="author-main-information">
        <div className="author-avatar-box">
          {field_author_image ? (
            <ImgOrSvg image={field_author_image} alt={alt} objectFit="cover" />
          ) : (
            <DefaultAvatar className="author-avatar__default" />
          )}
        </div>
        <div className="author-main-information__box">
          <h1 className="author-name">{name}</h1>

          {field_author_position && <p className="author-position">{field_author_position}</p>}

          <div className="author-social-media">
            {field_linkedin_profile_url && (
              <Link to={field_linkedin_profile_url.uri} className="author-social-link">
                <SocialIconRedesign iconType="linkedin" />
              </Link>
            )}

            {field_twitter_profile_url && (
              <Link to={field_twitter_profile_url.uri} className="author-social-link">
                <SocialIconRedesign iconType="twitter" />
              </Link>
            )}

            {field_facebook_profile_url && (
              <Link to={field_facebook_profile_url.uri} className="author-social-link">
                <SocialIconRedesign iconType="facebook" />
              </Link>
            )}
          </div>

          <div itemProp="author" itemScope itemType="https://schema.org/Person" className="hidden">
            <span itemProp="name">{name}</span>
          </div>

          <div
            itemProp="publisher"
            itemScope
            itemType="https://schema.org/Organization"
            className="hidden"
          >
            <meta itemProp="name" content="Anyforsoft" />
            <div itemProp="logo" itemScope itemType="https://www.schema.org/ImageObject">
              <link itemProp="url" href="https://anyforsoft.com/anyforsoft_social.jpg" />
            </div>
          </div>
        </div>
      </div>
      {description && (
        <div className="author-description">{parser(sanitize(description.value))}</div>
      )}
    </div>
    <div className="author-background-elements">
      <img className="author-background__left" src={BackgroundLeftElement} alt="" />
      <img className="author-background__right" src={BackgroundRightElement} alt="" />
    </div>
  </div>
);

AuthorPreview.propTypes = {
  author: PropTypes.shape({
    description: PropTypes.object,
    field_author_image: PropTypes.object,
    field_facebook_profile_url: PropTypes.object,
    field_linkedin_profile_url: PropTypes.object,
    field_twitter_profile_url: PropTypes.object,
    field_author_position: PropTypes.string,
    name: PropTypes.string,
    relationships: PropTypes.shape({
      field_author_image: PropTypes.object,
    }),
  }),
};

export default AuthorPreview;
