import React from 'react';
import { Link } from 'components/Link';
import { Message } from 'icons';

const PromotionBlock = () => (
  <div className="promotion-block">
    <div className="inner-wrapper">
      <div className="promotion-box">
        <h2 className="promotion-title">
          Your project <span className="highlight">next?</span>
        </h2>
        <div className="wrp-button">
          <Link to="/contact-us" className="recommended-button">
            <div className="btn-text">let’s talk</div>
            <div className="icon">
              <Message color="#FFFF" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default PromotionBlock;
