import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const FunctionalityItem = ({
  item: {
    field_description: { processed: description },
    field_image: { alt },
    relationships: { field_image: image },
  },
  index,
}) => (
  <div className="functionality-item">
    <div className="functionality-item-icon">
      <ImgOrSvg image={image} alt={alt} />
    </div>
    <div className="functionality-item-text">{parser(sanitize(description))}</div>
    <span className="functionality-item-number">{index + 1}</span>
  </div>
);

FunctionalityItem.propTypes = {
  item: PropTypes.shape({
    field_description: PropTypes.shape({
      processed: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }),
  index: PropTypes.number,
};

export default FunctionalityItem;
