import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { ResultItem, ResultsBackground } from './components';

const Results = ({
  dataItem: {
    internal: { type },
    field_title,
    field_subtitle,
    field_description: { processed: description },
    field_bottom_description,
    relationships: { field_result_item },
  },
}) => {
  const isEqualItem = field_result_item.length <= 2;

  return (
    <div className={type}>
      <ResultsBackground />

      <div className="results-box">
        <h2 className="results-title">{field_title}</h2>
        <span className="results-subtitle">{field_subtitle}</span>
        <div className="results-description">{parser(sanitize(description))}</div>
      </div>

      <div className={CN('results-items-list', { 'results-equal-items': isEqualItem })}>
        {field_result_item.map((item) => (
          <ResultItem data={item} key={item.id} />
        ))}
      </div>

      {field_bottom_description && (
        <div className="results-bottom-description">
          {parser(sanitize(field_bottom_description.processed))}
        </div>
      )}
    </div>
  );
};

Results.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    field_description: PropTypes.object,
    field_bottom_description: PropTypes.object,
    internal: PropTypes.shape({ type: PropTypes.string }),
    relationships: PropTypes.shape({ field_result_item: PropTypes.arrayOf(PropTypes.object) }),
  }),
};

export default Results;
