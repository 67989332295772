import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { VerticalItem } from './VerticalItem';

const Verticals = ({
  dataItem: {
    field_title,
    internal: { type },
    relationships: { field_reference },
  },
}) => (
  <div className={CN(type, 'without-wave')}>
    <div id="verticals" className="anchor-for-menu" />
    <div>
      <h2>{field_title}</h2>
    </div>
    <div className="vertical-list">
      {field_reference &&
        field_reference.map((item, index) => {
          const isEven = index % 2;
          return <VerticalItem key={item.id} data={item} isEven={isEven} />;
        })}
    </div>
  </div>
);

Verticals.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_reference: PropTypes.array,
    }),
  }).isRequired,
};

export default Verticals;
