import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';
import { Wrapper } from './components';

const BlogBannerWithoutButton = ({
  dataItem: {
    internal: { type },
    field_banner_title: { processed },
    field_display_as_link,
    field_banner_image,
    relationships: { field_banner_image: image },
  },
}) => (
  <div className={type}>
    <Wrapper field_display_as_link={field_display_as_link}>
      <div id={type} className="anchor-for-menu" />
      <div className="banner-info">
        {processed && <div className="banner-title">{parser(sanitize(processed))}</div>}
        <div className="banner-image">
          {image && (
            <ImgOrSvg
              image={image}
              itemProp="image"
              alt={field_banner_image?.alt || 'Sales person'}
            />
          )}
        </div>
      </div>
    </Wrapper>
  </div>
);

BlogBannerWithoutButton.propTypes = {
  dataItem: PropTypes.shape({
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_banner_title: PropTypes.shape({
      processed: PropTypes.string,
    }),
    field_display_as_link: PropTypes.shape({
      uri: PropTypes.string,
    }),
    field_banner_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_banner_image: PropTypes.object,
    }),
  }).isRequired,
};

export default BlogBannerWithoutButton;
