import React from 'react';
import PropTypes from 'prop-types';
import Obfuscate from 'react-obfuscate';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const OurTeamGrid = ({ rel }) => (
  <div className="team-grid members-list">
    {rel &&
      rel.map(
        (
          {
            id,
            title,
            field_position,
            field_linkedin,
            field_email,
            field_description,
            field_image: { alt },
            relationships: { field_image },
          },
          index,
        ) => {
          // @todo need to restrict duplicate field reference in drupal side
          const newKey = id + index;

          return (
            <div key={newKey} className="member">
              <div className="member-top">
                {field_image?.localFile && (
                  <div className="member-list__image">
                    <ImgOrSvg image={field_image} alt={alt} />
                  </div>
                )}

                <div className="member-name-position-social">
                  {title && <h3 className="name">{title}</h3>}
                  {field_position && <div className="position">{field_position}</div>}
                  <div className="bottom-socials">
                    {field_linkedin && (
                      <div className="linkedin">
                        <a href={field_linkedin} target="_blank" rel="noopener noreferrer">
                          {field_linkedin}
                        </a>
                      </div>
                    )}
                    {field_email && (
                      <div className="email">
                        <Obfuscate email={field_email} linkText=" ">
                          {field_email}
                        </Obfuscate>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="member-bottom">
                {field_description && (
                  <div className="description">{parser(sanitize(field_description.processed))}</div>
                )}
              </div>
            </div>
          );
        },
      )}
  </div>
);

OurTeamGrid.propTypes = {
  rel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      field_description: PropTypes.shape({
        processed: PropTypes.string,
      }),
      field_email: PropTypes.string,
      field_linkedin: PropTypes.string,
      field_position: PropTypes.string,
      field_image: PropTypes.shape({
        alt: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        field_image: PropTypes.object,
      }),
    }),
  ),
};

export default OurTeamGrid;
