import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import OurNews from './OurNews';

const OurNewsWrapper = ({
  dataItem: {
    field_title,
    internal: { type },
  },
}) => (
  <div className={CN(type, 'green-light-bg')}>
    <span className="green-light-bg__inner" />

    <h2>{field_title}</h2>
    <OurNews />
  </div>
);

OurNewsWrapper.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default OurNewsWrapper;
