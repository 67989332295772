const getInputValue = ({ type, files, value: inputValue, checked }) => {
  if (type === 'file') {
    return files?.[0] || '';
  }

  if (type === 'checkbox') {
    return checked;
  }

  return inputValue.replace(/([<(|)*>])+/gi, '');
};

export default getInputValue;
