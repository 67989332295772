// This prototype helps to merge the meta tags, which are defined here and which can be
// added later.
// It helps to avoid duplicates when we have the same keys (like 'og:image') but
// different values.
const getUniqueMetaByContentProp = (values) => {
  // As some elements can be added into the end of array we need to put them up in order
  // to remove the right duplicates which are defined in this file first.
  const data = [...values].reverse();
  const b = data;
  let unique1 = data.filter(((set) => (f) => !set.has(f.name) && set.add(f.name))(new Set()));
  unique1 = unique1.filter((arr) => typeof arr.name !== 'undefined');
  let unique2 = b.filter(((set) => (f) => !set.has(f.property) && set.add(f.property))(new Set()));
  unique2 = unique2.filter((arr) => typeof arr.property !== 'undefined');
  return unique1.reverse().concat(unique2);
};

export default getUniqueMetaByContentProp;
