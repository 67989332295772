import React from 'react';

const ArrowBottom = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" fill="none">
    <path
      d="M0.783471 0.8945C1.08721 0.590758 1.57967 0.590758 1.88341 0.8945L6.00011 5.0112L10.1168 0.894501C10.4205 0.59076 10.913 0.59076 11.2167 0.894501C11.5205 1.19824 11.5205 1.6907 11.2167 1.99444L6.55008 6.66111C6.24634 6.96485 5.75388 6.96485 5.45014 6.66111L0.783471 1.99444C0.479729 1.6907 0.47973 1.19824 0.783471 0.8945Z"
      fill="#091543"
    />
  </svg>
);

export default ArrowBottom;
