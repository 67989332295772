import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const TechnicalSolutionsList = ({
  dataItem: {
    field_title,
    field_descriptions,
    field_text_field,
    internal: { type },
    field_image: { alt },
    relationships: { field_image },
  },
}) => (
  <div className="technical-solution-list-wrapper">
    <div className={type}>
      {field_title && (
        <div className="title">
          <div>
            <h2>{field_title}</h2>
          </div>
        </div>
      )}

      <div className="list-items">
        {field_descriptions &&
          field_descriptions.map((item, i) => (
            <div key={uid(item)} className="list-item__single">
              <div>
                <div className="list-item__content">
                  {parser(sanitize(field_descriptions[i].processed))}
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="bottom-section">
        <div className="text-field">
          {field_text_field && (
            <div className="text-field-content">
              <div>
                <span>{parser(sanitize(field_text_field.processed))}</span>
              </div>
            </div>
          )}
        </div>

        <div className="technical-image">
          {field_image && <ImgOrSvg image={field_image} alt={alt} />}
        </div>
      </div>
    </div>
  </div>
);

TechnicalSolutionsList.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_descriptions: PropTypes.array,
    field_text_field: PropTypes.object,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }).isRequired,
};

export default TechnicalSolutionsList;
