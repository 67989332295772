import React from 'react';
import Obfuscate from 'react-obfuscate';

import { Form } from 'components';

const ContactUs = () => (
  <div className="contact-us-header">
    <div className="content">
      <div className="content-box">
        <h1>Let's get in touch!</h1>
        <div className="description">
          Want to discuss your product or partnership? We’re always ready to help.
        </div>
        <div className="info email">
          <span>EMAIL</span>
          <Obfuscate email="presale@anyforsoft.com" linkText=" ">
            presale@anyforsoft.com
          </Obfuscate>
        </div>
        <div className="info next">
          <span>What happens next?</span>
          <div className="info-next">
            <div className="info-next__item">
              <div className="info-next__number">1</div>
              <div className="info-next__text">An expert contacts you</div>
            </div>
            <div className="info-next__item">
              <div className="info-next__number">2</div>
              <div className="info-next__text">If needed, we sign an NDA</div>
            </div>
            <div className="info-next__item">
              <div className="info-next__number">3</div>
              <div className="info-next__text">We submit a comprehensive project</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="webform">
      <div className="webform-box">
        <Form key="contact" formId="contact" inputId="privacy_policy-secondary" withRemoveFileBtn />
      </div>
    </div>
  </div>
);

export default ContactUs;
