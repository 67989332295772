import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CN from 'classnames';

import { HeaderLogo } from 'icons';
import { Link } from 'components';

import { Breadcrumbs, HeaderInfo, HireUs, Menu } from './components';

import './Header.scss';

const Header = ({
  siteTitle,
  mainMenuLinks,
  headerData,
  nodeTitle,
  type,
  showHeaderForm,
  parentBreadcrumb,
  customBreadcrumbs,
  isDefaultBreadcrumbs,
  parentTitle,
  reviews,
  socialLinks,
  location,
  hash,
  isAlternativeHeader,
}) => {
  const [isStickyHeader, setIsStickyHeader] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const isHomePage = location === '/';

  const onMenuToggle = useCallback(() => {
    setIsMenuOpened((prevState) => !prevState);
  }, [isMenuOpened]);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpened(false);
  }, []);

  const scrollHandler = useCallback(() => {
    setIsStickyHeader(window.pageYOffset > 1);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const paragraphHeader = headerData.find(
    (item) => item?.internal?.type === 'paragraph__header_paragraph',
  );

  const backgroundType = paragraphHeader?.field_background_type || '';

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: CN(`page-${type}`, {
            'home-page': isHomePage,
            'sticky-menu': isStickyHeader,
            'menu-opened': isMenuOpened,
            'disabled-scroll-active': isMenuOpened,
            'alternative-header': isAlternativeHeader,
          }),
        }}
      >
        <html lang="en" />
      </Helmet>

      <header
        className={CN('main-header element_to_animate', backgroundType, {
          'header-with-form': showHeaderForm,
          'alternative-story-header': isAlternativeHeader,
        })}
      >
        <div className="header-top">
          <div className="logo">
            <Link to="/" onClick={onCloseMenu}>
              <HeaderLogo className="header-logo" />
            </Link>
          </div>

          <Menu
            isMenuOpened={isMenuOpened}
            onMenuToggle={onMenuToggle}
            siteTitle={siteTitle}
            mainMenuLinks={mainMenuLinks}
            reviews={reviews}
            socialLinks={socialLinks}
            location={location}
            hash={hash}
          />

          <HireUs />
        </div>
      </header>

      <div className="container header-hero">
        {type !== 'front' ? (
          <div className="header-top-box">
            <Breadcrumbs
              location={location}
              nodeTitle={nodeTitle}
              parentBreadcrumb={parentBreadcrumb}
              parentTitle={parentTitle}
              customBreadcrumbs={customBreadcrumbs}
              isDefaultBreadcrumbs={isDefaultBreadcrumbs}
            />
          </div>
        ) : null}

        <HeaderInfo
          type={type}
          showHeaderForm={showHeaderForm}
          headerData={headerData}
          nodeTitle={nodeTitle}
          isAlternativeHeader={isAlternativeHeader}
        />
      </div>
    </>
  );
};

Header.defaultProps = {
  siteTitle: '',
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  mainMenuLinks: PropTypes.array,
  headerData: PropTypes.array,
  nodeTitle: PropTypes.string,
  type: PropTypes.string,
  showHeaderForm: PropTypes.string,
  parentBreadcrumb: PropTypes.string,
  parentTitle: PropTypes.string,
  customBreadcrumbs: PropTypes.array,
  isDefaultBreadcrumbs: PropTypes.bool,
  isAlternativeHeader: PropTypes.bool,
  reviews: PropTypes.arrayOf(PropTypes.object),
  socialLinks: PropTypes.array,
  location: PropTypes.string,
  hash: PropTypes.string,
};

export default Header;
