import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { ImgOrSvg, Link } from 'components';

const TechnicalExpertise = ({
  dataItem: {
    field_title,
    field_grey_background,
    field_extend_grey_background,
    field_alternative_title_style,
    internal: { type },
    relationships: { field_technical_expertise },
  },
}) => {
  const isBackgroundBottom = field_extend_grey_background === 'bottom';
  const isBackgroundTop = field_extend_grey_background === 'top';
  const isAlternativeTitleStyle = field_alternative_title_style;

  const renderCompanies = (companies) =>
    companies.map(
      ({ field_logo, field_link, relationships: { field_logo: rel } }) =>
        rel && (
          <div key={rel.id} className="image-custom-wrapper">
            {field_link?.uri && (
              <Link itemProp="url" to={field_link.uri} className="company-link" />
            )}
            <ImgOrSvg
              image={rel}
              alt={field_logo?.alt}
              title={field_logo?.title}
              className="company-logo"
            />
          </div>
        ),
    );

  return (
    <div
      className={CN(type, {
        'grey-bg': field_grey_background,
        'bottom-background': isBackgroundBottom,
        'bottom-top-background': isBackgroundTop,
        'alternative-title-style': isAlternativeTitleStyle,
      })}
    >
      {field_grey_background && <span className="grey-bg__inner" />}
      {isAlternativeTitleStyle ? <h4>{field_title}</h4> : <h2>{field_title}</h2>}

      <div className="logos-wrapper">{renderCompanies(field_technical_expertise)}</div>
    </div>
  );
};

TechnicalExpertise.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_grey_background: PropTypes.bool,
    field_extend_grey_background: PropTypes.string,
    field_alternative_title_style: PropTypes.bool,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_technical_expertise: PropTypes.array,
    }),
  }),
};

export default TechnicalExpertise;
