import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg } from 'components';

const TeamMemberTeaser = ({ data }) => (
  <div className="team-teaser">
    {data &&
      data.map(
        ({
          relationships: { field_image },
          field_image: { alt },
          title,
          field_position,
          drupal_id,
        }) => (
          <div className="teaser-item" key={drupal_id}>
            <div className="image">
              <ImgOrSvg image={field_image} className="teaser-image" alt={alt} />
            </div>
            <span className="name">{title}</span>
            <p className="position">{field_position}</p>
          </div>
        ),
      )}
  </div>
);

TeamMemberTeaser.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TeamMemberTeaser;
