import React from 'react';
import CN from 'classnames';
import PropTypes from 'prop-types';

const SliderBtn = ({ prevBtn, onClick, disabled }) => (
  <button
    type="button"
    disabled={disabled}
    className={CN({
      'slider-prev': prevBtn,
      'slider-next': !prevBtn,
    })}
    onClick={onClick}
  >
    <div className="inner-element" />
  </button>
);

SliderBtn.propTypes = {
  prevBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

SliderBtn.defaultProps = {
  prevBtn: false,
  disabled: false,
};

export default SliderBtn;
