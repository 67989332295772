import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { Link, ImgOrSvg } from 'components';
import TitleWithDescriptionAndCenterImage from './TitleWithDescriptionAndCenterImage';

const TitleWithDescriptionAndImage = ({
  dataItem,
  dataItem: {
    field_main_title,
    field_title,
    field_description,
    field_discover_more,
    field_layout,
    field_text_spacing,
    field_background,
    field_image_size,
    field_title_font_size,
    field_image_or_json,
    field_disable_json_autoloop,
    internal: { type },
    relationships: { field_image, field_json_lottie },
  },
}) => {
  const disabledLottieParagraph = useRef(null);

  return (
    <div
      className={CN(
        'title-with-description-and-image',
        field_text_spacing && 'text-top',
        field_background === 'grey' && 'grey',
        field_layout === 'image_left' && 'image-left',
        field_layout === 'image_right' && 'image-right',
        field_layout === 'image_right_absolute' && 'image-right image-right-absolute',
        field_layout === 'image_bottom' && 'image-bottom',
        field_layout === 'image_center' && 'image-center',
        field_image_size === 'full_width' && 'image-full-width',
        field_image_size === 'normal' && 'image-with-adaptive',
        field_title_font_size === null ||
          (field_title_font_size === 'normal' && 'normal-font-size'),
        field_title_font_size === 'small' && 'small-font-size',
      )}
      ref={disabledLottieParagraph}
    >
      {field_main_title && <h2 className="paragraph-title">{field_main_title}</h2>}
      <div className={type}>
        {field_layout === 'image_center' ? (
          <TitleWithDescriptionAndCenterImage dataItem={dataItem} />
        ) : (
          <>
            <div className="paragraph__title_with_description_and_image-image">
              <ImgOrSvg
                image={field_image_or_json === 'lottie_json' ? field_json_lottie : field_image}
                alt={dataItem.field_image?.alt || 'Image'}
                field_disable_json_autoloop={field_disable_json_autoloop}
                disabledLottieParagraph={disabledLottieParagraph}
              />
            </div>

            <div className="paragraph__title_with_description_and_image-text">
              <div className="paragraph__title_with_description_and_image-text_inner">
                {field_title && <h2>{field_title}</h2>}
                {field_description && (
                  <div className="description">{parser(sanitize(field_description.value))}</div>
                )}
                {field_discover_more && (
                  <Link to={field_discover_more.uri} className="btn">
                    {field_discover_more.title}
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

TitleWithDescriptionAndImage.propTypes = {
  dataItem: PropTypes.shape({
    field_main_title: PropTypes.string,
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    field_discover_more: PropTypes.object,
    field_layout: PropTypes.string,
    field_text_spacing: PropTypes.bool,
    field_background: PropTypes.string,
    field_image_size: PropTypes.string,
    field_title_font_size: PropTypes.string,
    field_image_or_json: PropTypes.string,
    field_disable_json_autoloop: PropTypes.bool,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_json_lottie: PropTypes.object,
    }),
  }),
};

export default TitleWithDescriptionAndImage;
