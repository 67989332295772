import React from 'react';

const Message = (props) => (
  <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64765 15.2143C6.90565 15.9563 5.70264 15.9563 4.96064 15.2143L3.67699 13.9306C1.89158 13.7441 0.5 12.2347 0.5 10.4V3.8C0.5 1.83939 2.08939 0.25 4.05 0.25H13.95C15.9106 0.25 17.5 1.83939 17.5 3.8V10.4C17.5 12.3606 15.9106 13.95 13.95 13.95H8.91193L7.64765 15.2143ZM6.18924 13.9857C6.2527 14.0491 6.35559 14.0491 6.41905 13.9857L7.63573 12.769C7.99205 12.4127 8.47532 12.2125 8.97923 12.2125H13.95C14.951 12.2125 15.7625 11.401 15.7625 10.4V3.8C15.7625 2.79898 14.951 1.9875 13.95 1.9875H4.05C3.04898 1.9875 2.2375 2.79898 2.2375 3.8V10.4C2.2375 10.9229 2.45859 11.3937 2.81312 11.7249C3.1372 12.0276 3.57173 12.2125 4.05 12.2125C4.28439 12.2125 4.50918 12.3056 4.67491 12.4713L6.18924 13.9857Z"
      />
      <path d="M5.7 8.8375C6.43178 8.8375 7.025 8.24428 7.025 7.5125C7.025 6.78072 6.43178 6.1875 5.7 6.1875C4.96822 6.1875 4.375 6.78072 4.375 7.5125C4.375 8.24428 4.96822 8.8375 5.7 8.8375Z" />
      <path d="M9.00078 8.8375C9.73256 8.8375 10.3258 8.24428 10.3258 7.5125C10.3258 6.78072 9.73256 6.1875 9.00078 6.1875C8.269 6.1875 7.67578 6.78072 7.67578 7.5125C7.67578 8.24428 8.269 8.8375 9.00078 8.8375Z" />
      <path d="M12.2996 8.8375C13.0314 8.8375 13.6246 8.24428 13.6246 7.5125C13.6246 6.78072 13.0314 6.1875 12.2996 6.1875C11.5678 6.1875 10.9746 6.78072 10.9746 7.5125C10.9746 8.24428 11.5678 8.8375 12.2996 8.8375Z" />
    </g>
  </svg>
);

export default Message;
