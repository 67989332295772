import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

import { getAnimationJsonData } from 'api';
import { isInViewport } from 'utils';

const LottieAnimation = ({ animation, field_disable_json_autoloop, disabledLottieParagraph }) => {
  const [animationData, setAnimationData] = useState('');
  const [autoPlay, setAutoPlay] = useState(!field_disable_json_autoloop);

  useEffect(() => {
    const scrollHandler = () => {
      if (isInViewport(disabledLottieParagraph.current)) {
        setAutoPlay(true);
      }
    };

    if (field_disable_json_autoloop) {
      window.addEventListener('scroll', scrollHandler);
    }

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  useEffect(() => {
    getAnimationJsonData(animation).then((res) => {
      setAnimationData(res.data);
    });
  }, []);

  return (
    <Lottie animationData={animationData} loop={!field_disable_json_autoloop} autoplay={autoPlay} />
  );
};

LottieAnimation.propTypes = {
  animation: PropTypes.string.isRequired,
  field_disable_json_autoloop: PropTypes.bool,
  disabledLottieParagraph: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default LottieAnimation;
