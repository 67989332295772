import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

const TextField = ({ value, name, label, error, onChange, onBlur }) => (
  <div className={CN('form-item item-type-input', { error })}>
    <input value={value} type="text" name={name} onChange={onChange} onBlur={onBlur} />
    {error && <div className="error-message">{error}</div>}
    <label className={CN({ fixed: value })}>
      {label}
      <span className={CN('circle', error || !value ? 'error' : 'success')} />
    </label>
    <div className="on-focus" />
  </div>
);

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default TextField;
