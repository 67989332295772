import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

const HighlightedText = ({
  dataItem: {
    field_highlighted_description,
    internal: { type },
  },
}) => (
  <div className={type}>
    <span className="blue-bg__inner" />
    <div className="top-side">
      {field_highlighted_description && (
        <div className="description">
          {parser(sanitize(field_highlighted_description.processed))}
        </div>
      )}
    </div>
  </div>
);

HighlightedText.propTypes = {
  dataItem: PropTypes.shape({
    field_highlighted_description: PropTypes.object,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default HighlightedText;
