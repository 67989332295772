import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg, VideoPlayer } from 'components';

const StoryHeader = ({
  data: {
    title,
    field_description,
    field_what_to_display,
    field_json_lottie,
    field_image,
    alt,
    field_video_file,
    field_video_file_preview,
    field_video_autoplay_status,
    field_video_url,
  },
}) => (
  <div className="page-story-top element_to_animate">
    <div className="page-story-top-text fade-in-to-right-custom">
      <h1>{title}</h1>
      {field_description && <div>{parser(sanitize(field_description.processed))}</div>}
    </div>
    {(field_what_to_display === 'image' || field_what_to_display === 'lottie_json') && (
      <div className="page-story-top-image fade-in-to-left-custom">
        <ImgOrSvg
          image={field_what_to_display === 'lottie_json' ? field_json_lottie : field_image}
          alt={alt}
        />
      </div>
    )}
    {field_what_to_display === 'video' && (
      <div className="page-story-top-video">
        <VideoPlayer
          video_file={field_video_file?.localFile?.publicURL}
          video_preview={field_video_file_preview?.localFile?.publicURL}
          id={field_video_file?.drupal_id}
          autoplay_status={field_video_autoplay_status}
          field_link={field_video_url?.uri}
        />
      </div>
    )}
  </div>
);

StoryHeader.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    field_description: PropTypes.shape({
      processed: PropTypes.string,
    }),
    field_what_to_display: PropTypes.string,
    field_json_lottie: PropTypes.object,
    field_image: PropTypes.object,
    alt: PropTypes.string,
    field_video_file: PropTypes.object,
    field_video_file_preview: PropTypes.object,
    field_video_autoplay_status: PropTypes.bool,
    field_video_url: PropTypes.string,
  }).isRequired,
};

export default StoryHeader;
