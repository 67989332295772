import React from 'react';
import PropTypes from 'prop-types';

import { StatisticItem } from './StatisticItem';

const Statistic = ({
  dataItem: {
    internal: { type },
    field_display_link,
    relationships: { field_paragraph },
  },
}) => (
  <div className={type}>
    {field_paragraph &&
      field_paragraph.map((item) => <StatisticItem key={item.id} dataItem={item} />)}

    {field_display_link && (
      <div className="afs-clutch-widget paragraph__statistic_block_item">
        <div
          className="clutch-widget statistic-widget"
          data-url="https://widget.clutch.co"
          data-widget-type="2"
          data-expandifr="true"
          data-height="100"
          data-width="500"
          data-theme="white"
          data-nofollow="true"
          data-snippets="true"
          data-clutchcompany-id="335158"
          data-primary-color="#fffff"
          data-secondary-color="#fffff"
        />
      </div>
    )}
  </div>
);

Statistic.propTypes = {
  dataItem: PropTypes.shape({
    field_display_link: PropTypes.bool,
    internal: PropTypes.shape({ type: PropTypes.string }),
    relationships: PropTypes.shape({ field_paragraph: PropTypes.arrayOf(PropTypes.object) }),
  }),
};

export default Statistic;
