import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IndustryItem } from '../IndustryItem';

const Accordion = ({ items }) => {
  const [activeAcc, setActiveAcc] = useState([0]);

  const toggleActiveAcc = (i) => () => {
    if (activeAcc.includes(i)) {
      const newList = activeAcc.filter((item) => item !== i);
      setActiveAcc(newList);
    } else {
      setActiveAcc([...activeAcc, i]);
    }
  };

  return (
    <>
      {items &&
        items.map((item, index) => (
          <IndustryItem
            isActive={activeAcc.includes(index)}
            index={index}
            data={item}
            key={item.title}
            toggleActiveAcc={toggleActiveAcc}
            type="accordion"
          />
        ))}
    </>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default Accordion;
