import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { sanitize, parser } from 'utils';

import { ImgOrSvg, Link } from 'components';

const FloatingBar = ({
  node: {
    body: { processed: text },
    field_hide_block,
    field_image,
    field_remove_image,
    field_button,
    field_remove_button,
    relationships: { field_image: image },
  },
}) => {
  const initialVisibleState = typeof window !== 'undefined' && !sessionStorage.getItem('hello-bar');
  const [isVisible, setVisible] = useState(initialVisibleState);

  const onClose = () => {
    setVisible(false);
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('hello-bar', 'closed');
    }
  };

  if (field_hide_block || !isVisible) {
    return null;
  }

  return (
    <span className="floating-bar">
      <div className="container">
        <div
          className={CN('floating-bar-wrapper', {
            'single-button': field_remove_button,
          })}
        >
          {!field_remove_image && <ImgOrSvg image={image[0]} alt={field_image?.[0]?.alt} />}
          <div className="floating-bar-text">
            {field_remove_button ? (
              parser(sanitize(text))
            ) : (
              <Link to={field_button.uri} onClick={onClose}>
                {parser(sanitize(text))}
              </Link>
            )}
          </div>
          {!field_remove_button && (
            <Link className="btn white" to={field_button.uri} onClick={onClose}>
              {field_button.title}
            </Link>
          )}
          <button type="button" className="close-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </span>
  );
};

FloatingBar.propTypes = {
  node: PropTypes.shape({
    body: PropTypes.shape({
      processed: PropTypes.string,
    }),
    field_hide_block: PropTypes.bool,
    field_image: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string,
      }),
    ),
    field_remove_image: PropTypes.bool,
    field_button: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    field_remove_button: PropTypes.bool,
    relationships: PropTypes.shape({
      field_image: PropTypes.array,
    }),
  }),
};

export default FloatingBar;
