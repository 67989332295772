import React from 'react';

import Background from './Images/ResultsBackground.svg';

const ResultsBackground = () => (
  <div className="results-background-elements">
    <img className="results-background" src={Background} alt="" />
    <img className="results-mobile-background" src={Background} alt="" />
  </div>
);

export default ResultsBackground;
