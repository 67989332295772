import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import { sanitize, parser } from 'utils';

const FAQItem = ({
  data: {
    field_question,
    field_answer: { processed: answer },
  },
  index,
  active,
  toggleActiveAcc,
}) => (
  <div
    className={CN('accordion__item', {
      active,
    })}
    itemProp="mainEntity"
    itemScope
    itemType="https://schema.org/Question"
  >
    <div
      className="accordion__item-question"
      onClick={toggleActiveAcc(index)}
      role="button"
      tabIndex={0}
      aria-hidden
    >
      <h4 className="accordion__item-title" itemProp="name">
        {field_question}
      </h4>
    </div>
    <div
      className="accordion__item-answer"
      itemScope
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
    >
      {parser(sanitize(answer))}
      <p className="hidden" itemProp="text">
        {answer.replace(/(<([^>]+)>)/gi, '')}
      </p>
    </div>
  </div>
);

FAQItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    field_question: PropTypes.string.isRequired,
    field_answer: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }).isRequired,
  }),
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  toggleActiveAcc: PropTypes.func.isRequired,
};

export default FAQItem;
