import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const WpsItem = ({
  data: {
    title,
    field_description,
    field_position,
    field_company,
    field_country_flag: { alt },
    relationships: { field_client_main_image: image, field_country_flag },
    field_client_main_image,
  },
}) => (
  <div className="wps-item">
    <div className="holder">
      <div className="wps-left">
        <div className="wps-author-wrap">
          {image && (
            <ImgOrSvg
              className="wps-author-photo"
              image={image}
              alt={field_client_main_image?.alt || 'Client'}
              quality={100}
            />
          )}
          <div className="wps-author-description">
            <div className="wps-author-name">
              <ImgOrSvg className="country-flag" image={field_country_flag} alt={alt} />
              <span>{title}</span>
            </div>
            <div className="wps-position-and-company">
              {field_position && <span className="wps-position">{field_position}</span>}
              {field_company && <span className="wps-company">{field_company}</span>}
            </div>
          </div>
        </div>
      </div>
      <div className="wps-right">
        {field_description?.value && (
          <div className="wps-text">{parser(sanitize(field_description.value))}</div>
        )}
      </div>
    </div>
  </div>
);

WpsItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    field_position: PropTypes.string,
    field_company: PropTypes.string,
    field_description: PropTypes.object,
    field_country: PropTypes.string,
    field_client_main_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_country_flag: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_client_main_image: PropTypes.object,
      field_country_flag: PropTypes.object,
    }),
  }).isRequired,
};

export default WpsItem;
