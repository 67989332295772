import React from 'react';
import PropTypes from 'prop-types';

const EventIcon = ({ color, type }) => {
  switch (type) {
    case 'calendar':
      return (
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="event-icon"
        >
          <path
            d="M26.9759 19.2998C27.5815 19.2998 28.0724 18.8089 28.0724 18.2032V6.57948C28.0724 4.16086 26.1047 2.19316 23.6861 2.19316H22.2606V1.09658C22.2606 0.490939 21.7696 0 21.164 0C20.5583 0 20.0674 0.490939 20.0674 1.09658V2.19316H15.078V1.09658C15.078 0.490939 14.587 0 13.9814 0C13.3758 0 12.8848 0.490939 12.8848 1.09658V2.19316H7.9502V1.09658C7.9502 0.490939 7.45926 0 6.85362 0C6.24798 0 5.75704 0.490939 5.75704 1.09658V2.19316H4.38632C1.9677 2.19316 0 4.16086 0 6.57948V23.6861C0 26.1047 1.9677 28.0724 4.38632 28.0724H23.6861C26.1047 28.0724 28.0724 26.1047 28.0724 23.6861C28.0724 23.0805 27.5815 22.5895 26.9759 22.5895C26.3702 22.5895 25.8793 23.0805 25.8793 23.6861C25.8793 24.8954 24.8954 25.8793 23.6861 25.8793H4.38632C3.17701 25.8793 2.19316 24.8954 2.19316 23.6861V6.57948C2.19316 5.37017 3.17701 4.38632 4.38632 4.38632H5.75704V5.4829C5.75704 6.08854 6.24798 6.57948 6.85362 6.57948C7.45926 6.57948 7.9502 6.08854 7.9502 5.4829V4.38632H12.8848V5.4829C12.8848 6.08854 13.3758 6.57948 13.9814 6.57948C14.587 6.57948 15.078 6.08854 15.078 5.4829V4.38632H20.0674V5.4829C20.0674 6.08854 20.5583 6.57948 21.164 6.57948C21.7696 6.57948 22.2606 6.08854 22.2606 5.4829V4.38632H23.6861C24.8954 4.38632 25.8793 5.37017 25.8793 6.57948V18.2032C25.8793 18.8089 26.3702 19.2998 26.9759 19.2998Z"
            fill={color}
          />
          <path
            d="M6.85366 12.6111C7.45928 12.6111 7.95024 12.1202 7.95024 11.5145C7.95024 10.9089 7.45928 10.418 6.85366 10.418C6.24804 10.418 5.75708 10.9089 5.75708 11.5145C5.75708 12.1202 6.24804 12.6111 6.85366 12.6111Z"
            fill={color}
          />
          <path
            d="M16.3939 12.6111C16.9996 12.6111 17.4905 12.1202 17.4905 11.5145C17.4905 10.9089 16.9996 10.418 16.3939 10.418C15.7883 10.418 15.2974 10.9089 15.2974 11.5145C15.2974 12.1202 15.7883 12.6111 16.3939 12.6111Z"
            fill={color}
          />
          <path
            d="M21.164 12.6111C21.7696 12.6111 22.2605 12.1202 22.2605 11.5145C22.2605 10.9089 21.7696 10.418 21.164 10.418C20.5583 10.418 20.0674 10.9089 20.0674 11.5145C20.0674 12.1202 20.5583 12.6111 21.164 12.6111Z"
            fill={color}
          />
          <path
            d="M6.85366 17.3816C7.45928 17.3816 7.95024 16.8907 7.95024 16.2851C7.95024 15.6794 7.45928 15.1885 6.85366 15.1885C6.24804 15.1885 5.75708 15.6794 5.75708 16.2851C5.75708 16.8907 6.24804 17.3816 6.85366 17.3816Z"
            fill={color}
          />
          <path
            d="M6.85366 22.1502C7.45928 22.1502 7.95024 21.6592 7.95024 21.0536C7.95024 20.448 7.45928 19.957 6.85366 19.957C6.24804 19.957 5.75708 20.448 5.75708 21.0536C5.75708 21.6592 6.24804 22.1502 6.85366 22.1502Z"
            fill={color}
          />
          <path
            d="M11.6237 12.6111C12.2293 12.6111 12.7203 12.1202 12.7203 11.5145C12.7203 10.9089 12.2293 10.418 11.6237 10.418C11.0181 10.418 10.5271 10.9089 10.5271 11.5145C10.5271 12.1202 11.0181 12.6111 11.6237 12.6111Z"
            fill={color}
          />
          <path
            d="M11.6237 17.3816C12.2293 17.3816 12.7203 16.8907 12.7203 16.2851C12.7203 15.6794 12.2293 15.1885 11.6237 15.1885C11.0181 15.1885 10.5271 15.6794 10.5271 16.2851C10.5271 16.8907 11.0181 17.3816 11.6237 17.3816Z"
            fill={color}
          />
          <path
            d="M11.6237 22.1502C12.2293 22.1502 12.7203 21.6592 12.7203 21.0536C12.7203 20.448 12.2293 19.957 11.6237 19.957C11.0181 19.957 10.5271 20.448 10.5271 21.0536C10.5271 21.6592 11.0181 22.1502 11.6237 22.1502Z"
            fill={color}
          />
          <path
            d="M16.3939 17.3816C16.9996 17.3816 17.4905 16.8907 17.4905 16.2851C17.4905 15.6794 16.9996 15.1885 16.3939 15.1885C15.7883 15.1885 15.2974 15.6794 15.2974 16.2851C15.2974 16.8907 15.7883 17.3816 16.3939 17.3816Z"
            fill={color}
          />
          <path
            d="M21.164 17.3816C21.7696 17.3816 22.2605 16.8907 22.2605 16.2851C22.2605 15.6794 21.7696 15.1885 21.164 15.1885C20.5583 15.1885 20.0674 15.6794 20.0674 16.2851C20.0674 16.8907 20.5583 17.3816 21.164 17.3816Z"
            fill={color}
          />
          <path
            d="M16.3939 22.1502C16.9996 22.1502 17.4905 21.6592 17.4905 21.0536C17.4905 20.448 16.9996 19.957 16.3939 19.957C15.7883 19.957 15.2974 20.448 15.2974 21.0536C15.2974 21.6592 15.7883 22.1502 16.3939 22.1502Z"
            fill={color}
          />
        </svg>
      );
    case 'time':
      return (
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="event-icon"
        >
          <path
            d="M14.0362 0C6.28423 0 0 6.28423 0 14.0362C0 21.7882 6.28423 28.0724 14.0362 28.0724C21.7882 28.0724 28.0724 21.7882 28.0724 14.0362C28.0642 6.2877 21.7848 0.0082831 14.0362 0ZM14.0362 26.0673C7.39164 26.0673 2.00516 20.6808 2.00516 14.0362C2.00516 7.39164 7.39164 2.00516 14.0362 2.00516C20.6808 2.00516 26.0673 7.39164 26.0673 14.0362C26.0601 20.6779 20.6779 26.0601 14.0362 26.0673Z"
            fill={color}
          />
          <path
            d="M14.0362 5.01367C13.4825 5.01367 13.0336 5.46255 13.0336 6.01628V13.0344H6.01555C5.46181 13.0344 5.01294 13.4833 5.01294 14.037C5.01294 14.5908 5.46181 15.0396 6.01555 15.0396H14.0362C14.59 15.0396 15.0388 14.5907 15.0388 14.037V6.01628C15.0388 5.46255 14.59 5.01367 14.0362 5.01367Z"
            fill={color}
          />
        </svg>
      );
    case 'place':
      return (
        <svg
          width="11"
          height="29"
          viewBox="0 0 11 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="event-icon"
        >
          <path
            d="M5.38372 0.407227C2.40979 0.407858 -0.000630909 2.81916 1.23872e-07 5.79315C0.00050495 8.35155 1.80116 10.5564 4.30787 11.0681V27.3302C4.30787 27.9251 4.79004 28.4072 5.38486 28.4072C5.97967 28.4072 6.46184 27.9251 6.46184 27.3302V11.0681C8.96729 10.5528 10.7664 8.34884 10.7697 5.79095C10.769 2.81701 8.35765 0.406596 5.38372 0.407227Z"
            fill={color}
          />
        </svg>
      );
    default:
      return null;
  }
};

EventIcon.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
};

EventIcon.defaultProps = {
  color: '#FFDF33',
};

export default EventIcon;
