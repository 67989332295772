export const TWITTER_HANDLE = 'anyforsoft';
export const SITE_DOMAIN = 'https://anyforsoft.com';

export const MIN_VIEWPORT_HEIGHT = 767;

export const BLOG_KEYWORDS = ['blog', 'gatsby', 'javascript', 'react'];

export const BLOG_CATEGORIES_DESCRIPTION = {
  blog:
    'AnyforSoft Blog is a platform where we share our latest business and tech insights to help you boost, improve and elevate your project.',
  news:
    'Transparency is one of our core principles. We are happy to share with you our latest news and achievements.',
  design:
    'Design is not only about interface or experience. The proper design is your loud statement. And we know how to make it clear and catchy.',
  tech:
    'Technology rules the world and we know how to implement it in your business. Discover our research and reviews.',
  business:
    'We are keen on your needs and know how to solve your business issues. Need fresh ideas? We share some right here.',
  drupal:
    'We are a development company with a wide experience in Drupal technology challenges. Learn more about our expertise.',
  events: 'Celebrate results and important events with our friendly and well-bonded team.',
  js:
    'We always strive to find or develop the state of art mobile or web solutions for our projects. Hence, we need flexible and scalable tech, like JS.',
};

export const SOCIAL_LINKS = {
  linkedin: 'https://www.linkedin.com/',
  twitter: 'https://www.twitter.com/',
  drupal: 'https://www.drupal.org/',
  behance: 'https://www.behance.net/',
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  clutch: 'https://clutch.co/profile/',
};

// Dynamic subheading
export const TEXT_DISPLAY_TIME = 3; // Amount of second to read each row
export const PLAY_PAUSE_RATIO = 3.16; // Frame pause will be X times longer than frame change

export const FAILED_FORM_MESSAGE =
  '<h3>Unexpected error occurred!</h3><p>Please, submit the web form again.</p>';
