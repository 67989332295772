import React from 'react';
import PropTypes from 'prop-types';
import { ImgOrSvg } from 'components/ImgOrSvg';

const PortfolioBanner = ({ data, title }) => {
  const { field_pr_bn_text, relationships, field_pr_bn_logos } = data[0];
  const formattedArray = relationships.field_pr_bn_logos.map((item, index) => ({
    ...item,
    alt: field_pr_bn_logos[index].alt,
  }));

  return (
    <section className="portfolio-banner">
      <div className="portfolio-banner-container">
        <div className="portfolio-banner__title">
          <h1>{title}</h1>
        </div>

        <div className="portfolio-banner__media">
          <span className="banner-subtitle">{field_pr_bn_text}</span>
          <div className="banner-partner-list">
            {formattedArray.map((item) => (
              <div className="partner-item" key={item?.localFile?.publicURL}>
                <ImgOrSvg image={item} alt={item.alt} objectFit="contain" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

PortfolioBanner.propTypes = { data: PropTypes.array, title: PropTypes.string };

export default PortfolioBanner;
