import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import CN from 'classnames';
import PropTypes from 'prop-types';

import { ArrowBottom } from 'icons';

import { Checkbox } from 'components/Forms/components/components';

const targetCookie =
  'These cookies are employed to enhance the relevance of advertising messages specifically for you. They can' +
  ' be set by us or our advertising partners through our website. These cookies may assist in creating a' +
  ' profile of your interests and displaying relevant advertisements on our site or other websites. They' +
  ' do not store personal information directly but rely on uniquely identifying your browser and device.';
const analyticCookie =
  'These cookies enable us to track and analyze visits and traffic sources, allowing us to evaluate and enhance' +
  ' the performance of our website. They assist us in identifying the most and least popular pages and understanding ' +
  'how visitors navigate through the site, which aids in optimizing your overall experience. The data collected by' +
  ' these cookies is aggregated and anonymized, ensuring your privacy. If you choose not to permit these cookies, we' +
  ' will be unable to utilize your data for these purposes.';
const functionalityCookie =
  'These cookies enable the website to offer improved functionality and personalized experiences. They can be set' +
  ' either by us or by third party providers whose services we have integrated into our pages. If you choose not' +
  ' to enable these cookies, some or all of these services may not operate effectively.';
const necessaryCookie =
  'These cookies play a vital role in ensuring the smooth functioning of our website and cannot be disabled within our systems.' +
  ' They are typically activated in response to your specific actions that require services, such as adjusting privacy' +
  ' preferences, logging in, filling out forms, or when they are crucial to delivering a service you have explicitly requested.' +
  ' Opting out of these cookies is not possible. These cookies do not store any personally identifiable information.';

const cookieSettingDescription =
  'You can manage your cookie' +
  ' preferences by clicking on checkbox headings. Note that blocking certain cookies may impact your site experience.';

const CookieSettingPopup = ({ onBack, onConfirm, customizedCookies, handleCustomizedChange }) => {
  const [openedId, setOpenedId] = useState('');

  const adValue = customizedCookies.ad_storage;
  const analyticValue = customizedCookies.analytics_storage;
  const functionalityValue = customizedCookies.functionality_storage;

  const handleChange = (name, value) => {
    const tagValue = adValue || analyticValue || functionalityValue;

    handleCustomizedChange(name, !value);
    handleCustomizedChange('gatsby-gdpr-google-tagmanager', tagValue);
  };

  const toggleAccordion = (id) => () => {
    if (id === openedId) {
      setOpenedId('');
    } else {
      setOpenedId(id);
    }
  };

  const renderCollapseElement = (text, id) => {
    const isOpen = id === openedId;

    return (
      <>
        <button
          className={CN('accordion__button', {
            expanded: isOpen,
          })}
          onClick={toggleAccordion(id)}
          type="button"
        >
          <ArrowBottom />
        </button>

        <Collapse isOpened={isOpen}>
          <div className="collapse-text">{text}</div>
        </Collapse>
      </>
    );
  };

  return (
    <div className="cookie-setting-popup">
      <h3>Customize Cookies</h3>
      <div className="cookie-setting-container">
        <div className="cookie-setting-description">{cookieSettingDescription}</div>

        <div className="field-box">
          <Checkbox
            onBlur={() => {}}
            onChange={() => {}}
            name="necessaryCookie"
            checked
            id="necessaryCookie"
            error=""
            label="Strictly necessary cookie"
            helperText={renderCollapseElement(necessaryCookie, 'necessaryCookie')}
          />

          <Checkbox
            onBlur={() => {}}
            onChange={() => handleChange('ad_storage', adValue)}
            name="ad_storage"
            checked={adValue}
            id="ad_storage"
            error=""
            label="Target cookie"
            helperText={renderCollapseElement(targetCookie, 'ad_storage')}
          />

          <Checkbox
            onBlur={() => {}}
            onChange={() => handleChange('analytics_storage', analyticValue)}
            name="analytics_storage"
            checked={analyticValue}
            id="analytics_storage"
            error=""
            label="Analytic cookie"
            helperText={renderCollapseElement(analyticCookie, 'analytics_storage')}
          />

          <Checkbox
            onBlur={() => {}}
            onChange={() => handleChange('functionality_storage', functionalityValue)}
            name="functionality_storage"
            checked={functionalityValue}
            id="functionality_storage"
            error=""
            label="Functionality cookie"
            helperText={renderCollapseElement(functionalityCookie, 'functionality_storage')}
          />
        </div>
      </div>
      <div className="btn-container">
        <button type="button" className="btn back" onClick={onBack}>
          Back
        </button>
        <button type="button" className="btn save" onClick={onConfirm}>
          Save Settings
        </button>
      </div>
    </div>
  );
};

CookieSettingPopup.propTypes = {
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  customizedCookies: PropTypes.object.isRequired,
  handleCustomizedChange: PropTypes.func.isRequired,
};

export default CookieSettingPopup;
