import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick/lib/slider';

import { parser, sanitize } from 'utils';

import { SliderBtn } from 'components/SliderBtn';
import { TechnologyItem } from './TechnologyItem';

const SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TechnologyList = ({
  dataItem: {
    field_technology_title,
    internal: { type },
    relationships: {
      field_technology_items,
      field_technology_items: { length },
    },
    field_description,
  },
}) => {
  const [currentSlide, setCurrentSlice] = useState(0);
  const [highestItemHeight, setHighestItemHeight] = useState(0);
  const slider = useRef(null);

  const isPrevBtnDisabled = currentSlide === 0;
  const isNextBtnDisabled = currentSlide === length - 1;
  const extraSlide = field_technology_items[0];

  const handleNext = () => slider.current.slickNext();

  const handlePrev = () => slider.current.slickPrev();

  const calculateHighestItemHeight = () => {
    const listItems = Array.from(slider.current.innerSlider.list.children[0].children);
    let maxItemHeight = 0;

    listItems.forEach((item) => {
      const itemHeight = item.offsetHeight;
      let descriptionHeight = item.querySelector('.technology-description').offsetHeight;

      // Check if the item is the currently expanded item
      if (item.classList.contains('slick-current')) {
        descriptionHeight = 0;
      }

      const totalHeight = itemHeight + descriptionHeight;
      maxItemHeight = Math.max(maxItemHeight, totalHeight);
    });

    setHighestItemHeight(maxItemHeight);
  };

  const handleBeforeChange = (_, currentIndex) => {
    setCurrentSlice(currentIndex);
  };

  const sliderSettings = { ...SLIDER_SETTINGS, beforeChange: handleBeforeChange };

  useEffect(() => {
    calculateHighestItemHeight();
  }, []);

  return (
    <div className={type}>
      <div className="technologies-text">
        <h2>{field_technology_title}</h2>
        {field_description && (
          <div className="description">{parser(sanitize(field_description.value))}</div>
        )}

        {length > 1 && (
          <div className="slider-buttons">
            <SliderBtn prevBtn onClick={handlePrev} disabled={isPrevBtnDisabled} />
            <SliderBtn onClick={handleNext} disabled={isNextBtnDisabled} />
          </div>
        )}
      </div>

      <div className="technologies-media" style={{ minHeight: highestItemHeight }}>
        <Slider {...sliderSettings} ref={slider} className="technologies-media-slider">
          {field_technology_items &&
            [...field_technology_items, extraSlide].map((item, index) => {
              const isActiveSlide = currentSlide === index;

              return <TechnologyItem key={item.drupal_id} data={item} isActive={isActiveSlide} />;
            })}
        </Slider>
      </div>
    </div>
  );
};

TechnologyList.propTypes = {
  dataItem: PropTypes.shape({
    field_technology_title: PropTypes.string,
    field_description: PropTypes.object,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_technology_items: PropTypes.array,
    }),
  }),
};

export default TechnologyList;
