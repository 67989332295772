import React from 'react';
import PropTypes from 'prop-types';

import { CTA, CaseSolutionItem } from './components';

const CaseSolution = ({
  dataItem: {
    internal: { type },
    field_cta_text,
    field_cta,
    relationships: { field_solution_item },
  },
}) => (
  <div className={type}>
    <div className="case-solution-list">
      {field_solution_item.map((item) => (
        <CaseSolutionItem key={item.id} item={item} />
      ))}
    </div>
    <CTA field_cta={field_cta} field_cta_text={field_cta_text} />
  </div>
);

CaseSolution.propTypes = {
  dataItem: PropTypes.shape({
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_cta_text: PropTypes.object,
    field_cta: PropTypes.object,
    relationships: PropTypes.shape({
      field_solution_item: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default CaseSolution;
