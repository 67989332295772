import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { BlogCard, RecommendedButton } from 'components';

const query = graphql`
  query {
    news {
      items {
        body {
          processed
        }
        field_reading_time {
          value
        }
        field_image {
          alt
          height
          target_id
          target_type
          target_uuid
          title
          url
          width
          localFile {
            childImageSharp {
              fluid(quality: 90, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            publicURL
            extension
          }
        }
        field_blog_category {
          name {
            value
          }
          tid {
            value
          }
        }
        nid {
          value
        }
        title {
          value
        }
        path {
          alias
        }
        created {
          value(fromNow: true)
        }
      }
    }
  }
`;

const OurNews = () => {
  const {
    news: { items },
  } = useStaticQuery(query);

  return (
    <>
      <div className="block-content">
        <div className="content-box">
          {items.map(
            ({ path, field_blog_category, field_reading_time, title, field_image, nid }) => (
              <BlogCard
                path={path[0]?.alias}
                category={field_blog_category.name}
                readingTime={field_reading_time[0].value}
                title={title[0].value}
                image={field_image?.[0]}
                alt={field_image?.[0].alt}
                key={nid[0].value}
              />
            ),
          )}
        </div>
      </div>
      <RecommendedButton />
    </>
  );
};

export default OurNews;
