import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import { OurTeamGrid } from './OurTeamGrid';
import { OurTeamGridSpeaker } from './OurTeamGridSpeaker';
import { OurTeamSlider } from './OurTeamSlider';

const OurTeam = ({
  dataItem: {
    field_type,
    field_display_as_grid,
    field_title,
    internal: { type },
    relationships: { field_reference },
  },
}) => (
  <div className={CN('our-team', field_type && field_type === 'speaker' ? 'speakers' : '')}>
    <div className={type}>
      <div>
        <h2>{field_title}</h2>
      </div>

      {/* eslint-disable-next-line no-nested-ternary */}
      {field_display_as_grid ? (
        field_type && field_type === 'speaker' ? (
          <OurTeamGridSpeaker rel={field_reference} />
        ) : (
          <OurTeamGrid rel={field_reference} />
        )
      ) : (
        <div className="members-list">
          <OurTeamSlider rel={field_reference} />
        </div>
      )}
    </div>
  </div>
);

OurTeam.propTypes = {
  dataItem: PropTypes.shape({
    field_type: PropTypes.string,
    field_display_as_grid: PropTypes.bool,
    field_title: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.object,
  }).isRequired,
};

OurTeamGridSpeaker.propTypes = {
  dataItem: PropTypes.shape({
    field_type: PropTypes.string,
    field_display_as_grid: PropTypes.bool,
    field_title: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.object,
  }).isRequired,
};

export default OurTeam;
