const defaultPreview = '/anyforsoft_social.jpg';

const getImagePreview = (headerImage = null, openGraphImage = null) => {
  let imageUrl;

  if (headerImage) {
    imageUrl = headerImage;
  } else if (openGraphImage) {
    imageUrl = openGraphImage;
  } else {
    imageUrl = defaultPreview;
  }
  return imageUrl;
};

export default getImagePreview;
