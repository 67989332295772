import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { Link } from 'components';
import { Message } from 'icons';

const BeInTouch = ({ margin, blueBg, description, title, buttonTitle }) => (
  <div className={CN('paragraph_be-in-touch', blueBg, margin)}>
    {blueBg && blueBg !== 'without-wave-bg' && <span className="blue-bg__inner" />}

    <div className="be-in-touch">
      <h2>{title}</h2>
      {description && <div className="subtitle">{parser(sanitize(description.processed))}</div>}

      <div className="wrp-button">
        <Link to="/contact-us" className="btn hire">
          {buttonTitle}
          <div className="btn-svg">
            <Message />
          </div>
        </Link>
      </div>
    </div>
  </div>
);

BeInTouch.propTypes = {
  blueBg: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  margin: PropTypes.string,
};

BeInTouch.defaultProps = {
  description: '',
  margin: '',
};

export default BeInTouch;
