import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils/index';

import { Link } from 'components';
import { Arrow } from 'icons';

const TextSliderItem = ({ data: { field_description, field_discover_more_link } }) => (
  <div className="text-slider-item">
    {field_description && (
      <div className="text-slider-item__description">
        {parser(sanitize(field_description.processed))}
      </div>
    )}
    {field_discover_more_link && (
      <Link to={field_discover_more_link.uri} className="link-show-more">
        {field_discover_more_link.title || 'Discover More'}
        <Arrow color="#00786E" />
      </Link>
    )}
  </div>
);

TextSliderItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TextSliderItem;
