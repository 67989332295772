import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

const ResultItem = ({ data: { field_title, field_subtitle, field_description } }) => (
  <div className="result-item">
    {field_title && <h3 className="result-item-title">{field_title}</h3>}
    {field_subtitle && <span className="result-item-subtitle">{field_subtitle}</span>}

    {field_description && (
      <div className="result-item-description">{parser(sanitize(field_description.processed))}</div>
    )}
  </div>
);

ResultItem.propTypes = {
  data: PropTypes.shape({
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    field_description: PropTypes.object,
  }),
};

export default ResultItem;
