import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'react-modern-drawer';
import CN from 'classnames';

import { NestedNavigationItem } from 'components/Header/components/NestedNavigationItem';
import { DrawerNavigation } from 'components/Header/components';

import 'react-modern-drawer/dist/index.css';

const Menu = ({ isMenuOpened, onMenuToggle, mainMenuLinks }) => (
  <div className="menu">
    <div className="menu-desktop">
      {mainMenuLinks.map((item) => (
        <NestedNavigationItem data={item} key={item.link.url} />
      ))}
    </div>

    <div className={CN('burger-menu', { active: isMenuOpened })}>
      <button
        className={CN('burger-btn', { open: isMenuOpened })}
        onClick={onMenuToggle}
        onKeyDown={onMenuToggle}
        type="button"
        tabIndex={0}
      >
        <div className="menu-icon">
          <div className="top" />
          <div className="middle" />
          <div className="middle" />
          <div className="bottom" />
        </div>
      </button>

      <Drawer
        open={isMenuOpened}
        lockBackgroundScroll
        overlayOpacity={1}
        onClose={onMenuToggle}
        direction="left"
        className="drawer"
      >
        <DrawerNavigation mainMenuLinks={mainMenuLinks} onClose={onMenuToggle} />
      </Drawer>
    </div>
  </div>
);

Menu.propTypes = {
  isMenuOpened: PropTypes.bool.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  mainMenuLinks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Menu;
