import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { Link } from 'components';

const TechnologyItem = ({
  data: {
    title,
    field_description,
    path: { alias },
  },
}) => (
  <div itemScope itemType="https://schema.org/Specialty" className="technology-list__item">
    <div className="technology-list__box">
      <h3 itemProp="name">{title}</h3>
      {field_description && (
        <div itemProp="description">{parser(sanitize(field_description.processed))}</div>
      )}
      <div className="item-link">
        <Link to={alias} className="btn--arrow">
          Discover more
        </Link>
      </div>
    </div>
  </div>
);

TechnologyItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    field_description: PropTypes.object,
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
  }).isRequired,
};

export default TechnologyItem;
