const getPicturesColumns = (picturesArray, altsArray) => {
  const reducer = (acc, { localFile, id }, index) => {
    const newItem = {
      image: { localFile },
      id,
      alt: altsArray?.[index]?.alt || 'AnyforSoft',
    };

    return index % 2
      ? {
          smallColumn: [...acc.smallColumn, newItem],
          bigColumn: acc.bigColumn,
        }
      : {
          bigColumn: [...acc.bigColumn, newItem],
          smallColumn: acc.smallColumn,
        };
  };

  return picturesArray.reduce(reducer, { bigColumn: [], smallColumn: [] });
};

export default getPicturesColumns;
