import React from 'react';
import PropTypes from 'prop-types';

import { OfficeItem } from './OfficeItem';

const Offices = ({
  dataItem: {
    field_title,
    relationships: { field_office },
    internal: { type },
  },
}) => (
  <div className={type}>
    <h2>{field_title}</h2>
    <div className="offices-wrapper">
      {field_office && field_office.map((item) => <OfficeItem data={item} key={item.id} />)}
    </div>
  </div>
);

Offices.propTypes = {
  dataItem: PropTypes.object.isRequired,
};

export default Offices;
