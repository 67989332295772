import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import CN from 'classnames';

import { sendData } from 'api';
import { validate, generateCaptcha, trackFormSuccess } from 'utils';
import { FAILED_FORM_MESSAGE } from 'globals';

import { FormPopUp } from 'components';
import { TextField } from '../components';

const initialValues = {
  name: '',
  email: '',
  company: '',
  captcha: '',
};

const EventForm = ({ formId, title }) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { name, email, company, captcha } = values;

  const { x, y, total } = useMemo(() => generateCaptcha(), []);

  const handlePopupClose = () => setPopupMessage('');

  const onChange = ({ target: { value: inputValue, name: inputName } }) =>
    setValues((prevState) => ({
      ...prevState,
      [inputName]: inputValue,
    }));

  const onBlur = ({ target: { value: inputValue, name: inputName } }) =>
    setErrors((prevState) => ({
      ...prevState,
      [inputName]: validate(inputValue, inputName, total),
    }));

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({
      name: validate(name, 'name'),
      email: validate(email, 'email'),
      company: validate(company, 'company'),
      captcha: validate(captcha, 'captcha', total),
    });

    const isValid = Object.keys(errors)
      .map((type) => validate(values[type], type, total))
      .every((error) => error === '');

    if (!isValid) {
      setLoading(false);
      return;
    }

    const emailData = {
      webform_id: formId,
      email,
      name,
      company,
    };

    sendData(emailData)
      .then(() => {
        trackFormSuccess(formId);
        setValues(initialValues);
        navigate('/thank-you/');
      })
      .catch(() => {
        setPopupMessage(FAILED_FORM_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={onSubmit} className={CN('anyforsoft-form', formId)}>
        {title && <h2>{title}</h2>}
        <div className="contact-fields">
          <TextField
            value={name}
            name="name"
            label="Full name"
            error={errors.name}
            onChange={onChange}
            onBlur={onBlur}
          />
          <TextField
            value={email}
            name="email"
            label="Business email"
            error={errors.email}
            onChange={onChange}
            onBlur={onBlur}
          />
          <TextField
            value={company}
            name="company"
            label="Company name"
            error={errors.company}
            onChange={onChange}
            onBlur={onBlur}
          />
          <div className="form-item form-item-captcha-submit">
            <div>
              <TextField
                value={captcha}
                name="captcha"
                label={`${x} + ${y}?`}
                error={errors.captcha}
                onChange={onChange}
                onBlur={onBlur}
              />
            </div>
          </div>
          <button className="btn hire" type="submit" value="Send" disabled={isLoading}>
            Submit
          </button>
        </div>
      </form>
      {popupMessage && <FormPopUp message={popupMessage} handlePopupClose={handlePopupClose} />}
    </>
  );
};

EventForm.propTypes = {
  formId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default EventForm;
