import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { ImgOrSvg, Link } from 'components';

const IndustryItem = ({
  data: {
    title,
    field_description,
    field_discover_more_link,
    field_image: { alt },
    relationships: { field_image },
  },
  type,
  isActive,
  toggleActiveAcc,
  toggleActiveSlide,
  index,
}) => {
  if (type === 'tabs') {
    return (
      <div className={CN('industry-tab-title', isActive ? 'active' : '')}>
        <button type="button" onClick={toggleActiveSlide(index)}>
          {field_image && (
            <ImgOrSvg itemProp="image" image={field_image} className="industry-image" alt={alt} />
          )}

          <div className="title-box">
            <h3 itemProp="name" className="title">
              {title}
            </h3>
          </div>
        </button>
      </div>
    );
  }

  return (
    <div
      itemScope
      itemType="https://schema.org/Service"
      className={CN('industry-item', isActive ? 'active' : '')}
    >
      {type === 'accordion' && (
        <div className="industry-title">
          <button
            type="button"
            className="industry-accordion-button"
            onClick={toggleActiveAcc(index)}
          >
            {field_image && (
              <ImgOrSvg itemProp="image" image={field_image} className="industry-image" alt={alt} />
            )}
            <h3 itemProp="name" className="title">
              {title}
            </h3>
          </button>
        </div>
      )}
      <div className="industry-content">
        <div itemProp="description" className="industry-description">
          {field_description?.processed && (
            <div className="description-text">{parser(sanitize(field_description.processed))}</div>
          )}
          {field_discover_more_link?.uri && (
            <Link to={field_discover_more_link.uri} className="btn--arrow">
              {field_discover_more_link?.title || 'discover more'}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

IndustryItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    field_description: PropTypes.object,
    field_discover_more_link: PropTypes.object,
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }).isRequired,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  toggleActiveAcc: PropTypes.func,
  toggleActiveSlide: PropTypes.func,
  index: PropTypes.number,
};

export default IndustryItem;
