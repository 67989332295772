import { useState, useEffect } from 'react';

import { getWindowSize } from 'utils';

const debounce = (fn, ms) => {
  let timeout;

  // eslint-disable-next-line func-names
  return function() {
    // eslint-disable-next-line func-names
    const later = function() {
      timeout = null;
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, ms);
  };
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const resizeHandler = debounce(
      () =>
        typeof window !== 'undefined' &&
        setWindowSize((prevState) => ({
          ...prevState,
          width: window.innerWidth,
          height: window.innerHeight,
        })),
      250,
    );

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return windowSize;
};

export default useWindowSize;
