import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';

import { useForceUpdate } from 'hooks';
import { getCategoryPath } from 'utils';

import { ImgOrSvg, Link } from 'components';
import { Arrow } from 'icons';

const FirstBlogCard = ({
  title,
  blogHeaderImage: { image, alt },
  tags,
  readingTime,
  summary,
  path,
}) => {
  // Used as force component update (related with TextTruncate initial render bug)
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <div className="first-blog-card">
      <Link to={`${path}/`} className="first-blog-link" itemProp="url" />

      <div className="first-blog-card__media">
        <ImgOrSvg
          image={image}
          itemProp="image"
          alt={alt}
          className="blog-media-card-src"
          objectFit="cover"
        />
      </div>

      <div className="first-blog-card__information">
        <div className="information-header">
          <div className="blog-category" itemProp="alternateName">
            {tags.map((tag) => (
              <Link
                key={tag.drupal_internal__tid}
                to={`/blog/category/${getCategoryPath(tag.name)}/`}
              >
                <span>{tag.name}</span>
              </Link>
            ))}
          </div>

          <span itemProp="identifier" className="blog-reading-times">
            {readingTime || ''}
          </span>
        </div>

        <h2 className="blog-title" itemProp="name">
          {title}
        </h2>

        <div className="blog-description">
          <TextTruncate
            line={4}
            element="span"
            truncateText="…"
            text={summary.replace(/(<([^>]+)>)/gi, '')}
          />
        </div>

        <span className="blog-read-more">
          Read more
          <Arrow />
        </span>
      </div>
    </div>
  );
};

FirstBlogCard.propTypes = {
  title: PropTypes.string,
  blogHeaderImage: PropTypes.shape({
    image: PropTypes.object,
    alt: PropTypes.string,
  }),
  summary: PropTypes.string,
  path: PropTypes.string,
  tags: PropTypes.array,
  readingTime: PropTypes.string,
};

export default FirstBlogCard;
