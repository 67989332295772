/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { SITE_DOMAIN } from 'globals';
import { getUniqueMetaByContentProp } from 'utils';

const getActualMetaTitle = (metaTitle) => {
  if (metaTitle.includes('[site:name]')) {
    return metaTitle.replace('[site:name]', 'AnyforSoft');
  }

  if (!metaTitle.includes('AnyforSoft')) {
    return `${metaTitle} | AnyforSoft`;
  }

  return metaTitle;
};

const SEO = ({ description, lang, title, keywords, robots, currentUrl, imageUrl }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const pageUrl = currentUrl && SITE_DOMAIN + currentUrl.replace('*', '');
  const imgUrl = imageUrl ? SITE_DOMAIN + imageUrl : `${SITE_DOMAIN}/anyforsoft_social.jpg`;
  const keys = keywords || 'Drupal 7, Drupal 8, Drupal 9, Anyforsoft';
  const metaDescription =
    (description && description.slice(0, 320)) || site.siteMetadata?.description.slice(0, 320);
  const metaTitle = title || site.siteMetadata.title;

  const actualMetaTitle = getActualMetaTitle(metaTitle);

  const metaValues = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      // @TODO Do we really need exactly the 'news_keywords' attribute?
      name: 'news_keywords',
      content: keys,
    },
    {
      name: 'referrer',
      content: 'no-referrer',
    },
    {
      property: 'og:url',
      content: pageUrl,
    },
    {
      property: 'og:title',
      content: actualMetaTitle,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:creator',
      content: site.siteMetadata.author,
    },
    {
      name: 'twitter:title',
      content: actualMetaTitle,
    },
    {
      name: 'twitter:url',
      content: pageUrl,
    },
    {
      name: 'twitter:image',
      content: imgUrl,
    },
    {
      property: 'og:site_name',
      content: 'AnyforSoft',
    },
    {
      name: 'robots',
      content: robots || 'index, follow',
    },
    {
      property: 'og:image',
      content: imgUrl,
    },
    {
      property: 'og:image:url',
      content: imgUrl,
    },
    {
      property: 'og:image:secure_url',
      content: imgUrl,
    },
    {
      property: 'og:image:width',
      content: '1200',
    },
    {
      property: 'og:image:height',
      content: '675',
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ];

  const meta = getUniqueMetaByContentProp(metaValues);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={[{ rel: 'shortcut icon', href: `${SITE_DOMAIN}/favicon.png` }]}
      meta={meta}
    />
  );
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  currentUrl: PropTypes.string,
  imageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  keywords: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  robots: PropTypes.string,
};

SEO.defaultProps = {
  lang: 'en',
  description: '',
  currentUrl: null,
  imageUrl: null,
};

export default memo(SEO);
