import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

const TextArea = ({ value, name, label, error, onChange, onBlur }) => (
  <div
    className={CN('form-item item-type-textarea', {
      error,
    })}
  >
    <textarea value={value} name={name} onChange={onChange} onBlur={onBlur} />
    {error && <div className="error-message">{error}</div>}
    <label className={CN({ fixed: value }, 'text-area-label')}>{label}</label>
    <div className="on-focus" />
  </div>
);

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default TextArea;
