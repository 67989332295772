import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { TextGridItem } from './TextGridItem';

const TextGrid = ({
  dataItem: {
    field_title,
    field_remove_margin,
    internal: { type },
    relationships: { field_paragraph },
  },
}) => (
  <div
    className={CN(type, {
      'remove-mt': field_remove_margin === 'top',
      'remove-mb': field_remove_margin === 'bottom',
      'remove-my': field_remove_margin === 'both',
    })}
  >
    <span className="blue-bg__inner" />
    {field_title && <h2 className="text-grid-title">{field_title}</h2>}

    <div className="text-grid-list">
      {field_paragraph &&
        field_paragraph.map((item) => <TextGridItem key={item.drupal_id} data={item} />)}
    </div>
  </div>
);

TextGrid.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_remove_margin: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_paragraph: PropTypes.array,
    }),
  }),
};

export default TextGrid;
