import React from 'react';

const CallUs = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path
        d="M20.8921 10.3033C21.0185 9.4554 20.977 8.58735 20.7677 7.7527C20.4913 6.6501 19.9219 5.6059 19.0597 4.74365C18.1974 3.88141 17.1532 3.31205 16.0506 3.03558C15.216 2.82629 14.3479 2.78484 13.5 2.91123"
        strokeOpacity="0.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0953 9.90173C17.2307 8.99343 16.9488 8.03493 16.2496 7.33573C15.5503 6.63653 14.5918 6.35458 13.6836 6.48998"
        strokeOpacity="0.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.1893 4.39722C7.5526 4.39722 7.8873 4.59423 8.0636 4.91185L9.28686 7.11525C9.44701 7.40375 9.4545 7.7527 9.30695 8.04785L8.12855 10.4047C8.12855 10.4047 8.47006 12.1604 9.89926 13.5896C11.3285 15.0188 13.0783 15.3544 13.0783 15.3544L15.4348 14.1762C15.7301 14.0285 16.0793 14.0362 16.3679 14.1966L18.5776 15.4251C18.8949 15.6015 19.0917 15.9361 19.0917 16.2991V18.8359C19.0917 20.1277 17.8917 21.0608 16.6677 20.6477C14.1537 19.7995 10.2514 18.1844 7.77796 15.7109C5.30451 13.2375 3.68941 9.33515 2.84114 6.8212C2.42813 5.59715 3.36117 4.39722 4.653 4.39722H7.1893Z"
        strokeOpacity="0.75"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CallUs;
