const getClearUrl = (urlWithComInternal) => {
  const arrayFromString = urlWithComInternal.toLowerCase().split(':');

  if (arrayFromString.length === 2) {
    return arrayFromString[1];
  }
  return arrayFromString[0];
};

export default getClearUrl;
