import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { AchievementItem } from './AchievementItem';

const Achievements = ({
  dataItem: {
    field_without_title,
    internal: { type },
    field_title,
    relationships: { field_achievements },
  },
}) => (
  <div
    className={CN(type, {
      'without-title': field_without_title,
    })}
  >
    <h2>{field_title}</h2>
    <div className="achievements-wrapper">
      {field_achievements &&
        field_achievements.map((item) => <AchievementItem data={item} key={item.id} />)}
    </div>
  </div>
);

Achievements.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_without_title: PropTypes.bool,
    internal: PropTypes.shape({ type: PropTypes.string }),
    relationships: PropTypes.shape({ field_achievements: PropTypes.arrayOf(PropTypes.object) }),
  }),
};

export default Achievements;
