import React from 'react';
import PropTypes from 'prop-types';

import { Link, ImgOrSvg } from 'components';

const StoriesTwoCol = ({
  item: {
    title,
    field_sub_title,
    path: { alias },
    field_image: { alt },
    relationships: { field_image: image },
  },
}) => (
  <>
    <div className="slider-image">
      <ImgOrSvg
        image={image}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={alt || title || 'Portfolio work'}
      />
    </div>
    <div className="slider-info-wrapper">
      <div className="slider-info">
        {title && <h3 className="story-title">{title}</h3>}
        {field_sub_title && <div className="story-sub-title">{field_sub_title}</div>}
      </div>
      <div className="discover-more-wrapper">
        {alias && (
          <div className="story-link">
            <Link to={`${alias}/`} className="btn--arrow">
              Discover more
            </Link>
          </div>
        )}
      </div>
    </div>
  </>
);

StoriesTwoCol.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    field_sub_title: PropTypes.string,
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }).isRequired,
};

export default StoriesTwoCol;
