import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const Challenge = ({
  dataItem: {
    internal: { type },
    field_title,
    field_subtitle,
    field_description: { processed: description },
    field_image_left,
    field_image_right,
    relationships: { field_image_left: imageLeft, field_image_right: imageRight },
  },
}) => (
  <div className={type}>
    <div className="challenge-info">
      <h2 className="challenge-title">{field_title}</h2>
      <span className="challenge-description-subtitle">{field_subtitle}</span>
      <div className="challenge-description-text">{parser(sanitize(description))}</div>
    </div>
    {imageLeft && (
      <div className="challenge-images">
        <ImgOrSvg className="field-image-left" image={imageLeft} alt={field_image_left.alt} />
        {imageRight && (
          <ImgOrSvg className="field-image-right" image={imageRight} alt={field_image_right.alt} />
        )}
      </div>
    )}
  </div>
);

Challenge.propTypes = {
  dataItem: PropTypes.shape({
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    field_description: PropTypes.shape({
      processed: PropTypes.string,
    }),
    field_image_left: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image_right: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image_left: PropTypes.object,
      field_image_right: PropTypes.object,
    }),
  }),
};

export default Challenge;
