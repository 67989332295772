import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { ImgOrSvg } from 'components';
import { parser, sanitize } from 'utils/index';

const Feedback = ({
  dataItem: {
    field_client_feedback_text,
    field_client_full_name,
    field_client_full_position,
    field_title,
    internal: { type },
    relationships: { field_client_main_image: image },
    field_grey_background,
    field_grey_background_full,
    field_client_main_image,
  },
}) => (
  <div className={type}>
    {field_title && <h2>{field_title}</h2>}
    <div
      className={CN(
        'paragraph-feedback-wrapper',
        field_grey_background_full && 'grey-background-full',
      )}
    >
      {field_grey_background && <span className="grey-bg__inner" />}
      <div className="feedback--inner">
        <div className="left-side">
          <div className="paragraph-feedback-body">
            {field_client_feedback_text && (
              <div className="description">
                <div>{parser(sanitize(field_client_feedback_text.processed))}</div>
              </div>
            )}
          </div>
          <div className="left-side-bottom">
            {field_client_full_name && (
              <div className="title">
                <div>
                  <span>{field_client_full_name}</span>
                </div>
              </div>
            )}
            {field_client_full_position && (
              <div className="position">
                <span>{field_client_full_position}</span>
              </div>
            )}
          </div>
        </div>
        <div className="image-side">
          {image && <ImgOrSvg image={image} alt={field_client_main_image?.alt || 'Client Photo'} />}
        </div>
      </div>
    </div>
  </div>
);

Feedback.propTypes = {
  dataItem: PropTypes.shape({
    field_client_feedback_text: PropTypes.object,
    field_client_full_name: PropTypes.string,
    field_client_full_position: PropTypes.string,
    field_title: PropTypes.string,
    field_grey_background: PropTypes.bool,
    field_grey_background_full: PropTypes.bool,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_client_main_image: PropTypes.object,
    }),
    field_client_main_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
  }).isRequired,
};

export default Feedback;
