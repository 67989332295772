import React from 'react';
import PropTypes from 'prop-types';
import Obfuscate from 'react-obfuscate';

import { parser, sanitize } from 'utils';

const ContactInfo = ({
  dataItem: {
    type,
    field_address,
    field_phone,
    field_e_mail,
    field_working_hours,
    field_working_hours: { processed },
  },
}) => (
  <div id={type} className={type}>
    {field_address && (
      <div className="contact-item">
        <div className="contact-label">Address</div>
        <div className="contact-value address">{parser(sanitize(field_address))}</div>
      </div>
    )}

    {field_phone && (
      <div className="contact-item">
        <div className="contact-label">Phone</div>
        <div className="contact-value phone">
          <Obfuscate tel={field_phone} linkText=" ">
            {field_phone}
          </Obfuscate>
        </div>
      </div>
    )}

    {field_e_mail && (
      <div className="contact-item">
        <div className="contact-label">E-mail</div>
        <div className="contact-value email">
          <Obfuscate email={field_e_mail} linkText=" " />
        </div>
      </div>
    )}

    {field_working_hours?.processed && (
      <div className="contact-item">
        <div className="contact-label">Working Hours</div>
        <div className="contact-value working_hours">{parser(sanitize(processed))}</div>}
      </div>
    )}
  </div>
);

ContactInfo.propTypes = {
  dataItem: PropTypes.shape({
    type: PropTypes.string,
    field_address: PropTypes.string,
    field_phone: PropTypes.string,
    field_e_mail: PropTypes.string,
    field_working_hours: PropTypes.object,
  }).isRequired,
};

export default ContactInfo;
