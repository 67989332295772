import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

const MetricItem = ({ data: { field_description }, itemCount }) => (
  <div className={CN('metrics-item', itemCount === 0 && 'single')}>
    {parser(sanitize(field_description.processed))}
  </div>
);

MetricItem.propTypes = {
  data: PropTypes.shape({
    field_description: PropTypes.object,
  }).isRequired,
  itemCount: PropTypes.number.isRequired,
};

export default MetricItem;
