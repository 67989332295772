import React from 'react';

import { Arrow } from 'icons';
import { Link } from 'components';

const RecommendedButton = () => (
  <Link to="/blog/" className="recommended-button" target="_self">
    <div className="btn-text">View blog</div>
    <div className="see-more-icon">
      <Arrow color="#FFFF" />
    </div>
  </Link>
);

export default RecommendedButton;
