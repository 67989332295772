import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { Link } from 'components';
import { Arrow } from 'icons';

const HeadingWithLink = ({
  dataItem: {
    field_remove_margin,
    field_bigger_title,
    field_title,
    field_discover_more,
    field_description,
  },
}) => (
  <div
    className={CN(
      'heading-with-link_wrapper',
      `remove-${field_remove_margin || 'none'}`,
      field_bigger_title ? 'bigger-title' : 'test',
      field_description ? 'with-description' : '',
    )}
  >
    <div className="heading-with-link">
      <div className="heading-with-description">
        <h2>{parser(sanitize(field_title))}</h2>

        {field_description && (
          <div className="description">{parser(sanitize(field_description.processed))}</div>
        )}
      </div>

      <div className="wrp-button">
        <Link to={field_discover_more.uri} className="recommended-button">
          <div className="btn-text">{field_discover_more.title}</div>
          <div className="see-more-icon">
            <Arrow color="#FFFF" />
          </div>
        </Link>
      </div>
    </div>
  </div>
);

HeadingWithLink.propTypes = {
  dataItem: PropTypes.shape({
    field_remove_margin: PropTypes.string,
    field_bigger_title: PropTypes.bool,
    field_title: PropTypes.string,
    field_discover_more: PropTypes.object,
    field_description: PropTypes.object,
  }).isRequired,
};

export default HeadingWithLink;
