const allowedExtensions = ['png', 'jpeg', 'jpg', 'txt', 'doc', 'docx', 'pdf', 'xls', 'xlsx'];

const validate = (value, type, total) => {
  let errors = '';
  switch (type) {
    case 'name':
      errors = value.trim().length < 3 ? 'At least 3 characters are required' : '';
      break;
    case 'company':
      errors = value.trim().length < 4 ? 'Company name must be a minimum of 4 characters long' : '';
      break;
    case 'email':
      const validEmailRegex = RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      );
      errors = validEmailRegex.test(value) ? '' : 'Email is invalid';
      if (value.trim().length === 0) {
        errors = 'At least 3 characters are required';
      }
      break;
    case 'message':
      const validMessageRegex = RegExp(/([<(|)*>])+/gi);
      errors = validMessageRegex.test(value)
        ? 'Please, do not use special characters in your message'
        : '';
      break;
    case 'privacyPolicy':
      errors = value !== true ? 'Privacy policy field is required' : '';
      break;
    case 'file':
      if (value) {
        const found = allowedExtensions.includes(value.name.split('.').pop());
        if (value.size > 50000000) {
          errors = 'Maximum upload file size is 50MB. ';
        }
        errors = found ? '' : `Allowed extension here: ${allowedExtensions.join(', ')}`;
      }
      break;

    case 'resume':
      if (value) {
        const found = allowedExtensions.includes(value.name.split('.').pop());
        if (value.size > 32000000) {
          errors = 'Maximum upload file size is 32MB. ';
        }
        errors = found
          ? ''
          : `File extension is not allowed! Allowed extension here: ${allowedExtensions.join(
              ', ',
            )}`;
      } else {
        errors = 'Resume field is required';
      }
      break;

    case 'captcha':
      errors = !value.trim().length ? 'Please fill the answer' : '';
      if (value.trim().length > 0 && +value !== total) {
        errors = 'Wrong answer, try one more time';
      }
      break;
    default:
      break;
  }

  return errors;
};

export default validate;
