import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ color }) => (
  <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.19572 7.91276L7.73598 7.92092L7.73598 2.82408L1.33435 9.22571L0.27421 8.16557L6.67584 1.76394L1.58308 1.76801L1.58716 0.304201L9.19572 0.304201L9.19572 7.91276Z"
      fill={color}
    />
  </svg>
);

Arrow.propTypes = {
  color: PropTypes.string,
};

export default Arrow;
