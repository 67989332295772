import React from 'react';
import PropTypes from 'prop-types';

import { StoryItem } from './StoryItem';
import { PromotionBlock } from './PromotionBlock';

const StoriesList = ({
  dataItem: {
    internal: { type },
    relationships: { field_reference: stories },
  },
}) => (
  <div className={type}>
    <div id={type} className="anchor-for-menu" />
    <div className="stories-list">
      {stories.map((item) => (
        <StoryItem key={item.id} data={item} />
      ))}
      <PromotionBlock />
    </div>
  </div>
);

StoriesList.propTypes = {
  dataItem: PropTypes.shape({
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_reference: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          path: PropTypes.shape({
            alias: PropTypes.string,
          }),
          title: PropTypes.string,
          field_sub_title: PropTypes.string,
          field_image_header: PropTypes.shape({
            alt: PropTypes.string,
          }),
          field_image: PropTypes.shape({
            alt: PropTypes.string,
          }),
          relationships: PropTypes.shape({
            field_image_header: PropTypes.object,
            field_image: PropTypes.object,
          }),
        }),
      ),
    }),
  }).isRequired,
};

export default StoriesList;
