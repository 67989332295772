import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { Link, ImgOrSvg } from 'components';

const StoriesOneCol = ({
  item: {
    title,
    field_description: { processed },
    path: { alias },
    field_image: { alt },
    relationships: { field_image: image },
  },
}) => (
  <>
    <div className="slider-info">
      {title && <h3 className="story-title">{title}</h3>}
      {processed && <div className="story-description">{parser(sanitize(processed))}</div>}
      {alias && (
        <div className="story-link">
          <Link to={`${alias}/`} className="btn--arrow">
            Discover more
          </Link>
        </div>
      )}
    </div>
    <div className="slider-image">
      <ImgOrSvg
        image={image}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={alt || title || 'Portfolio work'}
      />
    </div>
  </>
);

StoriesOneCol.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    field_description: PropTypes.shape({
      processed: PropTypes.string,
    }),
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }).isRequired,
};

export default StoriesOneCol;
