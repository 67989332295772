import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const TitleWithDescriptionAndCenterImage = ({
  dataItem: {
    field_title,
    field_background,
    field_description,
    field_image_or_json,
    field_disable_json_autoloop,
    field_image,
    relationships: { field_image: image, field_json_lottie },
  },
}) => {
  const disabledLottieParagraph = useRef(null);

  return (
    <>
      <div className="paragraph__title_with_description_and_image-text">
        <div className="paragraph__title_with_description_and_image-text_inner item-title">
          {field_title && <h2>{field_title}</h2>}
        </div>
      </div>

      <div className="image-and-content" ref={disabledLottieParagraph}>
        <div className="paragraph__title_with_description_and_image-image">
          <ImgOrSvg
            image={field_image_or_json === 'lottie_json' ? field_json_lottie : image}
            alt={field_image?.alt || 'Image'}
            field_disable_json_autoloop={field_disable_json_autoloop}
            disabledLottieParagraph={disabledLottieParagraph}
          />
        </div>

        <div
          className={CN('paragraph__title_with_description_and_image-text', 'item-content', {
            grey: field_background === 'grey',
          })}
        >
          <div className="paragraph__title_with_description_and_image-text_inner">
            {field_description && (
              <div className="description">{parser(sanitize(field_description.value))}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

TitleWithDescriptionAndCenterImage.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_background: PropTypes.string,
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_description: PropTypes.object,
    field_image_or_json: PropTypes.string,
    field_disable_json_autoloop: PropTypes.bool,
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_json_lottie: PropTypes.object,
    }),
  }),
};

export default TitleWithDescriptionAndCenterImage;
