import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ color }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.33 12.9223C17.7011 12.9223 18.002 12.6088 18.002 12.2221V4.79973C18.002 3.25532 16.7962 1.99884 15.3141 1.99884H14.4405V1.29861C14.4405 0.911879 14.1397 0.598389 13.7686 0.598389C13.3974 0.598389 13.0966 0.911879 13.0966 1.29861V1.99884H10.0391V1.29861C10.0391 0.911879 9.73827 0.598389 9.36714 0.598389C8.99601 0.598389 8.69517 0.911879 8.69517 1.29861V1.99884H5.6713V1.29861C5.6713 0.911879 5.37045 0.598389 4.99932 0.598389C4.62819 0.598389 4.32735 0.911879 4.32735 1.29861V1.99884H3.48739C2.00529 1.99884 0.7995 3.25532 0.7995 4.79973V15.7232C0.7995 17.2676 2.00529 18.5241 3.48739 18.5241H15.3141C16.7962 18.5241 18.002 17.2676 18.002 15.7232C18.002 15.3365 17.7011 15.023 17.33 15.023C16.9589 15.023 16.658 15.3365 16.658 15.7232C16.658 16.4954 16.0551 17.1237 15.3141 17.1237H3.48739C2.74634 17.1237 2.14344 16.4954 2.14344 15.7232V4.79973C2.14344 4.02753 2.74634 3.39928 3.48739 3.39928H4.32735V4.09951C4.32735 4.48624 4.62819 4.79973 4.99932 4.79973C5.37045 4.79973 5.6713 4.48624 5.6713 4.09951V3.39928H8.69517V4.09951C8.69517 4.48624 8.99601 4.79973 9.36714 4.79973C9.73827 4.79973 10.0391 4.48624 10.0391 4.09951V3.39928H13.0966V4.09951C13.0966 4.48624 13.3974 4.79973 13.7686 4.79973C14.1397 4.79973 14.4405 4.48624 14.4405 4.09951V3.39928H15.3141C16.0551 3.39928 16.658 4.02753 16.658 4.79973V12.2221C16.658 12.6088 16.9589 12.9223 17.33 12.9223Z"
      fill="currentColor"
    />
    <path
      d="M4.99705 8.65056C5.36827 8.65056 5.6692 8.33711 5.6692 7.95046C5.6692 7.56381 5.36827 7.25037 4.99705 7.25037C4.62582 7.25037 4.32489 7.56381 4.32489 7.95046C4.32489 8.33711 4.62582 8.65056 4.99705 8.65056Z"
      fill="currentColor"
    />
    <path
      d="M10.8232 8.65068C11.1944 8.65068 11.4954 8.33723 11.4954 7.95058C11.4954 7.56393 11.1944 7.25049 10.8232 7.25049C10.452 7.25049 10.1511 7.56393 10.1511 7.95058C10.1511 8.33723 10.452 8.65068 10.8232 8.65068Z"
      fill="currentColor"
    />
    <path
      d="M13.7646 8.65031C14.1358 8.65031 14.4368 8.33687 14.4368 7.95022C14.4368 7.56357 14.1358 7.25012 13.7646 7.25012C13.3934 7.25012 13.0925 7.56357 13.0925 7.95022C13.0925 8.33687 13.3934 8.65031 13.7646 8.65031Z"
      fill="currentColor"
    />
    <path
      d="M4.99705 11.6962C5.36827 11.6962 5.6692 11.3828 5.6692 10.9961C5.6692 10.6095 5.36827 10.296 4.99705 10.296C4.62582 10.296 4.32489 10.6095 4.32489 10.9961C4.32489 11.3828 4.62582 11.6962 4.99705 11.6962Z"
      fill="currentColor"
    />
    <path
      d="M4.99705 14.7417C5.36827 14.7417 5.6692 14.4283 5.6692 14.0416C5.6692 13.655 5.36827 13.3416 4.99705 13.3416C4.62582 13.3416 4.32489 13.655 4.32489 14.0416C4.32489 14.4283 4.62582 14.7417 4.99705 14.7417Z"
      fill="currentColor"
    />
    <path
      d="M7.90916 8.65043C8.28038 8.65043 8.58131 8.33699 8.58131 7.95034C8.58131 7.56369 8.28038 7.25024 7.90916 7.25024C7.53793 7.25024 7.237 7.56369 7.237 7.95034C7.237 8.33699 7.53793 8.65043 7.90916 8.65043Z"
      fill="currentColor"
    />
    <path
      d="M7.90916 11.6958C8.28038 11.6958 8.58131 11.3824 8.58131 10.9957C8.58131 10.6091 8.28038 10.2957 7.90916 10.2957C7.53793 10.2957 7.237 10.6091 7.237 10.9957C7.237 11.3824 7.53793 11.6958 7.90916 11.6958Z"
      fill="currentColor"
    />
    <path
      d="M7.90916 14.7416C8.28038 14.7416 8.58131 14.4282 8.58131 14.0415C8.58131 13.6549 8.28038 13.3414 7.90916 13.3414C7.53793 13.3414 7.237 13.6549 7.237 14.0415C7.237 14.4282 7.53793 14.7416 7.90916 14.7416Z"
      fill="currentColor"
    />
    <path
      d="M10.8232 11.6963C11.1944 11.6963 11.4954 11.3829 11.4954 10.9962C11.4954 10.6096 11.1944 10.2961 10.8232 10.2961C10.452 10.2961 10.1511 10.6096 10.1511 10.9962C10.1511 11.3829 10.452 11.6963 10.8232 11.6963Z"
      fill="currentColor"
    />
    <path
      d="M13.7646 11.6961C14.1358 11.6961 14.4368 11.3826 14.4368 10.996C14.4368 10.6093 14.1358 10.2959 13.7646 10.2959C13.3934 10.2959 13.0925 10.6093 13.0925 10.996C13.0925 11.3826 13.3934 11.6961 13.7646 11.6961Z"
      fill="currentColor"
    />
    <path
      d="M10.8232 14.7417C11.1944 14.7417 11.4954 14.4282 11.4954 14.0416C11.4954 13.6549 11.1944 13.3415 10.8232 13.3415C10.452 13.3415 10.1511 13.6549 10.1511 14.0416C10.1511 14.4282 10.452 14.7417 10.8232 14.7417Z"
      fill="currentColor"
    />
  </svg>
);

CalendarIcon.propTypes = {
  color: PropTypes.string,
};

export default CalendarIcon;
