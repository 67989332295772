import React from 'react';
import PropTypes from 'prop-types';

import { BlogCard, RecommendedButton } from 'components';

const Recommended = ({ recommended }) =>
  recommended?.relationships.field_reference ? (
    <div className="recommended without-wave">
      {recommended?.field_title && (
        <h2 className="recommended-articles-title">{recommended.field_title}</h2>
      )}

      <div className="block-recommended-content">
        <div className="content-box">
          {recommended.relationships.field_reference.map(
            ({ path, relationships, field_reading_time, title, field_image, drupal_id }) => (
              <BlogCard
                path={path.alias}
                category={relationships.field_blog_category}
                readingTime={field_reading_time}
                title={title}
                image={relationships.image}
                alt={field_image.alt}
                key={drupal_id}
              />
            ),
          )}
        </div>
      </div>

      <RecommendedButton />
    </div>
  ) : (
    <></>
  );

Recommended.propTypes = {
  recommended: PropTypes.object,
};

export default Recommended;
