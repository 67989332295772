import React from 'react';
import PropTypes from 'prop-types';

import { sanitize, parser } from 'utils';

import { PopUpWrapper } from './components';

const FormPopUp = ({ message, handlePopupClose }) => {
  const onClose = () => {
    handlePopupClose();
  };

  return (
    <PopUpWrapper isOpened>
      <div className="form-popup">
        <div className="form-popup-message">{parser(sanitize(message))}</div>
        <button className="btn" type="button" onClick={onClose}>
          Close
        </button>
      </div>
    </PopUpWrapper>
  );
};

FormPopUp.propTypes = {
  message: PropTypes.string.isRequired,
  handlePopupClose: PropTypes.func.isRequired,
};

export default FormPopUp;
