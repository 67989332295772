import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { FAQItem } from './components';

const FAQ = ({
  dataItem: {
    internal: { type },
    field_title,
    field_grey_background,
    relationships: { field_faq_items },
  },
}) => {
  const [activeAcc, setActiveAcc] = useState([]);

  const toggleActiveAcc = (index) => () =>
    setActiveAcc((prevState) =>
      prevState.includes(index) ? prevState.filter((idx) => idx !== index) : [...prevState, index],
    );

  return (
    <div
      className={CN(type, {
        grey: field_grey_background,
      })}
      itemScope
      itemType="https://schema.org/FAQPage"
    >
      {field_title && <h2 className="paragraph-fqa__title">{field_title}</h2>}
      {field_faq_items && (
        <div className="accordion">
          {field_faq_items.map((item, index) => (
            <FAQItem
              key={item.id}
              data={item}
              index={index}
              active={activeAcc.includes(index)}
              toggleActiveAcc={toggleActiveAcc}
            />
          ))}
        </div>
      )}
    </div>
  );
};

FAQ.propTypes = {
  dataItem: PropTypes.shape({
    internal: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
    field_title: PropTypes.string,
    field_grey_background: PropTypes.bool,
    relationships: PropTypes.shape({
      field_faq_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          field_question: PropTypes.string.isRequired,
          field_answer: PropTypes.shape({
            processed: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ),
    }),
  }),
};

export default FAQ;
