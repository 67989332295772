import React from 'react';
import PropTypes from 'prop-types';

import { getPicturesColumns, parser, sanitize } from 'utils';

const TeamPictures = ({
  dataItem: {
    field_title,
    field_description,
    field_team_pictures: teamPicturesAlts,
    internal: { type },
    relationships: { field_team_pictures: teamPictures },
  },
}) => {
  const { bigColumn, smallColumn } = getPicturesColumns(teamPictures, teamPicturesAlts);

  return (
    <div className={type}>
      <div className="big-column">
        <div className={`${type}-title-description item`}>
          <h2 className="h2">{field_title}</h2>
          {field_description && (
            <div className="description">{parser(sanitize(field_description.processed))}</div>
          )}
        </div>

        {teamPictures &&
          bigColumn.map(({ image, id, alt }) => (
            <div key={id} className={`${type}-images item item-img`}>
              <img src={image.localFile.childImageSharp.fluid.src} alt={alt} />
            </div>
          ))}
      </div>

      <div className="small-column">
        {teamPictures &&
          smallColumn.map(({ image, id, alt }) => (
            <div key={`${id}-images`} className={`${type}-images item item-img`}>
              <img src={image.localFile.childImageSharp.fluid.src} alt={alt} />
            </div>
          ))}
      </div>
    </div>
  );
};

TeamPictures.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    field_team_pictures: PropTypes.array,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_team_pictures: PropTypes.array,
    }),
  }).isRequired,
};

export default TeamPictures;
