import React from 'react';
import PropTypes from 'prop-types';

import { ServicesList } from './ServicesList';
import { DoubleServicesSlider } from './DoubleServicesSlider';

const Services = ({ dataItem }) => (
  <div>
    <div id={dataItem.internal?.type} className="anchor-for-menu" />
    {dataItem.field_display_tabs ? (
      <DoubleServicesSlider data={dataItem} />
    ) : (
      <ServicesList dataItem={dataItem} />
    )}
  </div>
);

Services.propTypes = {
  dataItem: PropTypes.object,
};

export default Services;
