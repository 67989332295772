import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

const AchievementItem = ({ data: { field_title, field_subtitle, field_description } }) => (
  <div className="achievements-item">
    <div className="box-wrapper">
      {field_title && <span className="item-title">{field_title}</span>}
      {field_subtitle && <span className="subtitle">{field_subtitle}</span>}
      {field_description && (
        <div className="item-description">{parser(sanitize(field_description.processed))}</div>
      )}
    </div>
  </div>
);

AchievementItem.propTypes = {
  data: PropTypes.shape({
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    field_description: PropTypes.object,
  }).isRequired,
};

export default AchievementItem;
