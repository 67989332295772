import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { Form, ImgOrSvg } from 'components';
import { BeInTouch } from 'components/Paragraphs/Types';

const Forms = ({
  webforms,
  dataItem: {
    field_title,
    field_form,
    field_description,
    field_remove_margin,
    field_event_form,
    field_iframe_link,
    field_image_or_json,
    field_disable_json_autoloop,
    relationships: { field_image, field_json_lottie, field_image_background },
    internal: { type },
  },
}) => {
  const disabledLottieParagraph = useRef(null);

  if (field_form === 'touch') {
    return (
      <BeInTouch
        title={field_title}
        description={field_description || ''}
        buttonTitle="Let’s Talk"
        blueBg="blue-bg"
        margin={`remove-${field_remove_margin === 'top' ? 'top' : 'bottom'}`}
      />
    );
  }

  if (field_iframe_link) {
    return (
      <div className="paragraph-event-form" ref={disabledLottieParagraph}>
        <div id={field_form} className="anchor-for-menu">
          anchor
        </div>
        <div className={CN(type, field_form)}>
          <div className="content-side">
            <h2>{field_title}</h2>
            <div className="item block-content">
              <ImgOrSvg itemProp="image" image={field_image_background} className="bg-image" />
            </div>
          </div>
          <div className="form-side">
            <div key={field_form + Math.random()} className={`item wrapper-${field_form}`}>
              <iframe
                title="livestorm-form"
                id="livestorm-form"
                width="100%"
                height="480"
                frameBorder="0"
                src={field_iframe_link}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (field_event_form) {
    return (
      <div className="paragraph-event-form" ref={disabledLottieParagraph}>
        <span className="blue-bg__inner" />
        <div id={field_form} className="anchor-for-menu">
          anchor
        </div>
        <div className={CN(type, field_form)}>
          <div className="content-side">
            <h2>{field_title}</h2>
            <div className="item block-content">
              <ImgOrSvg
                className="image"
                image={field_image_or_json === 'lottie_json' ? field_json_lottie : field_image}
                field_disable_json_autoloop={field_disable_json_autoloop}
                disabledLottieParagraph={disabledLottieParagraph}
                alt="Contact illustration"
              />
            </div>
          </div>
          <div className="form-side">
            <div key={field_form + Math.random()} className={`item wrapper-${field_form}`}>
              <Form
                key={field_form}
                formId={field_form}
                webforms={webforms}
                inputId="privacy_policy-secondary"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="paragraph-contact-us blue-bg-left" ref={disabledLottieParagraph}>
      <div id={field_form} className="anchor-for-menu">
        anchor
      </div>

      <span className="blue-bg-left__inner" />
      <div className={CN(type, field_form)}>
        <h2>
          <span>{field_title}</span>
        </h2>

        <div className="item block-content">
          <div key={field_form + Math.random()} className={`item wrapper-${field_form} `}>
            <Form
              key={field_form}
              formId={field_form}
              webforms={webforms}
              inputId="privacy_policy-secondary"
            />
          </div>
          <ImgOrSvg
            className="image"
            image={field_image_or_json === 'lottie_json' ? field_json_lottie : field_image}
            field_disable_json_autoloop={field_disable_json_autoloop}
            disabledLottieParagraph={disabledLottieParagraph}
            alt="Contact illustration"
          />
        </div>
      </div>
    </div>
  );
};

Forms.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_form: PropTypes.string,
    field_description: PropTypes.object,
    field_remove_margin: PropTypes.string,
    field_event_form: PropTypes.bool,
    field_iframe_link: PropTypes.string,
    field_image_or_json: PropTypes.string,
    field_disable_json_autoloop: PropTypes.bool,
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_json_lottie: PropTypes.object,
      field_image_background: PropTypes.object,
    }),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
  webforms: PropTypes.object,
};

export default Forms;
