import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { AwardItem } from './AwardItem';

const Awards = ({
  dataItem: {
    field_title,
    internal: { type },
    field_title_position,
    field_column_count,
    field_description,
    relationships: { field_paragraph },
  },
}) => (
  <div
    className={CN(
      type,
      field_title_position && 'title-inside',
      field_column_count === '4' ? 'awards-4-column' : '',
    )}
  >
    <div className="top-side">
      <h2 className="h2">{field_title}</h2>
      {field_description?.processed && (
        <div className="subtitle">{parser(sanitize(field_description.processed))}</div>
      )}
    </div>
    <div className="bottom-side">
      {field_paragraph.map((item) => (
        <AwardItem data={item} key={item.id} />
      ))}
    </div>
  </div>
);

Awards.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_title_position: PropTypes.bool,
    field_column_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_description: PropTypes.shape({
      processed: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_paragraph: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    }),
  }).isRequired,
};

export default Awards;
