import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { SITE_DOMAIN, TWITTER_HANDLE } from 'globals';

import { Form, Link, ShareButtons } from 'components';

import { Arrow, EventIcon } from 'icons';
import { parser, sanitize } from 'utils/index';

const EventHeader = ({
  headerData: {
    title,
    field_event_date,
    field_event_time,
    field_title_formatted,
    field_timezone,
    field_iframe_link,
    field_meeting_link,
    alias,
  },
}) => {
  const [isActive, setActive] = useState(false);

  const url = SITE_DOMAIN + alias;
  const tagTitle = title.replace(' ', '_');
  const tags = ['AnyforSoft', tagTitle];

  const handleClick = () => {
    setActive((prevState) => !prevState);
  };

  if (field_iframe_link) {
    return (
      <div className="event-header">
        <div className="content">
          <h2>
            {field_title_formatted && (
              <span className="title-formatted">
                {parser(sanitize(field_title_formatted?.processed))}
              </span>
            )}
            {title}
          </h2>
          <div className="date-time">
            <div className="box date">
              <EventIcon type="calendar" color="rgba(9, 21, 67, 0.75)" />
              <span>{field_event_date || '01.01.1970'}</span>
            </div>
            <div className="box time">
              <EventIcon type="time" color="rgba(9, 21, 67, 0.75)" />
              <time>{field_event_time || '00:00'}</time>
              <span className="timezone">{field_timezone}</span>
            </div>
            <div className="box place">
              <EventIcon type="place" color="rgba(9, 21, 67, 0.75)" />
              <Link to={field_meeting_link.uri}>{field_meeting_link.title}</Link>
            </div>
          </div>
          <div className="share-container">
            <div className="share-button">
              <button className="recommended-button" type="button" onClick={handleClick}>
                <div className="btn-text">Share</div>
                <div className="see-more-icon">
                  <Arrow color="#FFFF" />
                </div>
              </button>
            </div>
            <div className={CN('share-social', isActive ? 'active' : '')}>
              <ShareButtons title={title} url={url} twitterHandle={TWITTER_HANDLE} tags={tags} />
            </div>
          </div>
        </div>
        <div className="webform">
          <div className="webform-box">
            <iframe
              title="livestorm"
              id="livestorm-form"
              width="100%"
              height="480"
              frameBorder="0"
              src={field_iframe_link}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="event-header">
      <div className="content">
        <h2>{title}</h2>
        <div className="date-time">
          <div className="box date">
            <EventIcon type="calendar" />
            <span>{field_event_date || '01.01.1970'}</span>
          </div>
          <div className="box time">
            <EventIcon type="time" />
            <time>{field_event_time || '00:00'}</time>
            <span className="timezone">{field_timezone}</span>
          </div>
          <div className="box place">
            <EventIcon type="place" />
            <Link to={field_meeting_link.uri}>{field_meeting_link.title}</Link>
          </div>
        </div>
        <div className="share-container">
          <div className="share-button">
            <button className="btn white" type="button" onClick={handleClick}>
              Share
            </button>
          </div>
          <div className={CN('share-social', isActive ? 'active' : '')}>
            <ShareButtons title={title} url={url} twitterHandle={TWITTER_HANDLE} tags={tags} />
          </div>
        </div>
      </div>
      <div className="webform">
        <div className="webform-box">
          <h4>Register now</h4>
          <Form key="events" formId="events" inputId="privacy_policy-secondary" />
        </div>
      </div>
    </div>
  );
};

EventHeader.propTypes = {
  headerData: PropTypes.object.isRequired,
};

export default EventHeader;
