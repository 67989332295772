import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import CN from 'classnames';

import { getGeoCountry, sendData } from 'api';
import { generateCaptcha, getInputValue, setFileData, validate, trackFormSuccess } from 'utils';
import { FAILED_FORM_MESSAGE } from 'globals';

import { FormPopUp, Link } from 'components';

import { Checkbox, FileField, TextArea, TextField } from '../components';

const initialValues = {
  name: '',
  email: '',
  message: '',
  captcha: '',
  file: '',
  privacyPolicy: false,
};

const FIELDS_ONCHANGE_VALIDATE = ['file', 'privacyPolicy', 'name', 'email'];

const BasicForm = ({
  formId,
  inputId,
  title,
  btnText,
  fileBtnText,
  withRemoveFileBtn,
  withFilePlaceholder,
}) => {
  const [values, setValues] = useState(initialValues);
  const [popupMessage, setPopupMessage] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
    captcha: '',
    file: '',
    privacyPolicy: '',
  });
  const [isLoading, setLoading] = useState(false);
  const { name, email, message, captcha, file, privacyPolicy } = values;

  const { x, y, total } = useMemo(() => generateCaptcha(), []);

  const isRecruiterForm = formId === 'recruiter_form';
  const fileName = isRecruiterForm ? 'resume' : 'file';
  const inputBtnText = fileBtnText || 'Attach files';
  const fileLabel = isRecruiterForm ? 'Attach your resume' : inputBtnText;

  const handlePopupClose = () => setPopupMessage('');

  const onChange = ({ target, target: { name: inputName } }) => {
    const value = getInputValue(target);

    setErrors((prevState) => ({
      ...prevState,
      [inputName]: validate(value, inputName, total),
    }));

    setValues((prevState) => ({
      ...prevState,
      [inputName]: value,
    }));
  };

  const onBlur = ({ target: { name: inputName }, target }) => {
    const value = getInputValue(target);

    if (FIELDS_ONCHANGE_VALIDATE.includes(inputName)) {
      return;
    }

    setErrors((prevState) => ({
      ...prevState,
      [inputName]: validate(value, inputName, total),
    }));
  };

  const sendRequest = async (data) =>
    sendData(data)
      .then(() => {
        trackFormSuccess(formId);
        setValues(initialValues);
        navigate('/thank-you/');
      })
      .catch(() => {
        setPopupMessage(FAILED_FORM_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });

  const onSubmit = async (event) => {
    event.preventDefault();

    const errorsValues = {
      name: validate(name, 'name'),
      email: validate(email, 'email'),
      message: validate(message, 'message'),
      captcha: validate(captcha, 'captcha', total),
      file: validate(file, fileName),
      privacyPolicy: validate(privacyPolicy, 'privacyPolicy'),
    };

    setLoading(true);
    setErrors(errorsValues);

    const isValid = Object.keys(errorsValues).every((type) => errorsValues[type] === '');

    if (!isValid) {
      setLoading(false);
      return;
    }

    const emailData = {
      webform_id: formId,
      email,
      name,
      message,
    };

    try {
      await getGeoCountry()
        .then((country) => {
          emailData.country = country;
        })
        .catch(() => {
          emailData.country = 'Unknown';
        });

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const fileData = setFileData(file, reader.result);
          emailData.file_data = JSON.stringify(fileData);

          sendRequest(emailData);
        };
      } else {
        await sendRequest(emailData);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className={CN('anyforsoft-form', formId)}>
        {title && <h2>{title}</h2>}
        <div className="contact-fields">
          <TextField
            value={name}
            name="name"
            label="Name"
            error={errors.name}
            onChange={onChange}
            onBlur={onBlur}
          />
          <TextField
            value={email}
            name="email"
            label={isRecruiterForm ? 'Email' : 'Business Email'}
            error={errors.email}
            onChange={onChange}
            onBlur={onBlur}
          />
          <TextArea
            value={message}
            name="message"
            label="Message or question"
            error={errors.message}
            onChange={onChange}
            onBlur={onBlur}
          />
          <FileField
            value={file}
            label={fileLabel}
            error={errors.file}
            onChange={onChange}
            onBlur={onBlur}
            withRemoveBtn={withRemoveFileBtn}
            withFilePlaceholder={withFilePlaceholder}
          />
          <Checkbox
            checked={privacyPolicy}
            name="privacyPolicy"
            id={inputId}
            error={errors.privacyPolicy}
            onChange={onChange}
            onBlur={onBlur}
          >
            <span>I have read the </span>
            <Link to="/privacy-policy/">privacy policy</Link>
          </Checkbox>

          <div className="form-item form-item-captcha-submit">
            <div>
              <TextField
                value={captcha}
                name="captcha"
                label={`${x} + ${y} = ?`}
                error={errors.captcha}
                onChange={onChange}
                onBlur={onBlur}
              />
            </div>
          </div>

          <button className="btn hire" type="submit" value="Send" disabled={isLoading}>
            {btnText || 'Send message'}
          </button>
        </div>
      </form>
      {popupMessage && <FormPopUp message={popupMessage} handlePopupClose={handlePopupClose} />}
    </>
  );
};

BasicForm.propTypes = {
  formId: PropTypes.string,
  inputId: PropTypes.string,
  btnText: PropTypes.string,
  fileBtnText: PropTypes.string,
  title: PropTypes.string,
  withRemoveFileBtn: PropTypes.bool,
  withFilePlaceholder: PropTypes.bool,
};

export default BasicForm;
