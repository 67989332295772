import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { Success } from 'icons';
import { Link } from 'components';

const ServiceItem = ({
  data: {
    field_discover_more: { title, uri },
  },
}) => (
  <div
    className={CN('item', {
      'with-link': !uri.includes('<nolink>'),
    })}
  >
    <div className="item-box">
      <Success className="success-icon" />
      <div className="content">{title}</div>
      <Link to={uri} className="item-link" />
    </div>
  </div>
);

ServiceItem.propTypes = {
  data: PropTypes.shape({
    field_discover_more: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }).isRequired,
};

export default ServiceItem;
