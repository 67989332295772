import React from 'react';
import PropTypes from 'prop-types';

import { SITE_DOMAIN } from 'globals';

const OrganizationMicrodata = ({ imageUrl }) => (
  <div itemScope itemType="https://schema.org/Organization" className="schema-snippet hidden">
    <span itemProp="name">AnyforSoft</span>
    <link itemProp="url" href="https://anyforsoft.com/" />
    <img itemProp="logo" src={SITE_DOMAIN + imageUrl} alt="AnyforSoft" />
    <link itemProp="sameAs" href="https://www.facebook.com/anyforsoft/" />
    <link itemProp="sameAs" href="https://twitter.com/anyforsoft?lang=en" />
    <link itemProp="sameAs" href="https://www.instagram.com/anyforsoft/?hl=en" />
    <link itemProp="sameAs" href="https://anyforsoft.com/" />
    <div itemProp="contactPoint" itemScope itemType="https://schema.org/ContactPoint">
      <span itemProp="telephone">+1 (941) 932-8580</span>
    </div>
  </div>
);

OrganizationMicrodata.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default OrganizationMicrodata;
