import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg, Link } from 'components';

const AwardItem = ({
  data: {
    field_image: { alt },
    field_discover_more: { uri },
    field_title,
    relationships: {
      field_image: image,
      field_image: { localFile },
    },
  },
}) =>
  localFile && (
    <div className="item">
      <Link to={uri}>
        <ImgOrSvg image={image} objectFit="cover" objectPosition="50% 50%" alt={alt} />
        <div className="item-title">{field_title}</div>
      </Link>
    </div>
  );

AwardItem.propTypes = {
  data: PropTypes.shape({
    field_title: PropTypes.string,
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_discover_more: PropTypes.shape({
      uri: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }).isRequired,
};

export default AwardItem;
