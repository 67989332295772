import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { MetricItem } from './MetricItem';

const Metrics = ({
  dataItem: {
    field_title,
    field_description,
    internal: { type },
    relationships: { field_metrics },
  },
}) => (
  <div className="metrics">
    <div className={type}>
      <div>
        <div className="paragraph__metrics-top">
          <h2>{field_title}</h2>
          {field_description && (
            <div className="description">{parser(sanitize(field_description.processed))}</div>
          )}
        </div>
      </div>

      <div>
        <div className="paragraph__metrics-bottom">
          {field_metrics &&
            field_metrics.map((item, index) => (
              <MetricItem data={item} itemCount={index} key={item.id} />
            ))}
        </div>
      </div>
    </div>
  </div>
);

Metrics.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_metrics: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default Metrics;
