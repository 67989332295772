import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import { Link } from 'components';
import { FeatureItem } from './FeatureItem';

const Features = ({
  dataItem: {
    internal: { type },
    field_title,
    field_features_type,
    field_description,
    field_discover_more,
    relationships: { field_features },
  },
}) => (
  <div className="features">
    <div className={CN(type, `list-type-${field_features_type || 'list'}`)}>
      <div className="features-top-wrapper">
        {field_title && <h2>{field_title}</h2>}
        {field_description && (
          <div className="description">
            <div className="subtitle-description">
              {parser(sanitize(field_description.processed))}
            </div>
          </div>
        )}
      </div>
      {field_features &&
        field_features.map((item, i) => <FeatureItem data={item} index={i} key={item.id} />)}

      {field_discover_more && (
        <div className="visit-btn-wrapper">
          <Link to={field_discover_more.uri} className="btn--arrow" rel="nofollow" target="_blank">
            {field_discover_more.title ? field_discover_more.title : 'Discover more'}
          </Link>
        </div>
      )}
    </div>
  </div>
);

Features.propTypes = {
  dataItem: PropTypes.shape({
    internal: PropTypes.shape({ type: PropTypes.string }),
    field_title: PropTypes.string,
    field_features_type: PropTypes.string,
    field_description: PropTypes.object,
    field_discover_more: PropTypes.object,
    relationships: PropTypes.shape({
      field_features: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default Features;
