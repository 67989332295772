// import 'scroll-behavior-polyfill';

const scrollToElement = require('scroll-to-element');

const scrollToHash = (hash, offset) => {
  // NODE-SAFE CODE
  // Gatsby uses Node to generate our pages.
  // Node doesn't know what a window is.
  // Be sure to wrap any of your browser interactions
  // in some sort of node-safe if statement like this:

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-console
    // First, are we on the home page?
    // If so, let's scroll to the desired block,
    // which was passed in as an onClick method on our <Link />.
    if (window.location.pathname === '/') {
      scrollToElement(hash, {
        offset, // Offset a fixed header if you need
        duration: 900,
      });
    }
  }
};

export default scrollToHash;
