import React from 'react';
import PropTypes from 'prop-types';

import { LottieAnimation } from 'components';
import Img from '../../../plugins/gatsby-image-new/src';

const ImgOrSvg = ({
  image,
  alt,
  loading,
  objectFit,
  objectPosition,
  field_disable_json_autoloop,
  disabledLottieParagraph,
  ...rest
}) => {
  const fileExtension = image?.localFile?.extension;

  if (!image?.localFile) return null;

  switch (fileExtension) {
    case 'svg':
      return (
        <img
          src={encodeURI(image.localFile.publicURL)}
          alt={alt}
          className="gatsby-image-svg"
          loading={loading}
          {...rest}
        />
      );

    case 'gif':
      return (
        <img
          src={encodeURI(image.localFile.publicURL)}
          alt={alt}
          className="gatsby-image-animation"
          loading={loading}
          {...rest}
        />
      );

    case 'json':
      return (
        <LottieAnimation
          animation={encodeURI(image.localFile.publicURL)}
          field_disable_json_autoloop={field_disable_json_autoloop}
          disabledLottieParagraph={disabledLottieParagraph}
        />
      );

    default:
      return (
        <Img
          fluid={image.localFile.childImageSharp.fluid}
          objectFit={objectFit}
          objectPosition={objectPosition}
          alt={alt}
          loading={loading}
          {...rest}
        />
      );
  }
};

ImgOrSvg.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  alt: PropTypes.string.isRequired,
  loading: PropTypes.string,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  field_disable_json_autoloop: PropTypes.bool,
  disabledLottieParagraph: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

ImgOrSvg.defaultProps = {
  loading: 'lazy',
};

export default ImgOrSvg;
