const trackFormSuccess = (drupalFormId) => {
  const formEventNames = {
    main_contact: 'main_formSuccess',
    recruiter_form: 'recruiter_formSuccess',
    event_form: 'event_formSuccess',
    blog_file_download: 'blog_fileDownload',
    checklist: 'checklist_formSuccess',
    contact: 'contact_formSuccess',
    contact_sales: 'contact_sales_formSuccess',
    events: 'events_formSuccess',
    subscribe: 'subscribe_formSuccess',
  };

  const eventName = formEventNames[drupalFormId];
  const isClient = typeof window !== 'undefined';

  if (eventName && isClient) {
    window.dataLayer.push({
      event: eventName,
      formName: drupalFormId,
    });
  } else {
    console.warn(`No event name mapped for form ID: ${drupalFormId}. isClient: ${isClient}`);
  }
};

export default trackFormSuccess;
