import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CN from 'classnames';

const PopUpWrapper = ({ isOpened, children }) => (
  <>
    <Helmet
      bodyAttributes={{
        id: CN({
          'popup-visible': isOpened,
        }),
      }}
    />
    <div
      className={CN('pop-up', {
        shown: isOpened,
      })}
    >
      <div className="pop-up--container">{children}</div>
    </div>
  </>
);

PopUpWrapper.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default PopUpWrapper;
