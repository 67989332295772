import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const OurTeamGridSpeaker = ({ rel }) => (
  <div className="team-grid members-list">
    {rel.length > 0 &&
      rel.map(
        (
          {
            id,
            title,
            field_position,
            field_description,
            field_image: { alt },
            relationships: { field_image },
          },
          index,
        ) => {
          const newKey = id + index;

          return (
            <div key={newKey} className="member">
              <div className="member-info">
                <div className="member-top">
                  <div className="member-name-position">
                    {title && <h3 className="name">{title}</h3>}
                    {field_position && <p className="position">{field_position}</p>}
                  </div>
                </div>

                {field_description && (
                  <div className="member-bottom">
                    <div className="description">
                      {parser(sanitize(field_description.processed))}
                    </div>
                  </div>
                )}
              </div>

              <div className="member-photo">
                {field_image?.localFile && (
                  <div className="member-list__image">
                    <ImgOrSvg image={field_image} alt={alt} objectFit="cover" />
                  </div>
                )}
              </div>
            </div>
          );
        },
      )}
  </div>
);

OurTeamGridSpeaker.propTypes = {
  rel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      field_description: PropTypes.shape({
        processed: PropTypes.string,
      }),
      field_position: PropTypes.string,
      field_image: PropTypes.shape({
        alt: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        field_image: PropTypes.object,
      }),
    }),
  ),
};

export default OurTeamGridSpeaker;
