import React from 'react';

const ScrollTopIcon = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.42826 11.3217L13.75 2M13.75 2L23.0717 11.3217M13.75 2V25.5"
      stroke="#00786E"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default ScrollTopIcon;
