import React from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick/lib/slider';
import { uid } from 'react-uid';

import { parser, sanitize } from 'utils';

import { Link } from 'components';
import { TechnologyStackItem } from './TechnologyStackItem';

const TechnologyStack = ({
  dataItem: {
    field_title,
    field_description,
    field_link,
    field_display_link,
    internal: { type },
    relationships: { field_technology_items },
  },
}) => {
  const slides = field_technology_items?.reduce(
    (rows, key, index) =>
      (index % 6 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows,
    [],
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          customPaging(i) {
            return (
              <>
                <span className="current">{`${i + 1} `}</span>/{' '}
                <span className="total">{`${slides.length}`}</span>
              </>
            );
          },
        },
      },
    ],
  };

  return (
    <div className={`without-wave blue-bg ${type}`}>
      <span className="blue-bg__inner" />
      <div id={type} className="anchor-for-menu" />
      <h2>
        <span>{field_title}</span>
      </h2>

      {field_description && (
        <div className="description">{parser(sanitize(field_description.processed))}</div>
      )}
      <div className="technology-stack-list">
        <Slider {...settings}>
          {slides &&
            slides.map((slide, index) => (
              <div key={uid(index)} className="technology-stack-slide">
                {slide.map((item) => (
                  <TechnologyStackItem key={item.name} data={item} />
                ))}
              </div>
            ))}
        </Slider>
      </div>

      {field_link?.uri && field_display_link && (
        <div className="technology-more-link">
          <Link to={field_link.uri} className="btn white">
            {field_link.title || 'Show more'}
          </Link>
        </div>
      )}
    </div>
  );
};

TechnologyStack.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    field_link: PropTypes.object,
    field_display_link: PropTypes.bool,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_technology_items: PropTypes.array,
    }),
  }).isRequired,
};

export default TechnologyStack;
