import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { Link } from 'components';
import { Message } from 'icons';
import { TeamMemberTeaser } from './TeamMemberTeaser';

const DescriptionWithMembers = ({
  dataItem: {
    internal: { type },
    field_main_title,
    field_description,
    field_discover_more: { title, uri },
    relationships: { field_team_members },
  },
}) => (
  <div className={type}>
    <div className="content-side">
      <h3 className="title">{field_main_title}</h3>
      {field_description && (
        <div className="description">{parser(sanitize(field_description.processed))}</div>
      )}
      <div className="wrp-button desktop">
        <Link to={uri} className="btn">
          {title}
          <div className="btn-svg ">
            <Message color="#FFFF" />
          </div>
        </Link>
      </div>
    </div>

    <div className="members-side">
      <TeamMemberTeaser data={field_team_members} />
    </div>

    <div className="wrp-button mobile">
      <Link to={uri} className="btn">
        {title}
        <div className="btn-svg ">
          <Message color="#FFFF" />
        </div>
      </Link>
    </div>
  </div>
);

DescriptionWithMembers.propTypes = {
  dataItem: PropTypes.shape({
    field_main_title: PropTypes.string,
    field_description: PropTypes.object,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    field_discover_more: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_team_members: PropTypes.array,
    }),
  }).isRequired,
};

export default DescriptionWithMembers;
