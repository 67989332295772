import React, { useLayoutEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useCookies } from 'react-cookie';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { getCurrentDate, gtag } from 'utils';

import { Link } from 'components';
import { CookieSettingPopup } from './components';

const Cookies = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isCookieSettingsOpen, setIsCookieSettingsOpen] = useState(false);
  const [customizedCookies, setCustomizedCookies] = useState({
    ad_storage: true,
    analytics_storage: true,
    functionality_storage: true,
    'gatsby-gdpr-google-tagmanager': true,
  });

  const currentDate = getCurrentDate();
  const contentClasses = `cookieContent ${isCookieSettingsOpen ? 'cookieSetting' : ''}`;

  const handleClose = () => {
    setCookie('CookieConsent', currentDate);
  };

  const removeAllCookies = () => {
    Object.keys(customizedCookies).forEach((cookieName) => {
      removeCookie(cookieName, { path: '/' });
    });
  };

  const handleCustomizedChange = (cookieName, value) => {
    setCustomizedCookies((prevCustomizedCookies) => ({
      ...prevCustomizedCookies,
      [cookieName]: value,
    }));
  };

  const handleSettingsChange = (value) => {
    handleCustomizedChange('ad_storage', value);
    handleCustomizedChange('analytics_storage', value);
    handleCustomizedChange('functionality_storage', value);
    handleCustomizedChange('gatsby-gdpr-google-tagmanager', value);
  };

  const handleTracking = (
    initialTracking = false,
    updatedCustomizedCookies = customizedCookies,
  ) => {
    const dataLayerAdValue = updatedCustomizedCookies.ad_storage ? 'granted' : 'denied';
    const dataLayerFunctionalityValue = updatedCustomizedCookies.functionality_storage
      ? 'granted'
      : 'denied';
    const dataLayerAnalyticValue = updatedCustomizedCookies.analytics_storage
      ? 'granted'
      : 'denied';
    const dataLayerTGMValue = updatedCustomizedCookies['gatsby-gdpr-google-tagmanager'];

    const dataLayerState = initialTracking ? 'default' : 'update';

    gtag('consent', dataLayerState, {
      ad_storage: dataLayerAdValue,
      analytics_storage: dataLayerAnalyticValue,
      functionality_storage: dataLayerFunctionalityValue,
    });

    if (!initialTracking) {
      removeAllCookies();
    }

    setCookie('ad_storage', dataLayerAdValue);
    setCookie('analytics_storage', dataLayerAnalyticValue);
    setCookie('functionality_storage', dataLayerFunctionalityValue);
    setCookie('gatsby-gdpr-google-tagmanager', dataLayerTGMValue);

    initializeAndTrack(window.location);
  };

  const handleAccept = () => {
    handleSettingsChange(true);

    const updatedCustomizedCookies = {
      ad_storage: true,
      analytics_storage: true,
      functionality_storage: true,
      'gatsby-gdpr-google-tagmanager': true,
    };
    handleTracking(false, updatedCustomizedCookies);
  };

  const handleNecessaryOnly = () => {
    handleSettingsChange(false);

    const updatedCustomizedCookies = {
      ad_storage: false,
      analytics_storage: false,
      functionality_storage: false,
      'gatsby-gdpr-google-tagmanager': false,
    };
    handleTracking(false, updatedCustomizedCookies);
  };

  useLayoutEffect(() => {
    if (typeof cookies.CookieConsent === 'undefined') {
      // Set initial tracking to true (request from sales side)
      handleTracking(true);
    }
  }, []);

  return (
    <div>
      {!cookies.CookieConsent && (
        <CookieConsent
          location="none"
          buttonText="Accept All Cookies"
          declineButtonText="Only Necessary Cookies"
          disableStyles
          buttonClasses="cookieBtn btn"
          declineButtonClasses="cookieDeclineBtn btn"
          contentClasses={contentClasses}
          cookieValue={currentDate}
          enableDeclineButton
          onDecline={handleNecessaryOnly}
          onAccept={handleAccept}
          buttonWrapperClasses="button-wrapper"
        >
          <div className="cookieImage">
            <img src="/cookies.svg" alt="Cookies" />
          </div>

          {isCookieSettingsOpen ? (
            <CookieSettingPopup
              onBack={() => setIsCookieSettingsOpen(false)}
              onConfirm={() => {
                handleTracking();
                handleClose();
              }}
              customizedCookies={customizedCookies}
              handleCustomizedChange={handleCustomizedChange}
            />
          ) : (
            <>
              <div>
                <h3>Cookies</h3>
                <p>
                  We use cookies and similar technologies on our website to improve your browsing
                  experience and our services. Data processing may be done with your consent or on
                  the basis of a legitimate interest, which you can object to in individual privacy
                  settings. You can modify or revoke your consent at any time in the footer section
                  of the website.
                </p>
                <p>
                  You have the right not to consent and to change or revoke your consent at a later
                  time. For more information on the use of your data, please visit our{' '}
                  <Link style={{ fontSize: '14px' }} to="/privacy-policy/">
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>

              <button
                className="btn settingsBtn"
                onClick={() => setIsCookieSettingsOpen(true)}
                type="button"
              >
                Customize Cookies
              </button>
            </>
          )}
        </CookieConsent>
      )}
    </div>
  );
};

export default Cookies;
