import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';

import { parser, sanitize } from 'utils';

import { ServiceItem } from './ServiceItem';

const DevelopmentServices = ({
  dataItem: {
    field_title,
    field_description,
    internal: { type },
    relationships: { field_paragraph },
  },
}) => (
  <div className={type}>
    <span className="grey-bg__inner" />
    <div className="top-side">
      <h2 className="h2">{field_title}</h2>
      {field_description && (
        <div className="description">{parser(sanitize(field_description.processed))}</div>
      )}
    </div>
    <div className="items-list">
      {field_paragraph &&
        field_paragraph.map((item) => (
          <ServiceItem data={item} key={item.id ? item.id : uid(item)} />
        ))}
    </div>
  </div>
);

DevelopmentServices.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_paragraph: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default DevelopmentServices;
