import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg, Link } from 'components';

const ToolItem = ({
  data: {
    field_title,
    field_description,
    relationships: { field_tool_image },
  },
}) => (
  <div>
    <h4>{field_title}</h4>
    {field_description && (
      <div className="stack-description">{parser(sanitize(field_description.processed))}</div>
    )}

    <div className="stack-list">
      {field_tool_image.map(
        ({
          drupal_id,
          field_title: itemTitle,
          field_image: { alt },
          relationships: { field_image },
          field_discover_more,
        }) =>
          field_discover_more ? (
            <Link key={drupal_id} className="stack-list__item" to={field_discover_more.uri}>
              <ImgOrSvg image={field_image} alt={alt} />
              <span>{itemTitle}</span>
            </Link>
          ) : (
            <div key={drupal_id} className="stack-list__item">
              <ImgOrSvg image={field_image} alt={alt} />
              <span>{itemTitle}</span>
            </div>
          ),
      )}
    </div>
  </div>
);

ToolItem.propTypes = {
  data: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    relationships: PropTypes.shape({
      field_tool_image: PropTypes.array,
    }),
  }).isRequired,
};

export default ToolItem;
