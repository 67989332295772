import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { getCategoryPath } from 'utils';
import { useClickOutside, useWindowSize } from 'hooks';

import { ArrowBottom } from 'icons';
import { Link } from 'components';

const getLinkProps = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: 'active' } : {};

const sortByWeight = (arr) => {
  if (arr.length === 0) {
    return [];
  }
  const compareWeight = (a, b) => a.node.weight - b.node.weight;

  // Sort the array using the compare function
  return [...arr].sort(compareWeight);
};

const CategoryMenuBlog = ({ categories, additionalQuery }) => {
  const [isMobile, setMobile] = useState(false);
  const [isActive, setActive] = useState(false);
  const categoryRef = useRef(null);
  const { width } = useWindowSize();

  const sortedCategory = sortByWeight(categories);
  const isDefaultPath = additionalQuery === '';
  const linksUrl = isDefaultPath ? '/blog/category/' : `/blog/${additionalQuery}/category/`;
  const latestLinkUrl = isDefaultPath ? '/blog/' : `/blog/${additionalQuery}/`;
  const categoryClass = isMobile ? 'mobile-category-menu' : 'category-menu';

  const toggleClass = () => {
    setActive(!isActive);
  };

  useClickOutside(categoryRef, () => {
    setActive(false);
  });

  useEffect(() => {
    setMobile(width <= 1023);
  }, [width]);

  return (
    <div ref={categoryRef} className={CN('blog-menu', { 'active-category-menu': isActive })}>
      {isMobile && <ArrowBottom onClick={toggleClass} />}

      <ul className={categoryClass}>
        <li>
          <Link to={latestLinkUrl} onClick={toggleClass}>
            Latest
          </Link>
        </li>

        {sortedCategory.map(({ node: category }) => {
          const isCategoryExist = category.relationships.node__blog?.length > 0;
          const categoryName = category.name;

          return isCategoryExist ? (
            <li key={category.drupal_internal__tid}>
              <Link
                getProps={getLinkProps}
                onClick={toggleClass}
                to={`${linksUrl}${getCategoryPath(categoryName)}/`}
              >
                {categoryName}
              </Link>
            </li>
          ) : (
            <></>
          );
        })}
      </ul>
    </div>
  );
};

CategoryMenuBlog.propTypes = {
  categories: PropTypes.array,
  additionalQuery: PropTypes.string,
};

CategoryMenuBlog.defaultProps = {
  additionalQuery: '',
};

export default CategoryMenuBlog;
