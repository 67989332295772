import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import CN from 'classnames';

const Text = ({ dataItem: { field_description, field_remove_margin, field_grey_background } }) => {
  const formattedContent = field_description.processed
    .split('/sites/default/files')
    .join(`${process.env.GATSBY_SSL_URL}sites/default/files`);

  const parsedBody = parser(sanitize(formattedContent));

  return (
    parsedBody && (
      <div
        className={CN(
          'paragraph-text',
          'description',
          `remove-${field_remove_margin || 'none'}`,
          field_grey_background ? 'grey-background' : '',
        )}
      >
        <div>{parsedBody}</div>
      </div>
    )
  );
};

Text.propTypes = {
  dataItem: PropTypes.shape({
    field_remove_margin: PropTypes.string,
    field_description: PropTypes.object,
    field_grey_background: PropTypes.bool,
  }),
};

export default Text;
