import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { Link } from 'components';
import { Message } from 'icons';

const CTA = ({ field_cta_text: { processed: ctaText }, field_cta: { title, uri } }) => (
  <div className="case-solution-cta">
    <div className="case-solution-cta-text">{parser(sanitize(ctaText))}</div>
    <Link className="case-solution-cta-link" to={uri}>
      <span className="case-solution-cta-link-title">{title}</span>
      <span className="case-solution-cta-link-icon">
        <Message />
      </span>
    </Link>
  </div>
);

CTA.propTypes = {
  field_cta_text: PropTypes.shape({
    processed: PropTypes.string,
  }),
  field_cta: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
  }),
};

export default CTA;
