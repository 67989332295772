import React from 'react';

import { Message, CallUs } from 'icons';
import { Link } from 'components';

const HireUs = () => (
  <div className="hire-us">
    <div className="hire-us--button">
      <Link to="/contact-us" className="mobile-btn">
        <CallUs />
      </Link>
      <Link to="/contact-us" className="btn">
        <span>Letʼs Talk</span>

        <div className="btn-logo">
          <Message />
        </div>
      </Link>
    </div>
  </div>
);

export default HireUs;
