import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const OfficeItem = ({
  data: {
    field_office_location,
    relationships: { field_office_image, field_image },
    field_addresses_and_phones,
    field_office_image: { alt },
    field_image: { alt: office_map_alt },
  },
}) => (
  <div className="office-item">
    <div className="paragraph__offices-head">
      <div className="office-image">
        <ImgOrSvg image={field_office_image} alt={alt} />
      </div>
      <div className="office-info">
        <h3 className="office-country">{field_office_location.toString()}</h3>
        <div className="office-content">
          {field_addresses_and_phones && (
            <div className="office-address">
              <div className="office-address__content">
                {parser(sanitize(field_addresses_and_phones.processed))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="office-map">
      <ImgOrSvg image={field_image} alt={office_map_alt} />
    </div>
  </div>
);

OfficeItem.propTypes = {
  data: PropTypes.shape({
    field_office_location: PropTypes.string,
    relationships: PropTypes.shape({
      field_office_image: PropTypes.object,
      field_image: PropTypes.object,
    }),
    field_addresses_and_phones: PropTypes.object,
    field_office_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
  }).isRequired,
};

export default OfficeItem;
