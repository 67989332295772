import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { Link } from 'components';

const Pagination = ({
  isFirst,
  numPages,
  currentPage,
  isLast,
  nextPage,
  prevPage,
  paginationPath,
}) => {
  if (numPages > 1) {
    return (
      <ul className="pagination without-wave">
        {!isFirst && (
          <li
            className={CN({
              'first-page': !isFirst,
            })}
          >
            <Link to={`/${prevPage}`} rel="prev">
              ←
            </Link>
          </li>
        )}

        {currentPage > 3 && (
          <li>
            <Link to={`${paginationPath}/`}>1</Link>
          </li>
        )}

        {Array.from({ length: numPages }, (_, i) => {
          const hideBeforeIndex = isLast ? 2 : 1;
          const hideBefore = i + 1 < currentPage - hideBeforeIndex && currentPage !== 1;

          const hideAfterIndex = isFirst ? 2 : 1;
          const hideAfter = i + 1 > currentPage + hideAfterIndex && !isLast;

          const isCurrent = currentPage === i + 1;
          const pageIndex = currentPage - 2 === 1 ? '' : currentPage - 2;
          const dynamicPaginationPath = hideBefore ? `${pageIndex}` : `${i === 0 ? '' : i + 1}`;
          if (numPages > 4) {
            return (
              <li
                className={CN({
                  'hide-before': hideBefore,
                  'hide-after': hideAfter,
                  'current-page': isCurrent,
                })}
                key={`pagination-number${i + 1}`}
              >
                <Link
                  to={`${paginationPath}/${dynamicPaginationPath}/`}
                  className={CN({
                    active: currentPage === i + 1,
                  })}
                >
                  {hideBefore || hideAfter ? '' : i + 1}
                </Link>
              </li>
            );
          }

          return (
            <li
              className={CN({
                'current-page': currentPage === i + 1,
              })}
              key={`pagination-number${i + 1}`}
            >
              <Link
                to={`${paginationPath}/${i === 0 ? '' : i + 1}/`}
                className={currentPage === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </Link>
            </li>
          );
        })}

        {currentPage + 3 < numPages && (
          <li>
            <Link to={`${paginationPath}/${numPages}/`}>{numPages}</Link>
          </li>
        )}

        {!isLast && (
          <li className={!isLast ? 'last-page' : ''}>
            <Link to={`/${nextPage}`} rel="next">
              →
            </Link>
          </li>
        )}
      </ul>
    );
  }

  return null;
};

Pagination.propTypes = {
  isFirst: PropTypes.bool,
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  isLast: PropTypes.bool,
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
  paginationPath: PropTypes.string,
};

export default Pagination;
