/**
 *
 * @param paragraphs - array of objects
 * @returns {*|null}
 *
 * function returns first found paragraph from all
 */

const getFirstExistedParagraph = (paragraphs) => {
  const firstParagraph = paragraphs.filter(
    (paragraph) => paragraph.field_description && paragraph,
  )[0];
  return firstParagraph?.field_description?.processed || null;
};

/**
 *
 * @param node - object
 * @returns {{blogImage: object, blogHeaderImage: object, summary: (*|string)}}
 */
const getPostContent = (node) => {
  const image = node.relationships.field_image;
  const headerImage = node.relationships.field_image_header || image;

  const imageAlt = node.field_image?.alt || 'AnyforSoft';
  const headerImageAlt = node.field_image_header?.alt || imageAlt;

  const summary =
    node.body?.processed || getFirstExistedParagraph(node.relationships.field_content) || '';

  return {
    blogImage: { image, alt: imageAlt },
    blogHeaderImage: { image: headerImage, alt: headerImageAlt },
    summary,
  };
};

export default getPostContent;
