import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import Sticky from 'react-sticky-el';

import { parser, sanitize } from 'utils';
import { useWindowSize } from 'hooks';

import { FunctionalityItem } from './components';

const renderTwoColumns = (functionalityItems) => (
  <>
    <div className="functionality-items-column">
      {functionalityItems.map((item, index) => {
        if ((index + 1) % 2) {
          return <FunctionalityItem key={item.id} item={item} index={index} />;
        }
        return <div key={item.id} />;
      })}
    </div>
    <div className="functionality-items-column">
      {functionalityItems.map((item, index) => {
        if (index % 2) {
          return <FunctionalityItem key={item.id} item={item} index={index} />;
        }
        return <div key={item.id} />;
      })}
    </div>
  </>
);

const CaseFunctionality = ({
  dataItem: {
    internal: { type },
    field_title,
    field_description: { processed: description },
    relationships: { field_functionality_items },
  },
}) => {
  const [functionalityItems, setFunctionalityItems] = useState(field_functionality_items);
  const [isSticky, setIsSticky] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const functionalityRef = useRef(null);
  const { width } = useWindowSize();

  let totalHeight = 0;

  if (typeof window !== 'undefined') {
    const functionalityContainer = functionalityRef?.current;
    const headerTopElement = document.querySelector('div.header-top');
    const floatingBarElement = document.querySelector('span.floating-bar');
    const headerTopHeight = headerTopElement ? headerTopElement.clientHeight : 0;
    const floatingBarHeight = floatingBarElement ? floatingBarElement.clientHeight : 0;
    const extraElementsHeight = headerTopHeight + floatingBarHeight;
    const functionalityPaddingTop = functionalityContainer
      ? window.getComputedStyle(functionalityRef?.current).getPropertyValue('padding-top')
      : 0;

    totalHeight = extraElementsHeight + parseInt(functionalityPaddingTop, 0);
  }

  const totalItemsNumber = field_functionality_items.length;
  const equalItems = functionalityItems.length === totalItemsNumber;
  const expandBtnText = equalItems ? 'Hide features' : `Show all features (${totalItemsNumber})`;
  const extraMargin = isTablet && isSticky ? totalHeight : 0;

  const toggleSticky = () => {
    setIsSticky((prevState) => !prevState);
  };

  const onToggle = () => {
    if (equalItems) {
      return setFunctionalityItems(field_functionality_items.slice(0, 4));
    }

    return setFunctionalityItems(field_functionality_items);
  };

  useEffect(() => {
    setIsTablet(width >= 1025);
    setIsDesktop(width >= 1201);

    if (width >= 768) {
      return setFunctionalityItems(field_functionality_items);
    }

    return setFunctionalityItems(field_functionality_items.slice(0, 4));
  }, [width]);

  return (
    <div className={type} ref={functionalityRef}>
      <div className="functionality-info">
        <Sticky
          boundaryElement={`.${type}`}
          topOffset={-totalHeight}
          bottomOffset={110}
          onFixedToggle={toggleSticky}
          hideOnBoundaryHit={false}
          disabled={!isTablet}
        >
          <div style={{ marginTop: extraMargin }}>
            <h2 className="functionality-info-title">{field_title}</h2>
            <div className="functionality-info-description">{parser(sanitize(description))}</div>
          </div>
        </Sticky>
      </div>

      <div className="functionality-items">
        {isDesktop ? (
          renderTwoColumns(functionalityItems)
        ) : (
          <>
            {functionalityItems.map((item, index) => (
              <FunctionalityItem key={item.id} item={item} index={index} />
            ))}
          </>
        )}

        {field_functionality_items.length > 4 && (
          <button
            type="button"
            className={CN('functionality-items-button', {
              'functionality-items-expanded': equalItems,
            })}
            onClick={onToggle}
          >
            {expandBtnText}
          </button>
        )}
      </div>
    </div>
  );
};

CaseFunctionality.propTypes = {
  dataItem: PropTypes.object,
};

export default CaseFunctionality;
