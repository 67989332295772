import React, { useRef } from 'react';

import { ControlledMenu, useMenuState } from '@szhsin/react-menu';
import CN from 'classnames';
import PropTypes from 'prop-types';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { Link } from 'components';
import { ArrowBottom } from 'icons';
import { SubMenuItem } from 'components/Header/components/SubMenuItem';

const SubNavigationItem = ({ data }) => {
  const ref = useRef(null);
  const { toggleMenu, ...menuProps } = useMenuState({
    transition: true,
    visible: true,
  });

  const { subtree } = data;
  const isNestedNavigation = subtree.length > 0;
  const isOpen = menuProps.state === 'open';
  const isActive = isOpen || menuProps.state === 'opening';

  const handleOpen = () => {
    toggleMenu(true);
  };

  const handleClose = () => {
    toggleMenu(false);
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  return (
    <div
      className={CN('navigationItem', { active: false })}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <div ref={ref} className="mainNavLinkBox">
        <Link
          to={data.link.url}
          className={CN('mainNavLink', {
            nestedLink: isNestedNavigation,
            isActive,
          })}
        >
          {data.link.title}
        </Link>
        {data.subtree.length > 0 && (
          <button className="expand-btn" type="button" onClick={handleToggle}>
            <ArrowBottom />
          </button>
        )}
      </div>

      {isNestedNavigation && (
        <ControlledMenu
          {...menuProps}
          anchorRef={ref}
          offsetY={31}
          className="navigationList slide"
        >
          {subtree.map((item) => {
            const isNestedItemNavigation = item.subtree?.length > 0;

            return isNestedItemNavigation ? (
              <SubMenuItem key={item.link.url} item={item} />
            ) : (
              <Link key={item.link.url} variant="grayUppercase" to={item.link.url}>
                {item.link.title}
              </Link>
            );
          })}
        </ControlledMenu>
      )}
    </div>
  );
};

SubNavigationItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SubNavigationItem;
