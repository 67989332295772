import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { ImgOrSvg } from 'components';

const Image = ({
  dataItem: {
    field_grey_background,
    field_image_with_shadow,
    field_disable_json_autoloop,
    internal: { type },
    relationships: { field_image: image, field_json_lottie },
    field_image_or_json,
    field_image,
  },
}) => {
  const disabledLottieParagraph = useRef(null);

  return (
    <div
      className={CN('paragraph-image', field_grey_background && 'grey-background')}
      ref={disabledLottieParagraph}
    >
      <div className={type}>
        <ImgOrSvg
          className={field_image_with_shadow ? 'image-with-shadow' : ''}
          image={field_image_or_json === 'lottie_json' ? field_json_lottie : image}
          alt={field_image?.alt || 'AnyforSoft'}
          quality={100}
          field_disable_json_autoloop={field_disable_json_autoloop}
          disabledLottieParagraph={disabledLottieParagraph}
        />
      </div>
    </div>
  );
};

Image.propTypes = {
  dataItem: PropTypes.shape({
    field_grey_background: PropTypes.bool,
    field_image_with_shadow: PropTypes.bool,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_json_lottie: PropTypes.object,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_image_or_json: PropTypes.string,
    field_disable_json_autoloop: PropTypes.bool,
  }).isRequired,
};

export default Image;
