import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const CaseSolutionItem = ({
  item: {
    field_title,
    field_subtitle,
    field_description: { processed: description },
    field_image,
    relationships: { field_image: image },
  },
}) => (
  <div className="case-solution-item">
    <div className="case-solution-info">
      {field_title && <h2 className="case-solution-title">{field_title}</h2>}
      <span className="case-solution-subtitle">{field_subtitle}</span>
      <div className="case-solution-description">{parser(sanitize(description))}</div>
    </div>
    {image && (
      <div className="case-solution-image">
        <ImgOrSvg image={image} alt={field_image.alt} />
      </div>
    )}
  </div>
);

CaseSolutionItem.propTypes = {
  item: PropTypes.shape({
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    field_description: PropTypes.shape({
      processed: PropTypes.string,
    }),
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
    }),
  }),
};

export default CaseSolutionItem;
