import React from 'react';
import PropTypes from 'prop-types';

import { parser, sanitize } from 'utils';

import { Link, ImgOrSvg } from 'components';

const CTABlock = ({
  dataItem: {
    relationships: {
      field_image,
      field_content: {
        field_title,
        field_description,
        field_button: { uri, title },
      },
    },
  },
}) => (
  <div className="paragraph-cta">
    <div className="paragraph-cta__left">
      <h3 className="paragraph-cta__title">{field_title}</h3>
      <div className="paragraph-cta__description">
        {parser(sanitize(field_description.processed))}
      </div>

      <Link to={uri} className="btn paragraph-cta__btn">
        {title}
      </Link>
    </div>

    <div className="paragraph-cta__right">
      <ImgOrSvg image={field_image} alt={field_image.alt} />
    </div>
  </div>
);

CTABlock.propTypes = {
  dataItem: PropTypes.shape({
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_content: PropTypes.shape({
        field_title: PropTypes.string,
        field_description: PropTypes.string,
        field_button: PropTypes.shape({
          uri: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default CTABlock;
