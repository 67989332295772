import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { Attachment } from 'icons';

const FileField = ({
  value,
  label,
  placeholder = 'Choose a file',
  error,
  onChange,
  onBlur,
  withRemoveBtn,
  withFilePlaceholder,
}) => {
  const inputFileRef = useRef(null);

  const labelText = withRemoveBtn && value ? 'Remove' : value?.name || label;

  const handleReset = (event) => {
    const inputValue = inputFileRef?.current.value;

    if (inputValue !== '' && withRemoveBtn) {
      event.preventDefault();

      inputFileRef.current.value = '';
      onChange(event);
    }
  };

  return (
    <div
      className={CN('form-item item-type-file', {
        error,
      })}
    >
      <div className="file-box">
        <Attachment className="attachment-icon" color={value ? '#1d746b' : '#989898'} />

        <input
          ref={inputFileRef}
          type="file"
          name="file"
          onChange={onChange}
          onClick={handleReset}
          onBlur={onBlur}
        />

        {withFilePlaceholder && <span className="file-placeholder">{placeholder}</span>}

        {withRemoveBtn && <span className="file-name">{value?.name || 'Choose a file'}</span>}

        <label className={CN({ uploaded: value })}>{labelText}</label>
      </div>

      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

FileField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  withRemoveBtn: PropTypes.bool,
  withFilePlaceholder: PropTypes.bool,
};

export default FileField;
