import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ video_file, video_preview, autoplay_status, field_link, id }) => {
  const videoId = id || field_link;

  const videoDynamicProps = autoplay_status
    ? {
        muted: true,
        loop: true,
      }
    : {
        light: video_preview,
      };

  return (
    <ReactPlayer
      id={videoId}
      className="video-player"
      url={video_file ? encodeURI(video_file) : field_link}
      wrapper="div"
      controls={!autoplay_status}
      playing
      playsinline
      {...videoDynamicProps}
    />
  );
};

VideoPlayer.propTypes = {
  video_file: PropTypes.string,
  autoplay_status: PropTypes.bool,
  video_preview: PropTypes.string,
  id: PropTypes.string,
  field_link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default VideoPlayer;
