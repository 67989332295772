import React from 'react';
import PropTypes from 'prop-types';

import { Link, ImgOrSvg } from 'components';
import Arrow from 'components/../icons/Arrow';

const CaseStudiesItem = ({
  data: {
    title,
    field_sub_title,
    path: { alias },
    relationships: { field_image },
    field_image: { alt },
  },
}) => (
  <div className="case-studies-item">
    {field_image && (
      <div className="case-studies-item-media">
        <ImgOrSvg
          itemProp="image"
          image={field_image}
          className="case-studies-item-img"
          alt={alt}
        />
      </div>
    )}

    <div className="case-studies-item-box">
      {title && <h3 className="case-studies-item-title">{title}</h3>}

      <div className="case-studies-item-subtitle">{field_sub_title || ' '}</div>
    </div>

    <Link to={`${alias}/`} className="case-studies-item-link" target="_self" />

    {alias && (
      <div className="case-studies-item-icon">
        <div className="icon-inner">
          <div className="icon-svg">
            <Arrow color="#000" />
          </div>
        </div>
      </div>
    )}
  </div>
);

CaseStudiesItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    field_sub_title: PropTypes.string,
    path: PropTypes.object,
    relationships: PropTypes.object,
    field_image: PropTypes.object,
  }),
};

export default CaseStudiesItem;
