import React, { forwardRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import { MediaSliderItem } from './components/MediaSliderItem';

const SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  draggable: true,
  swipeToSlide: true,
  focusOnSelect: true,
  accessibility: true,
  slidesToShow: 5.1,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4.5,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1.6,
      },
    },
  ],
};

const MediaSlider = forwardRef(({ data, onSlide }, ref) => {
  const sliderSettings = {
    ...SLIDER_SETTINGS,
    beforeChange: () => {
      // TODO: use currentSlide argument in beforeChange function
      //  after fix will be delivered https://github.com/akiran/react-slick/issues/1214
      setTimeout(() => {
        onSlide();
      }, 10);
    },
  };

  return (
    <div className="media-slider">
      <Slider
        ref={ref}
        aria-label="Services Slider"
        id="Services"
        className="media-slider__box"
        {...sliderSettings}
      >
        {data.length && data.map((item) => <MediaSliderItem key={item.drupal_id} data={item} />)}
      </Slider>
    </div>
  );
});

MediaSlider.propTypes = {
  data: PropTypes.array.isRequired,
  onSlide: PropTypes.func.isRequired,
};

MediaSlider.displayName = 'MediaSlider';

export default MediaSlider;
