import React from 'react';
import PropTypes from 'prop-types';

const Process = ({
  dataItem: {
    field_title,
    field_process_list,
    internal: { type },
  },
}) => (
  <div className={type}>
    <h2>{field_title}</h2>
    <div className="process-list">
      {field_process_list.map((item, index) => (
        <li key={item.toString()} data-index={index + 1}>
          {item}
        </li>
      ))}
    </div>
  </div>
);

Process.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_process_list: PropTypes.array,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default Process;
