import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { EmailForm, EventForm, BasicForm } from './components';

const query = graphql`
  query webforms {
    allWebformWebform {
      ...AllWebform
    }
  }
`;

const Form = ({
  formId,
  file,
  title,
  inputId,
  btnText,
  fileBtnText,
  withRemoveFileBtn,
  withFilePlaceholder,
}) => {
  const {
    allWebformWebform: { edges: webforms },
  } = useStaticQuery(query);

  const successMessage = webforms.reduce(
    (
      acc,
      {
        node: {
          drupal_internal__id,
          settings: { confirmation_message },
        },
      },
    ) => (drupal_internal__id === formId ? acc + confirmation_message : acc),
    '',
  );

  const forms = {
    contact: (
      <BasicForm
        formId={formId}
        title={title}
        inputId={inputId}
        btnText={btnText}
        fileBtnText={fileBtnText}
        withRemoveFileBtn={withRemoveFileBtn}
        withFilePlaceholder={withFilePlaceholder}
      />
    ),
    recruiter_form: <BasicForm formId={formId} title={title} inputId={inputId} />,
    events: <EventForm formId={formId} title={title} />,
    subscribe: (
      <EmailForm
        formId={formId}
        successMessage={successMessage}
        buttonText="Subscribe"
        description="Subscribe to receive the latest industry news"
      />
    ),
    checklist: (
      <EmailForm
        formId={formId}
        successMessage={successMessage}
        buttonText="Download"
        file={file}
        title={title}
      />
    ),
  };

  return forms[formId] || <div>{formId} - There isn't such type of form!</div>;
};

Form.propTypes = {
  formId: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  title: PropTypes.string,
  btnText: PropTypes.string,
  fileBtnText: PropTypes.string,
  withRemoveFileBtn: PropTypes.bool,
  withFilePlaceholder: PropTypes.bool,
  file: PropTypes.shape({
    drupal_internal__fid: PropTypes.number,
    filename: PropTypes.string,
    filesize: PropTypes.number,
    localFile: PropTypes.shape({
      extension: PropTypes.string,
    }),
    uri: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

Form.defaultProps = {
  inputId: '',
  title: '',
  file: null,
  withRemoveFileBtn: false,
  withFilePlaceholder: false,
};

export default Form;
