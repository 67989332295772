import React from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick/lib/slider';

import { ClutchWidget } from 'components';
import { WpsItem } from './WpsItem';

const Wps = ({
  dataItem: {
    field_title,
    internal: { type },
    relationships: {
      field_reference,
      field_reference: { length },
    },
  },
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,

    customPaging(i) {
      return (
        <>
          <span className="current">{`${i + 1} `}</span>/{' '}
          <span className="total">{`${length}`}</span>
        </>
      );
    },
  };

  return (
    <div className={`without-wave ${type}`}>
      <h2>
        <span>{field_title}</span>
      </h2>
      <div className="wps-slider">
        <Slider {...settings}>
          {field_reference && field_reference.map((item) => <WpsItem key={item.id} data={item} />)}
        </Slider>
        <ClutchWidget id="335158" />
      </div>
    </div>
  );
};

Wps.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_reference: PropTypes.array,
    }),
  }).isRequired,
};

export default Wps;
