import { useCallback, useState } from 'react';

export const useForceUpdate = () => {
  const [, setTick] = useState(0);

  return useCallback(() => {
    setTimeout(() => setTick((tick) => tick + 1), 0);
  }, []);
};

export default useForceUpdate;
