import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

const Checkbox = ({ checked, name, id, label, error, onChange, onBlur, children, helperText }) => (
  <div
    className={CN('form-item item-type-checkbox', {
      error,
    })}
  >
    <input
      id={id}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
    />
    <label className="checkbox-label" htmlFor={id}>
      {label || children}
    </label>
    {helperText}
    {error && <div className="error-message">{error}</div>}
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Checkbox.defaultProps = {
  label: '',
  children: '',
  helperText: '',
};

export default Checkbox;
