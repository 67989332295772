import React from 'react';
import PropTypes from 'prop-types';

import { getCategoryPath } from 'utils';

import { Link, ImgOrSvg } from 'components';

const BlogCard = ({ path, category, readingTime, title, image, alt }) => {
  const blogUrl = path || '';
  const blogAlt = alt || 'Blog Preview';

  return (
    <div className="blog-card-item" itemScope itemType="https://schema.org/Thing">
      <Link to={blogUrl} className="blog-card-link" itemProp="url" />

      {image && (
        <div className="teaser-image">
          <ImgOrSvg image={image} itemProp="image" objectFit="cover" alt={blogAlt} />
        </div>
      )}

      <div className="news-tags-time" itemProp="alternateName">
        <div className="blog-category">
          {category.map((item) => {
            const categoryName = item.value || item.name;

            return (
              <Link
                key={categoryName}
                className="news-tags-link"
                to={`/blog/category/${getCategoryPath(categoryName)}/`}
              >
                {categoryName}
              </Link>
            );
          })}
        </div>

        <div className="reading-time" itemProp="identifier">
          {readingTime}
        </div>
      </div>
      <h3 className="news-title" itemProp="name">
        {title}
      </h3>
    </div>
  );
};

BlogCard.propTypes = {
  path: PropTypes.string,
  readingTime: PropTypes.string,
  category: PropTypes.array,
  title: PropTypes.string,
  alt: PropTypes.string,
  image: PropTypes.object,
};

export default BlogCard;
