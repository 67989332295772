import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { IndustryItem } from '../IndustryItem';

const TabSlider = ({ items }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [minHeight, setMinHeight] = useState(200);
  const slidesListRef = useRef(null);

  useEffect(() => {
    const sliderHeights = slidesListRef.current?.childNodes?.length
      ? Array.from(slidesListRef.current.childNodes).map((slider) => slider.offsetHeight)
      : [200];
    setMinHeight(Math.max(...sliderHeights));
  }, []);

  const toggleActiveSlide = (i) => () => {
    setActiveSlide(i);
  };

  return (
    <div className="industry-slider">
      {items && (
        <>
          <div className="industry-tabs">
            {items.map((item, index) => (
              <IndustryItem
                type="tabs"
                data={item}
                key={item.title}
                isActive={activeSlide === index}
                toggleActiveSlide={toggleActiveSlide}
                index={index}
              />
            ))}
          </div>
          <div
            ref={slidesListRef}
            className="industry-slides"
            style={{
              minHeight,
            }}
          >
            {items.map((item, index) => (
              <IndustryItem data={item} key={item.title} isActive={activeSlide === index} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

TabSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default TabSlider;
