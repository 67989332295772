import React from 'react';
import PropTypes from 'prop-types';

const DefaultAvatar = ({ className, color }) => (
  <svg
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMin"
    className={className}
    color={color}
  >
    <path
      d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z"
      fill="#D9D9D9"
    />

    <path
      d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z"
      fill="#ECF2F9"
    />
    <path
      d="M60.7513 66.5453C60.7513 78.2407 51.2703 87.7217 39.5749 87.7217C27.8794 87.7217 18.3984 78.2407 18.3984 66.5453C18.3984 54.8499 27.8794 45.3689 39.5749 45.3689C51.2703 45.3689 60.7513 54.8499 60.7513 66.5453Z"
      fill="#F5F9FD"
    />
    <path
      d="M51.125 29.4761C51.125 35.8554 45.9535 41.0268 39.5742 41.0268C33.1949 41.0268 28.0234 35.8554 28.0234 29.4761C28.0234 23.0967 33.1949 17.9253 39.5742 17.9253C45.9535 17.9253 51.125 23.0967 51.125 29.4761Z"
      fill="#F5F9FD"
    />
    <path
      d="M75 40C75 59.33 59.33 75 40 75C20.67 75 5 59.33 5 40C5 20.67 20.67 5 40 5C59.33 5 75 20.67 75 40Z"
      stroke="#ECF2F9"
      strokeWidth="10"
    />
  </svg>
);

DefaultAvatar.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

DefaultAvatar.defaultProps = {
  className: '',
  color: '',
};

export default DefaultAvatar;
