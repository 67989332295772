import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg } from 'components';
import { ToolItem } from './ToolItem';

const Tools = ({
  dataItem: {
    field_title,
    relationships: { field_image, field_tool_stack },
    internal: { type },
  },
}) => (
  <div className={type}>
    <h2>{field_title}</h2>
    <div className="tools-list">
      <ImgOrSvg image={field_image} alt={field_title} className="bg-image" />
      {field_tool_stack.map((item) => (
        <ToolItem key={item.drupal_id} data={item} />
      ))}
    </div>
  </div>
);

Tools.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_tool_stack: PropTypes.array,
    }),
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default Tools;
