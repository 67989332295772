import React from 'react';
import PropTypes from 'prop-types';

import { PLAY_PAUSE_RATIO, TEXT_DISPLAY_TIME } from 'globals';

const DynamicSubheading = ({ dynamicSubheading }) => {
  const ascProps = dynamicSubheading?.map((item, index) => {
    const y = index * (100 / dynamicSubheading.length);
    return index === 0
      ? '{transform: translate3d(0, 0, 0);}'
      : `{transform: translate3d(0, -${y.toFixed(2)}%, 0);}`;
  });
  const descProps = [...ascProps].reverse().slice(1, ascProps.length - 1);
  const transformProps = [...ascProps, ...descProps];

  const frameSteps = (dynamicSubheading.length - 1) * 2;
  const frameChange = 100 / (frameSteps * (PLAY_PAUSE_RATIO + 1));
  const framePause = frameChange * PLAY_PAUSE_RATIO;

  const keyframesProps = transformProps
    .map((item, index) => {
      const from = (framePause + frameChange) * index;
      const to = from + framePause;
      if (index === 0) return `0%, ${framePause.toFixed(2)}%, 100%`;
      return `${from.toFixed(2)}%, ${to.toFixed(2)}%`;
    })
    .map((item, index) => `${item} ${transformProps[index]}`)
    .join('');

  const dynamicKeyframes = `
        @-webkit-keyframes change {${keyframesProps}}
        @-moz-keyframes change {${keyframesProps}}
        @keyframes change {${keyframesProps}}`;

  if (typeof window !== 'undefined' && !!window.document) {
    const subheaderStyle = document.createElement('STYLE');
    subheaderStyle.innerHTML = dynamicKeyframes;
    document.getElementsByTagName('head')[0].appendChild(subheaderStyle);

    const duration = frameSteps * TEXT_DISPLAY_TIME;
    document.documentElement.style.setProperty('--animation-change-duration', `${duration}s`);
  }

  return (
    <span className="header-dynamic-subheading">
      <ul>
        {dynamicSubheading.map((txt) => (
          <li key={txt}>
            <span>{txt}</span>
          </li>
        ))}
      </ul>
    </span>
  );
};

DynamicSubheading.propTypes = {
  dynamicSubheading: PropTypes.arrayOf(PropTypes.string),
};

export default DynamicSubheading;
