import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

import Stories from './Stories';

const StoriesWrapper = ({
  dataItem,
  dataItem: {
    field_title,
    field_description,
    field_stories_display,
    internal: { type },
  },
}) => (
  <div className={CN(type, 'blue-bg-right')}>
    <div id={type} className="anchor-for-menu" />

    {field_stories_display === 'stories' && <span className="blue-bg-right__inner" />}

    {field_title && <h2>{field_title}</h2>}
    {field_stories_display === 'stories' && field_description && (
      <div className="story-text">{parser(sanitize(field_description.processed))}</div>
    )}

    <div className="story-slider">
      <div className="story-slider--wrapper">
        {field_stories_display === 'stories' && (
          <Stories slides={1} sliderLayout="sliderOneCol" data={dataItem} />
        )}
        {field_stories_display === 'stories_simplified' && (
          <Stories slides={2} sliderLayout="sliderTwoCol" data={dataItem} />
        )}
      </div>
    </div>
  </div>
);

StoriesWrapper.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    field_stories_display: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default StoriesWrapper;
