import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { MediaSlider, TextSlider } from './components';

const DoubleServicesSlider = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderMediaRef = useRef(null);
  const sliderTextRef = useRef(null);

  const handleNext = () => sliderMediaRef.current.slickNext();

  const handlePrev = () => sliderMediaRef.current.slickPrev();

  const handleSlide = () => {
    const mediaSliderList = sliderMediaRef.current.innerSlider.list;
    const activeSlide = mediaSliderList.querySelector('.slick-current');
    const currentIndex = +activeSlide.getAttribute('data-index');

    setCurrentSlide(currentIndex);
  };

  useEffect(() => {
    sliderTextRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);

  return (
    <div className={CN('paragraph-service', data.internal.type)}>
      <h2>{data.field_title}</h2>

      <div className="service-content">
        <MediaSlider
          data={data.relationships.field_reference}
          ref={sliderMediaRef}
          onSlide={handleSlide}
          currentSlide={currentSlide}
        />

        <TextSlider
          data={data.relationships.field_reference}
          ref={sliderTextRef}
          onNext={handleNext}
          onPrev={handlePrev}
          currentSlide={currentSlide}
        />
      </div>

      <div className="background-element" />
    </div>
  );
};

DoubleServicesSlider.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DoubleServicesSlider;
