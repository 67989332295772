import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { ClutchWidget } from 'components/ClutchWidget';
import { ReviewSlider } from './components';

const Review = ({
  dataItem: {
    field_title,
    internal: { type },
    relationships: {
      field_reference: { length },
    },
  },
  dataItem,
}) => {
  const isSliderDisabled = length === 1;

  return (
    <div
      className={CN(`without-wave ${type}`, {
        'slider-disabled': isSliderDisabled,
      })}
    >
      <h2>{field_title}</h2>

      <div className="review-slider">
        <ReviewSlider dataItem={dataItem} />
        <ClutchWidget id="335158" />
      </div>
    </div>
  );
};

Review.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.object,
  }),
};

export default Review;
