import React from 'react';
import PropTypes from 'prop-types';

import { VideoPlayer } from 'components';

const Video = ({
  dataItem: {
    field_video_autoplay_status,
    field_link,
    drupal_id,
    relationships: { field_video, field_video_file_preview },
  },
}) => (
  <div className="paragraph__video">
    <VideoPlayer
      video_file={field_video?.localFile?.publicURL}
      video_preview={field_video_file_preview?.localFile?.publicURL}
      id={drupal_id}
      autoplay_status={field_video_autoplay_status}
      field_link={field_link?.uri}
    />
  </div>
);

Video.propTypes = {
  dataItem: PropTypes.shape({
    field_video_autoplay_status: PropTypes.bool,
    field_video_file_preview: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_link: PropTypes.object,
    drupal_id: PropTypes.string,
    relationships: PropTypes.shape({
      field_video: PropTypes.object,
      field_video_file_preview: PropTypes.object,
    }),
  }).isRequired,
};

export default Video;
