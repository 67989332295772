import React from 'react';
import PropTypes from 'prop-types';

import { getClearUrl, removeBr } from 'utils';
import { SITE_DOMAIN } from 'globals';

import { Link } from 'components';

const getCustomBreadcrumbValue = (customBreadcrumbs) => (type, index) => {
  if (!customBreadcrumbs) {
    return '';
  }

  if (type === 'title') {
    return customBreadcrumbs[index].field_link.title;
  }
  if (type === 'uri') {
    return customBreadcrumbs[index].field_link.uri;
  }

  return '';
};

const Breadcrumbs = ({
  location,
  nodeTitle,
  parentBreadcrumb,
  parentTitle,
  isDefaultBreadcrumbs,
  customBreadcrumbs,
}) => {
  const shouldUseCustomBreadcrumbs = !isDefaultBreadcrumbs && customBreadcrumbs?.length >= 2;
  const defaultParentLocation = parentTitle || 'Home';
  const defaultParentUrl = parentBreadcrumb || '/';
  const getBreadcrumbValues = getCustomBreadcrumbValue(customBreadcrumbs);

  const currentPageIndex = shouldUseCustomBreadcrumbs && customBreadcrumbs.length === 3 ? 2 : 1;

  const parentUrl = shouldUseCustomBreadcrumbs ? getBreadcrumbValues('uri', 0) : defaultParentUrl;
  const parentPageName = shouldUseCustomBreadcrumbs
    ? getBreadcrumbValues('title', 0)
    : defaultParentLocation;

  const currentPageName = shouldUseCustomBreadcrumbs
    ? getBreadcrumbValues('title', currentPageIndex)
    : removeBr(nodeTitle) || 'Page Title';

  const currentUrl = shouldUseCustomBreadcrumbs
    ? getBreadcrumbValues('uri', currentPageIndex)
    : location;

  return (
    <>
      <div className="breadcrumbs">
        <Link to={parentUrl} className="breadcrumb_link">
          {parentPageName}
        </Link>
        {shouldUseCustomBreadcrumbs && customBreadcrumbs.length === 3 && (
          <Link to={getBreadcrumbValues('uri', 1)} className="breadcrumb_link">
            {getBreadcrumbValues('title', 1)}
          </Link>
        )}
        <span className="current-page">{currentPageName}</span>
      </div>

      <ol className="hidden" itemScope itemType="https://schema.org/BreadcrumbList">
        <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem">
          <a
            itemProp="item"
            href={
              parentUrl && parentUrl !== '/'
                ? SITE_DOMAIN + getClearUrl(parentUrl)
                : `${SITE_DOMAIN}/`
            }
          >
            <span itemProp="name">{parentPageName}</span>
          </a>
          <meta itemProp="position" content="1" />
        </li>

        {shouldUseCustomBreadcrumbs && customBreadcrumbs.length === 3 && (
          <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem">
            <a itemProp="item" href={SITE_DOMAIN + getClearUrl(getBreadcrumbValues('uri', 1))}>
              <span itemProp="name">{getBreadcrumbValues('title', 1)}</span>
            </a>
            <meta itemProp="position" content="2" />
          </li>
        )}

        <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem">
          <a itemProp="item" href={SITE_DOMAIN + getClearUrl(currentUrl)}>
            <span itemProp="name">{currentPageName}</span>
          </a>
          <meta itemProp="position" content={shouldUseCustomBreadcrumbs ? '3' : '2'} />
        </li>
      </ol>
    </>
  );
};

Breadcrumbs.propTypes = {
  nodeTitle: PropTypes.string,
  parentBreadcrumb: PropTypes.string,
  parentTitle: PropTypes.string,
  location: PropTypes.string,
  isDefaultBreadcrumbs: PropTypes.bool,
  customBreadcrumbs: PropTypes.array,
};

export default Breadcrumbs;
