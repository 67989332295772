import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbar } from 'react-scrollbars-custom';

const trackYStyles = {
  width: 4,
  height: 'calc(100% - 10px)',
  top: 1,
  right: 5,
  backgroundColor: 'transparent',
  borderRadius: 5,
};

const trackXStyles = {
  height: 4,
  width: 'calc(100% - 10px)',
  left: 1,
  backgroundColor: 'transparent',
  borderRadius: 5,
};

const ScrollBar = ({ children }) => (
  <Scrollbar
    className="table-body-scroll"
    style={{ height: 600 }}
    trackYProps={{
      // eslint-disable-next-line react/prop-types
      renderer: ({ elementRef, ...restProps }) => (
        <div
          {...restProps}
          style={{
            ...restProps.style,
            ...trackYStyles,
          }}
          ref={elementRef}
        />
      ),
    }}
    trackXProps={{
      // eslint-disable-next-line react/prop-types
      renderer: ({ elementRef, ...restProps }) => (
        <div
          {...restProps}
          style={{
            ...restProps.style,
            ...trackXStyles,
          }}
          ref={elementRef}
        />
      ),
    }}
  >
    {children}
  </Scrollbar>
);

ScrollBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ScrollBar;
