import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

const scrollToElement = require('scroll-to-element');

const TableOfContents = ({
  tableRef,
  tableLinksRef,
  tableLinksWrapperRef,
  titles,
  activeIdLink,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    scrollToElement(e.target.hash, {
      offset: -110,
      duration: 600,
    });
  };

  return (
    <div className="table-of-contents" ref={tableRef}>
      <div className="table-of-contents-title">
        <h2>Table of contents</h2>
      </div>
      <div className="table-of-contents-menu" ref={tableLinksRef}>
        <div className="table-of-contents-menu-wrapper" ref={tableLinksWrapperRef}>
          {titles.map(({ title, id }) => (
            <a
              onClick={handleClick}
              className={CN('table-of-contents-item', { isActive: id === activeIdLink })}
              href={`#${id}`}
              key={id}
            >
              {title}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

TableOfContents.propTypes = {
  tableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  ]),
  tableLinksRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  ]),
  tableLinksWrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Object) }),
  ]),
  titles: PropTypes.array,
  activeIdLink: PropTypes.string,
};

export default TableOfContents;
