export const getCategoryTitleFromURL = (categories) => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const sections = url.split('/');
    let categorySlug = sections[sections.length - 2];

    // Check if the category slug ends with a number
    const lastChar = categorySlug.slice(-1);
    if (!Number.isNaN(parseInt(lastChar, 10))) {
      categorySlug = sections[sections.length - 3];
    }

    const category = categories.find(
      (cat) => cat.node.name.toLowerCase() === categorySlug.toLowerCase(),
    );

    if (category) {
      return `Blog - ${capitalizeFirstLetter(category.node.name)}`;
    }
  }

  return 'Blog category';
};

const getCategoryPath = (name) =>
  name
    .toLowerCase()
    .split(' ')
    .join('_')
    .replace('/', '_');

export default getCategoryPath;
