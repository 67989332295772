import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import CN from 'classnames';

import { Link } from 'components/Link';
import ArrowBottom from 'icons/ArrowBottom';

const AccordionDrawerItem = ({ item, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      <li className="item" role="presentation">
        <Link to={item.link.url} className="item-link" onClick={onClose}>
          {item.link.title}
        </Link>
        <button
          className={CN('next-btn', {
            expanded: isOpen,
          })}
          onClick={toggleAccordion}
          type="button"
        >
          <ArrowBottom />
        </button>
      </li>

      <div className="accordion-box">
        <Collapse isOpened={isOpen}>
          {item.subtree.map((nestedItem) => (
            <Link
              to={nestedItem.link.url}
              key={nestedItem.link.title}
              onClick={onClose}
              className="item-link"
            >
              {nestedItem.link.title}
            </Link>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

AccordionDrawerItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AccordionDrawerItem;
