import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';

import { SITE_DOMAIN } from 'globals';
import { getCategoryPath } from 'utils';

import { Link, ImgOrSvg } from 'components';

const isEqualDates = (created, updated) =>
  new Date(updated).getTime() === new Date(created).getTime();

const formatBlogDate = (dateString) =>
  new Date(dateString).toLocaleDateString('en-GB', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

const BlogTop = ({
  blogImage: { image, alt },
  tags,
  title,
  created,
  updated,
  readingTime,
  authors,
}) => {
  const isAuthorExist = authors?.length;
  const isEqual = isEqualDates(created, updated);

  return (
    <div className="blog-page-top">
      {image?.localFile && (
        <div className="blog-top-image">
          <ImgOrSvg image={image} alt={alt} className="blog-top-image-src" />
          <img
            className="hidden"
            src={SITE_DOMAIN + image.localFile.childImageSharp.fluid.src}
            alt={alt}
            itemProp="image"
          />
        </div>
      )}

      <div
        className={CN('blog-top-summary', {
          'absolute-pos': image && !!image.localFile,
        })}
      >
        <div className="blog-tags">
          {tags.map((tag) => (
            <Link
              key={tag.drupal_internal__tid}
              to={`/blog/category/${getCategoryPath(tag.name)}/`}
            >
              <span>{tag.name}</span>
            </Link>
          ))}
        </div>

        <h1 itemProp="headline" className="page-title">
          {title}
        </h1>

        <div className="wrapper">
          {!isEqual && (
            <span itemProp="dateModified" className="date" content={updated}>
              Last updated on {formatBlogDate(updated)}
            </span>
          )}
          <span
            itemProp="datePublished"
            className={CN('date', { hidden: !isEqual })}
            content={created}
          >
            {formatBlogDate(created)}
          </span>

          <span className="reading-time">{readingTime}</span>

          {isAuthorExist ? (
            <div
              itemProp="author"
              itemScope
              itemType="https://schema.org/Person"
              className="hidden"
            >
              <span itemProp="name">{authors[0].name}</span>
              {authors[0].path.alias && (
                <link itemProp="url" href={SITE_DOMAIN + authors[0].path.alias} />
              )}
            </div>
          ) : (
            <div
              itemProp="author"
              itemScope
              itemType="https://schema.org/Organization"
              className="hidden"
            >
              <span itemProp="name">AnyforSoft</span>
            </div>
          )}

          <div
            itemProp="publisher"
            itemScope
            itemType="https://schema.org/Organization"
            className="hidden"
          >
            <meta itemProp="name" content="Anyforsoft" />
            <div itemProp="logo" itemScope itemType="https://www.schema.org/ImageObject">
              <link itemProp="url" href="https://anyforsoft.com/anyforsoft_social.jpg" />
            </div>
          </div>
        </div>

        {isAuthorExist ? (
          <div className="authors">
            {authors.map((author) => {
              const authorUrl = author.path.alias;
              const isCreateAuthorPage = author.field_create_author_page;

              return (
                <div className="author" key={author.drupal_internal__tid}>
                  <div className="author-image">
                    {author.relationships.field_author_image?.localFile && (
                      <ImgOrSvg
                        image={author.relationships.field_author_image}
                        alt={author.field_author_image.alt}
                      />
                    )}
                  </div>

                  {isCreateAuthorPage && authorUrl !== '' && (
                    <Link to={`/blog/${authorUrl}/`} className="author-link" />
                  )}

                  <div className="author-details">
                    <div className="author-name">{author.name}</div>
                    <div className="author-position">{author.field_author_position}</div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty-author" />
        )}
      </div>
    </div>
  );
};

BlogTop.propTypes = {
  title: PropTypes.string,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string,
  readingTime: PropTypes.string,
  tags: PropTypes.array,
  blogImage: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    alt: PropTypes.string,
  }),
  authors: PropTypes.array,
};

export default BlogTop;
