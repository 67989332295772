import React from 'react';

const SocialIconRedesign = (props) => {
  const icon = props.iconType;

  switch (icon) {
    case 'facebook':
      return (
        <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.5556 0H2.44444C1.1 0 0 1.1 0 2.44444V19.5556C0 20.9012 1.1 22 2.44444 22H11V13.4444H8.55556V10.4194H11V7.91389C11 5.269 12.4813 3.41122 15.6029 3.41122L17.8066 3.41367V6.59756H16.3436C15.1287 6.59756 14.6667 7.50933 14.6667 8.35511V10.4207H17.8053L17.1111 13.4444H14.6667V22H19.5556C20.9 22 22 20.9012 22 19.5556V2.44444C22 1.1 20.9 0 19.5556 0Z"
            fill="white"
          />
        </svg>
      );
    case 'linkedin':
      return (
        <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.7206e-07 1.838C2.7206e-07 1.35053 0.193646 0.883032 0.538338 0.53834C0.88303 0.193648 1.35053 2.45031e-06 1.838 2.45031e-06H20.16C20.4016 -0.000392101 20.6409 0.0468654 20.8641 0.139069C21.0874 0.231273 21.2903 0.366612 21.4612 0.537339C21.6322 0.708065 21.7677 0.910826 21.8602 1.13401C21.9526 1.3572 22.0001 1.59643 22 1.838V20.16C22.0003 20.4016 21.9529 20.6409 21.8606 20.8642C21.7683 21.0875 21.6328 21.2904 21.462 21.4613C21.2912 21.6322 21.0884 21.7678 20.8651 21.8602C20.6419 21.9526 20.4026 22.0001 20.161 22H1.838C1.59655 22 1.35746 21.9524 1.1344 21.86C0.911335 21.7676 0.708671 21.6321 0.537984 21.4613C0.367297 21.2905 0.231932 21.0878 0.139623 20.8647C0.0473133 20.6416 -0.000131096 20.4025 2.7206e-07 20.161V1.838ZM8.708 8.388H11.687V9.884C12.117 9.024 13.217 8.25 14.87 8.25C18.039 8.25 18.79 9.963 18.79 13.106V18.928H15.583V13.822C15.583 12.032 15.153 11.022 14.061 11.022C12.546 11.022 11.916 12.111 11.916 13.822V18.928H8.708V8.388ZM3.208 18.791H6.416V8.25H3.208V18.79V18.791ZM6.875 4.812C6.88105 5.08668 6.83217 5.35979 6.73124 5.61532C6.63031 5.87084 6.47935 6.10364 6.28723 6.30003C6.09511 6.49643 5.8657 6.65248 5.61246 6.75901C5.35921 6.86554 5.08724 6.92042 4.8125 6.92042C4.53776 6.92042 4.26579 6.86554 4.01255 6.75901C3.7593 6.65248 3.52989 6.49643 3.33777 6.30003C3.14565 6.10364 2.99469 5.87084 2.89376 5.61532C2.79283 5.35979 2.74395 5.08668 2.75 4.812C2.76187 4.27286 2.98439 3.75979 3.36989 3.38269C3.75539 3.00558 4.27322 2.79442 4.8125 2.79442C5.35178 2.79442 5.86961 3.00558 6.25512 3.38269C6.64062 3.75979 6.86313 4.27286 6.875 4.812Z"
          />
        </svg>
      );
    case 'twitter':
      return (
        <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.6429 0H2.35714C1.0558 0 0 1.0558 0 2.35714V19.6429C0 20.9442 1.0558 22 2.35714 22H19.6429C20.9442 22 22 20.9442 22 19.6429V2.35714C22 1.0558 20.9442 0 19.6429 0ZM17.2415 7.79821C17.2513 7.93571 17.2513 8.07812 17.2513 8.21562C17.2513 12.4732 14.0103 17.379 8.08795 17.379C6.26116 17.379 4.56696 16.8487 3.14286 15.9353C3.40313 15.9647 3.65357 15.9746 3.91875 15.9746C5.42634 15.9746 6.81116 15.4638 7.91607 14.5996C6.50179 14.5701 5.31339 13.642 4.9058 12.3652C5.40179 12.4388 5.84866 12.4388 6.35938 12.3062C4.88616 12.0067 3.78125 10.7103 3.78125 9.14375V9.10446C4.20848 9.34509 4.70937 9.49241 5.23482 9.51205C4.79307 9.21821 4.43093 8.81958 4.18072 8.35173C3.93052 7.88388 3.80002 7.36136 3.80089 6.8308C3.80089 6.2317 3.95804 5.6817 4.23795 5.20536C5.82411 7.15982 8.2058 8.43661 10.8772 8.57411C10.4205 6.38884 12.0558 4.61607 14.0201 4.61607C14.9482 4.61607 15.783 5.00402 16.3723 5.63259C17.0991 5.49509 17.7964 5.225 18.4152 4.8567C18.1746 5.60312 17.6688 6.2317 17.0009 6.62946C17.6491 6.56071 18.2777 6.37902 18.8571 6.12857C18.4201 6.77187 17.8701 7.34152 17.2415 7.79821Z" />
        </svg>
      );
    default:
      return false;
  }
};

export default SocialIconRedesign;
