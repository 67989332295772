import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import { parser, sanitize } from 'utils/index';
import { Link } from 'components/Link';
import { ImgOrSvg } from 'components/ImgOrSvg';

import { CalendarIcon } from 'icons';

const HeroBanner = ({
  dataItem: {
    field_title,
    field_image,
    field_cta: { uri, title },
    field_description,
    relationships,
    internal: { type },
  },
}) => {
  const words = field_title.split(' ');
  const firstWord = words.shift();
  const restOfTitle = words.join(' ');
  const image = relationships?.field_image;
  const imageAlt = field_image.alt;

  return (
    <div className={CN(type, 'hero-header--top')}>
      <div className="hero-header--top-title">
        <h1 className="hero-title">
          <span className="first-word">{firstWord}</span> {restOfTitle}
        </h1>
      </div>

      <div className="hero-header--summary">
        {field_description && (
          <div className="hero-description">{parser(sanitize(field_description.processed))}</div>
        )}

        {uri && (
          <Link to={uri} className="btn hero-button">
            {title}
            <CalendarIcon />
          </Link>
        )}
      </div>

      {image && (
        <div className="hero-header--top-image">
          <ImgOrSvg
            image={image}
            alt={imageAlt}
            className="hero-header--top--image-src"
            objectFit="contain"
          />
        </div>
      )}
    </div>
  );
};

HeroBanner.propTypes = {
  dataItem: PropTypes.object,
};

export default HeroBanner;
