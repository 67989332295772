import React, { forwardRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import { SliderBtn } from 'components/SliderBtn';
import { TextSliderItem } from './components/TextSliderItem';

const SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  accessibility: true,
  slidesToShow: 1,
};

const TextSlider = forwardRef(({ data, onNext, onPrev, currentSlide }, ref) => {
  const isPrevBtnDisabled = currentSlide === 0;
  const isNextBtnDisabled = currentSlide === data.length - 1;

  return (
    <div className="text-slider-box">
      <div className="text-slider">
        <Slider {...SLIDER_SETTINGS} ref={ref} aria-label="Services Slider" id="Services">
          {data.length &&
            data.map((item, index) => {
              const isVisible = index === currentSlide;

              return (
                <TextSliderItem
                  key={item.drupal_id}
                  data={item}
                  index={index}
                  totalLength={data.length}
                  isVisible={isVisible}
                />
              );
            })}
        </Slider>
      </div>

      {data.length > 1 && (
        <div className="buttons-box">
          <SliderBtn prevBtn onClick={onPrev} disabled={isPrevBtnDisabled} />
          <SliderBtn onClick={onNext} disabled={isNextBtnDisabled} />
        </div>
      )}
    </div>
  );
});

TextSlider.propTypes = {
  data: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  currentSlide: PropTypes.number.isRequired,
};

TextSlider.displayName = 'TextSlider';

export default TextSlider;
