import { BLOG_CATEGORIES_DESCRIPTION } from 'globals';

/**
 *
 * @param path - string
 * @returns {*}
 *
 * function returns path without numeric string value
 * e.g. - '/blog/2/' -> '/blog/', '/blog/category/business/2/' -> '/blog/category/business/'
 */

const getUrlWithoutPageNumber = (path) =>
  path
    .split('/')
    .reduce((curr, next) => {
      if (Number.isNaN(parseInt(next, 10))) {
        return [...curr, next];
      }
      return [...curr];
    }, [])
    .join('/');

/**
 * Extracts the author name from the URL and converts it to a readable format.
 * @param {string} url - The URL containing the author name.
 * @returns [formattedName, urlName] - The readable format of the author name ond url.
 */
const getAuthorNameFromURL = (url) => {
  const matches = url.match(/\/blog\/([a-z0-9-]+)/i);

  if (matches && matches.length > 1) {
    const author = matches[1];
    // Split the author name by hyphens and capitalize each word
    const words = author.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with spaces to form the readable format
    return [words.join(' '), author];
  }

  return '';
};

/**
 *
 * @param url - string
 * @param currentPage - number
 * @param numPagesCategory - number
 * @param isAuthorPage - boolean
 * @param categoryName - string
 * @returns {
 *  {
 *    isFirst: boolean,
 *    isLast: boolean,
 *    nextPage: string,
 *    paginationPath,
 *    prevPage: string,
 *    metaTitle: string,
 *    metaDescription: string,
 *  }
 * }
 */

const getPageInfo = (
  url,
  currentPage,
  numPagesCategory,
  categoryName = null,
  isAuthorPage = false,
) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPagesCategory;
  const authorName = getAuthorNameFromURL(url);
  const blogUrl = 'blog/';
  const authorUrl = `blog/${authorName[1]}/`;
  const categoryUrl = categoryName ? `category/${categoryName}/` : '';
  const baseUrl = isAuthorPage ? authorUrl : blogUrl;
  const authorDescription = `Explore the collection of blog posts by ${authorName[0]}. Visit AnyforSoft's blog for more engaging content, valuable insights and helpful tips.
`;

  const prevPage =
    currentPage - 1 === 1
      ? `${baseUrl}${categoryUrl}`
      : `${baseUrl}${categoryUrl}${currentPage - 1}/`;
  const nextPage = `${baseUrl}${categoryUrl}${currentPage + 1}/`;
  const paginationPath = getUrlWithoutPageNumber(url);

  const metaTitle = `${isAuthorPage ? `Author Page - ${authorName[0]}` : 'Blog'} | ${
    categoryName ? `${categoryName[0].toUpperCase() + categoryName.slice(1)} | ` : ''
  }AnyforSoft${currentPage > 1 ? ` - Page ${currentPage}` : ''}`;

  const metaDescription = `${
    isAuthorPage ? authorDescription : BLOG_CATEGORIES_DESCRIPTION[categoryName || 'blog']
  }${currentPage > 1 ? ` - Page ${currentPage}` : ''}`;

  return {
    paginationPath,
    isFirst,
    isLast,
    prevPage,
    nextPage,
    metaTitle,
    metaDescription,
  };
};

export default getPageInfo;
