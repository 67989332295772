import React from 'react';
import PropTypes from 'prop-types';

import { CaseSummaryTags, DesktopMedia, MobileMedia } from './components';

const CaseSummary = ({
  dataItem: {
    internal: { type },
    field_what_to_display,
    field_mobile_case,
    field_video_autoplay_status,
    field_video_url,
    field_team,
    field_year_launched,
    field_image,
    relationships: {
      field_image: image,
      field_video_file_preview,
      field_video,
      field_case_summary_tags,
      field_mobile_images,
    },
  },
}) => (
  <div className={type}>
    {field_mobile_case ? (
      <MobileMedia field_mobile_images={field_mobile_images} />
    ) : (
      <DesktopMedia
        field_what_to_display={field_what_to_display}
        data={{
          image,
          field_image,
          field_video,
          field_video_file_preview,
          field_video_url,
          field_video_autoplay_status,
        }}
      />
    )}
    <CaseSummaryTags
      field_case_summary_tags={field_case_summary_tags}
      field_team={field_team}
      field_year_launched={field_year_launched}
    />
  </div>
);

CaseSummary.propTypes = {
  dataItem: PropTypes.shape({
    internal: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
    field_what_to_display: PropTypes.string,
    field_mobile_case: PropTypes.bool,
    field_video_autoplay_status: PropTypes.bool,
    field_video_url: PropTypes.shape({
      uri: PropTypes.string,
    }),
    field_team: PropTypes.string,
    field_year_launched: PropTypes.string,
    field_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_image: PropTypes.object,
      field_video_file_preview: PropTypes.object,
      field_video: PropTypes.object,
      field_case_summary_tags: PropTypes.array,
      field_mobile_images: PropTypes.object,
    }),
  }),
};

export default CaseSummary;
