import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import CN from 'classnames';

import { parser, sanitize } from 'utils';

const TechnicalSolution = ({
  dataItem: {
    field_title,
    field_description,
    field_technical_solution,
    internal: { type },
  },
}) => (
  <div className="technical-solution">
    <div className={type}>
      <div className="paragraph__technical_solution-top">
        <div>
          <h2>{field_title}</h2>
          {field_description && (
            <div className="description">{parser(sanitize(field_description.processed))}</div>
          )}
        </div>
      </div>
      <div className="paragraph__technical_solution-bottom">
        {field_technical_solution &&
          field_technical_solution.map((item, i) => (
            <div
              key={uid(item)}
              className={CN('paragraph__technical_solution-bottom-item', i % 2 ? 'odd' : 'even')}
            >
              {parser(sanitize(item.processed))}
            </div>
          ))}
      </div>
    </div>
  </div>
);

TechnicalSolution.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_description: PropTypes.object,
    field_technical_solution: PropTypes.array,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
  }),
};

export default TechnicalSolution;
