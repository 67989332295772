import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';

import { parser, sanitize } from 'utils';

import { Arrow } from 'icons';
import { ImgOrSvg, Link } from 'components';

const TechnologyItem = ({
  data: {
    name,
    description,
    field_technology_image: image,
    relationships: { field_technology_image },
    field_link,
  },
  isActive,
}) => {
  const [height, setHeight] = useState(0);

  const itemHeight = 260 + height < 400 ? 400 : 260 + height;
  const activeItemHeight = isActive ? itemHeight : 247;

  const measuredRef = (node) => {
    if (node !== null) {
      setTimeout(() => {
        setHeight(node.getBoundingClientRect().height);
      }, 0);
    }
  };

  return (
    <div className="technology-item" style={{ height: `${activeItemHeight}px` }}>
      <div className="technology-item-background-element" />

      {field_technology_image && (
        <div className="technology-icon-box">
          <ImgOrSvg image={field_technology_image} className="technology-icon" alt={image.alt} />
        </div>
      )}

      <div className="technology-title">
        <TextTruncate
          containerClassName="title"
          line={2}
          element="h3"
          truncateText=" …"
          text={name}
        />
      </div>

      <div className="technology-description" ref={measuredRef}>
        {description && <div>{parser(sanitize(description.processed))}</div>}
      </div>

      {field_link && (
        <div className="technology-discover-more">
          <Link to={field_link.uri}>
            {field_link.title || 'See More'} <Arrow />
          </Link>
        </div>
      )}
    </div>
  );
};

TechnologyItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    field_technology_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.object,
    relationships: PropTypes.object,
    field_link: PropTypes.object,
  }),
  isActive: PropTypes.bool,
};

export default TechnologyItem;
