import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components';
import { CalendarIcon } from 'icons';

const CTACalendly = ({
  dataItem: {
    field_title,
    field_subtitle,
    field_link: { title, uri },
  },
}) => (
  <div className="paragraph-cta-calendly">
    <div className="paragraph-cta-calendly__left">
      <h3 className="paragraph-cta-calendly__title">{field_title}</h3>
      <div className="paragraph-cta-calendly__description">{field_subtitle}</div>
    </div>
    <div className="paragraph-cta-calendly__right">
      <Link to={uri} className="btn paragraph-cta-calendly__btn">
        {title}
        <CalendarIcon />
      </Link>
    </div>
  </div>
);

CTACalendly.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    field_subtitle: PropTypes.string,
    field_link: PropTypes.shape({
      uri: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default CTACalendly;
