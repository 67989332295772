/**
 * Defines all paragraphs templates'.
 */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Achievements,
  Awards,
  BlogBannerWithButton,
  BlogBannerWithoutButton,
  CaseSummary,
  Challenge,
  Community,
  ContactInfo,
  CTABlock,
  DescriptionWithMembers,
  DevelopmentServices,
  FAQ,
  Features,
  Feedback,
  Forms,
  Functionality,
  HeadingWithLink,
  HighlightedText,
  Image,
  Industries,
  Metrics,
  Offices,
  OurNewsWrapper,
  OurTeam,
  Process,
  Review,
  Results,
  Services,
  StoriesList,
  StoriesWrapper,
  TeamPictures,
  TechnicalExpertise,
  TechnicalSolution,
  TechnicalSolutionsList,
  Technologies,
  TechnologyList,
  TechnologyStack,
  Text,
  TextGrid,
  TitleWithDescription,
  TitleWithDescriptionAndImage,
  Tools,
  TwoColumnText,
  Verticals,
  Video,
  Wps,
  CaseSolution,
  CaseFunctionality,
  CaseStudies,
  CTAWithForm,
  CTACalendly,
  Statistic,
} from './Types';

const Paragraphs = ({ data }) => {
  // Set paragraph component by type.
  const paragraphType = data.internal?.type;
  if (!paragraphType) return null;
  const paragraphs = {
    paragraph__verticals: <Verticals dataItem={data} />,
    paragraph__technologies: <Technologies dataItem={data} />,
    paragraph__our_news: <OurNewsWrapper dataItem={data} />,
    paragraph__stories: <StoriesWrapper dataItem={data} />,
    paragraph__stories_list: <StoriesList dataItem={data} />,
    paragraph__what_people_say: <Wps dataItem={data} />,
    paragraph__contact_information: <ContactInfo dataItem={data} />,
    paragraph__awards: <Awards dataItem={data} />,
    paragraph__forms: <Forms dataItem={data} />,
    paragraph__services: <Services dataItem={data} />,
    paragraph__title_with_description: <TitleWithDescription dataItem={data} />,
    paragraph__title_with_description_and_image: <TitleWithDescriptionAndImage dataItem={data} />,
    paragraph__paragraph_feedback: <Feedback dataItem={data} />,
    paragraph__technical_solution_list: <TechnicalSolutionsList dataItem={data} />,
    paragraph__our_team: <OurTeam dataItem={data} />,
    paragraph__heading_with_link: <HeadingWithLink dataItem={data} blueBg="blue-bg" />,
    paragraph__text: <Text dataItem={data} />,
    paragraph__image: <Image dataItem={data} />,
    paragraph__cta_block: <CTABlock dataItem={data} blueBg="blue-bg" />,
    paragraph__offices: <Offices dataItem={data} />,
    paragraph__community: <Community dataItem={data} />,
    paragraph__team_pictures: <TeamPictures dataItem={data} />,
    paragraph__metrics: <Metrics dataItem={data} />,
    paragraph__functionality: <Functionality dataItem={data} />,
    paragraph__technical_expertise: <TechnicalExpertise dataItem={data} />,
    paragraph__companies: <TechnicalExpertise dataItem={data} />,
    paragraph__features: <Features dataItem={data} />,
    paragraph__technical_solution: <TechnicalSolution dataItem={data} />,
    paragraph__achievements: <Achievements dataItem={data} />,
    paragraph__development_services: <DevelopmentServices dataItem={data} />,
    paragraph__highlighted_text: <HighlightedText dataItem={data} />,
    paragraph__description_with_members: <DescriptionWithMembers dataItem={data} />,
    paragraph__technology: <TechnologyList dataItem={data} />,
    paragraph__text_grid: <TextGrid dataItem={data} />,
    paragraph__two_column_text: <TwoColumnText dataItem={data} />,
    paragraph__tools: <Tools dataItem={data} />,
    paragraph__process: <Process dataItem={data} />,
    paragraph__blog_banner_without_button: <BlogBannerWithoutButton dataItem={data} />,
    paragraph__blog_banner_with_button: <BlogBannerWithButton dataItem={data} />,
    paragraph__video: <Video dataItem={data} />,
    paragraph__technology_stack: <TechnologyStack dataItem={data} />,
    paragraph__industries: <Industries dataItem={data} />,
    paragraph__faq: <FAQ dataItem={data} />,
    paragraph__case_summary: <CaseSummary dataItem={data} />,
    paragraph__challenge: <Challenge dataItem={data} />,
    paragraph__review: <Review dataItem={data} />,
    paragraph__results: <Results dataItem={data} />,
    paragraph__case_solution: <CaseSolution dataItem={data} />,
    paragraph__case_functionality: <CaseFunctionality dataItem={data} />,
    paragraph__case_studies: <CaseStudies dataItem={data} />,
    paragraph__cta_with_form: <CTAWithForm dataItem={data} />,
    paragraph__cta_calendly: <CTACalendly dataItem={data} />,
    paragraph__statistic_block: <Statistic dataItem={data} />,
  };

  return paragraphs[paragraphType];
};

Paragraphs.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Paragraphs;
