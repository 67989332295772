import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';

import { useForceUpdate, useWindowSize } from 'hooks';

import { ImgOrSvg } from 'components/index';
import { DefaultAvatar } from 'icons';

const ReviewItem = ({
  data: {
    title,
    field_description,
    field_position,
    field_company,
    field_country_flag: { alt },
    relationships: { field_client_main_image: image, field_country_flag },
    field_client_main_image,
  },
  slideIndex,
}) => {
  const [textRowsNumber, setTextRowsNumber] = useState(5);
  // Used as force component update (related with TextTruncate initial render bug)
  const forceUpdate = useForceUpdate();
  const { width } = useWindowSize();

  const isTabletDevice = width <= 767 && width > 599;
  const isMobileDevice = width <= 599;
  const mobileTextRows = isMobileDevice ? 6 : 3;
  const initialRowsNumber = isTabletDevice ? 4 : mobileTextRows;

  const imgAlt = field_client_main_image?.alt || 'Client';

  useEffect(() => {
    forceUpdate();
    setTextRowsNumber(initialRowsNumber);
  }, []);

  const handleExpand = () => {
    setTextRowsNumber(0);
  };

  const renderShowMoreElement = () => (
    <button type="button" onClick={handleExpand} className="more-text">
      Show more
    </button>
  );

  useEffect(() => {
    if (textRowsNumber !== initialRowsNumber) {
      setTextRowsNumber(initialRowsNumber);
    }
  }, [slideIndex]);

  return (
    <div className="review-item">
      <div className="holder">
        <div className="review-left">
          <div className="review-author-description">
            <div className="review-author-name">
              <ImgOrSvg className="country-flag" image={field_country_flag} alt={alt} />
              <span>{title}</span>
            </div>

            {(field_position || field_company) && (
              <div className="review-position-and-company">
                {field_position && <span className="review-position">{field_position}</span>}{' '}
                {field_company && <span className="review-company">{field_company}</span>}
              </div>
            )}
          </div>

          <div className="review-text">
            <TextTruncate
              className="review-desc"
              line={textRowsNumber}
              textTruncateChild={renderShowMoreElement()}
              element="span"
              truncateText=" …"
              text={field_description.value.replace(/(<([^>]+)>)/gi, '')}
            />
          </div>
        </div>

        <div className="review-right">
          {image?.localFile ? (
            <ImgOrSvg className="review-author-photo" image={image} alt={imgAlt} quality={100} />
          ) : (
            <DefaultAvatar className="default-avatar-icon" />
          )}
        </div>
      </div>
    </div>
  );
};

ReviewItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    field_position: PropTypes.string,
    field_company: PropTypes.string,
    field_description: PropTypes.object,
    field_country: PropTypes.string,
    field_client_main_image: PropTypes.shape({
      alt: PropTypes.string,
    }),
    field_country_flag: PropTypes.shape({
      alt: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_client_main_image: PropTypes.object,
      field_country_flag: PropTypes.object,
    }),
  }),
  slideIndex: PropTypes.number,
};

export default ReviewItem;
