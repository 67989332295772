import React from 'react';
import PropTypes from 'prop-types';

import { getPostContent } from 'utils';

import { FirstBlogCard } from 'components/Blog';
import { BlogCard } from 'components';

const BlogList = ({ posts }) => (
  <div className="blog-list">
    <div className="blog-list-container">
      {posts.map(({ node }, index) => {
        const { blogImage, blogHeaderImage, summary } = getPostContent(node);

        return index ? (
          <BlogCard
            image={blogImage.image}
            alt={blogImage.alt}
            category={node.relationships.field_blog_category}
            title={node.title}
            path={node.path.alias}
            readingTime={node.field_reading_time}
            key={node.drupal_id}
          />
        ) : (
          <FirstBlogCard
            blogHeaderImage={blogHeaderImage}
            tags={node.relationships.field_blog_category}
            readingTime={node.field_reading_time}
            title={node.title}
            path={node.path.alias}
            summary={summary}
            key={node.drupal_id}
          />
        );
      })}
    </div>
  </div>
);

BlogList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.object,
    }),
  ),
};

export default BlogList;
