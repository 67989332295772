import React from 'react';
import PropTypes from 'prop-types';

import { ImgOrSvg } from 'components/ImgOrSvg';

const MediaSliderItem = ({
  data: {
    title,
    field_image: alt,
    relationships: { field_image },
  },
}) => (
  <div className="media-slider-item">
    {field_image && (
      <div className="item-icon">
        <ImgOrSvg image={field_image} alt={alt.alt} />
      </div>
    )}
    <h3 className="item-title">{title}</h3>
  </div>
);

MediaSliderItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MediaSliderItem;
