import React from 'react';
import PropTypes from 'prop-types';
import { parser, sanitize } from 'utils/index';
import { DynamicSubheading, EventHeader } from 'components/Header/components';
import { VideoPlayer, Link, ImgOrSvg } from 'components';

import bigLogo from 'components/Header/Images/logo-big.svg';
import { CalendarIcon } from 'icons';

const HeaderParagraph = ({ data, showHeaderForm, type, nodeTitle }) => {
  const frontPage = type === 'front' && type !== 'inner-page';
  const discoverMoreLinks = data?.field_discover_more_buttons;

  const scrollToAnchor = (event) => {
    if (data.field_discover_more?.uri.includes('#')) {
      event.preventDefault();
      const id = data.field_discover_more?.uri.replace('internal:#', '');
      const target = document.getElementById(id);

      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (showHeaderForm === 'event') {
    return <EventHeader headerData={data} />;
  }

  return data ? (
    <div className="header-bottom">
      <div className="header-image-text">
        <div className="amplify">
          {nodeTitle && <h1 className="header-page-title">{parser(sanitize(nodeTitle))}</h1>}

          {data.field_description && (
            <div className="header-page-description">
              {parser(sanitize(data.field_description.processed))}
              {frontPage && data.field_dynamic_subheading && (
                <DynamicSubheading dynamicSubheading={data.field_dynamic_subheading} />
              )}
            </div>
          )}

          {frontPage && discoverMoreLinks && (
            <div className="header-page-button">
              {discoverMoreLinks.map(({ title, uri }) => (
                <Link
                  key={title}
                  to={uri}
                  className={
                    type === 'front stories-list'
                      ? 'btn--arrow btn--arrow-yellow button'
                      : 'btn white'
                  }
                >
                  {title}
                </Link>
              ))}
            </div>
          )}

          {!frontPage && data.field_discover_more && (
            <div className="header-page-button">
              <Link
                to={data.field_discover_more.uri}
                onClick={scrollToAnchor}
                className={
                  type === 'front stories-list' || type === 'basic-page'
                    ? 'btn--arrow btn--arrow-yellow button'
                    : 'btn'
                }
              >
                {data.field_discover_more.title}
                {type === 'front technology-page' ? <CalendarIcon /> : null}
              </Link>
            </div>
          )}
        </div>

        {data.field_background_type === 'with_logo' && (
          <div className="header-bottom--logo">
            <img src={bigLogo} alt="logo" />
          </div>
        )}

        {data.field_background_type === 'with_video' && (
          <div className="header-bottom--video">
            <VideoPlayer
              video_file={data.relationships.field_video?.localFile?.publicURL}
              video_preview={data.relationships.field_video_file_preview?.localFile?.publicURL}
              id={data.relationships.field_video?.drupal_id}
              autoplay_status={data.field_video_autoplay_status}
              field_link={data.field_video_url?.uri}
            />
          </div>
        )}

        {data.field_background_type === 'with_side_image' &&
          data.relationships?.field_image?.localFile && (
            <div className="header-bottom--logo static">
              <div className="header-bottom--logo__inner">
                <ImgOrSvg
                  image={data.relationships.field_image}
                  alt={data.field_image.alt}
                  loading="eager"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  ) : null;
};

HeaderParagraph.propTypes = {
  data: PropTypes.object.isRequired,
  showHeaderForm: PropTypes.string,
  type: PropTypes.string.isRequired,
  nodeTitle: PropTypes.string,
};

export default HeaderParagraph;
