import React from 'react';
import PropTypes from 'prop-types';

import { TechnologyItem } from './TechnologyItem';

const Technologies = ({
  dataItem: {
    field_title,
    internal: { type },
    relationships: { field_reference },
  },
}) => (
  <div className={`without-wave ${type}`}>
    <div id={type} className="anchor-for-menu" />
    <h2>
      <span>{field_title}</span>
    </h2>
    <div>
      <div className="technology-list">
        {field_reference &&
          field_reference.map((item) => <TechnologyItem key={item.drupal_id} data={item} />)}
      </div>
    </div>
  </div>
);

Technologies.propTypes = {
  dataItem: PropTypes.shape({
    field_title: PropTypes.string,
    internal: PropTypes.shape({
      type: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      field_reference: PropTypes.array,
    }),
  }).isRequired,
};

export default Technologies;
