import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/Link';
import NestedDrawerNavigation from 'components/Header/components/DrawerNavigation/NestedDrawerNavigation';

const DrawerNavigation = ({ mainMenuLinks, onClose }) => (
  <nav className="drawer-nav">
    <ul className="drawer-nav__list">
      {mainMenuLinks.map((item) => {
        const isNestedNavigation = item.subtree?.length > 0;

        return isNestedNavigation ? (
          <NestedDrawerNavigation key={item.link.title} item={item} onClose={onClose} />
        ) : (
          <li key={item.link.title} className="item single" role="presentation">
            <Link to={item.link.url} onClick={onClose} className="item-link">
              {item.link.title}
            </Link>
          </li>
        );
      })}
    </ul>
  </nav>
);

DrawerNavigation.propTypes = {
  mainMenuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DrawerNavigation;
