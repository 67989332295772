import React from 'react';
import PropTypes from 'prop-types';

import { SOCIAL_LINKS } from 'globals';

import { SocialIcon } from 'icons';

const Socials = ({ socialLinks }) => {
  const platforms = Object.entries(socialLinks[0].node.settings.platforms).sort(
    (a, b) => a[1].weight - b[1].weight,
  );

  return (
    <ul className="social-links">
      {platforms.map(([platformName, { value }]) => (
        <li key={platformName} className={`link-item ${platformName.toLowerCase()}`}>
          <a href={SOCIAL_LINKS[platformName] + value} target="_blank" rel="noopener noreferrer">
            <SocialIcon iconType={platformName.toLowerCase()} />
          </a>
        </li>
      ))}
    </ul>
  );
};

Socials.propTypes = {
  socialLinks: PropTypes.arrayOf(PropTypes.object),
};

export default Socials;
