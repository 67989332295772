import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import CN from 'classnames';

import { getGeoInfo } from 'api';
import { isTesting } from 'utils';

import { FloatingBar, Header, Footer, Cookies } from 'components';

const query = graphql`
  query Layout {
    ...LayoutQuery
  }
`;

const Layout = ({
  children,
  headerData,
  nodeTitle,
  type,
  showHeaderForm,
  parentBreadcrumb,
  customBreadcrumbs,
  isDefaultBreadcrumbs,
  parentTitle,
  isAlternativeHeader,
}) => {
  const {
    site: {
      siteMetadata: { title: siteTitle },
    },
    menuTree: { items: menuLinks },
    allBlockContentReviews: { edges: reviews },
    allBlockBlock: { edges: socialLinks },
    allBlockContentFooterBlock: { edges: footerInfo },
    allBlockContentFloatingBar: { edges: floatingBar },
  } = useStaticQuery(query);
  const location = useLocation();

  const floridaRedirectingUrls =
    location.pathname === '/' || location.pathname === '/service/custom-web-development';
  const isFirstVisitFromFlorida =
    typeof window !== 'undefined' && !localStorage.getItem('floridaUser');

  useEffect(() => {
    if (isFirstVisitFromFlorida && floridaRedirectingUrls) {
      getGeoInfo()
        .then(({ data: { region } }) => {
          const isUserFromFlorida = region === 'Florida';
          if (isUserFromFlorida) {
            if (typeof window !== 'undefined') {
              localStorage.setItem('floridaUser', 'visited');
            }
            navigate('/fl/service/custom-web-development/');
          }
        })
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    if (location.hash === '#contact') {
      navigate('/contact-us/');
    }
  }, [location.hash]);

  return (
    <>
      <FloatingBar node={floatingBar[0].node} />
      <div className={CN('main-wrapper', { 'testing-mode': isTesting })}>
        <Header
          type={type}
          siteTitle={siteTitle}
          mainMenuLinks={menuLinks}
          headerData={headerData}
          nodeTitle={nodeTitle}
          showHeaderForm={showHeaderForm}
          parentBreadcrumb={parentBreadcrumb}
          customBreadcrumbs={customBreadcrumbs}
          isDefaultBreadcrumbs={isDefaultBreadcrumbs}
          parentTitle={parentTitle}
          reviews={reviews}
          socialLinks={socialLinks}
          location={location.pathname}
          hash={location.hash}
          isAlternativeHeader={isAlternativeHeader}
        />

        <div className="container">
          <main>{children}</main>
          <Footer socialLinks={socialLinks} footerInfo={footerInfo[0]} />
        </div>
      </div>
      <Cookies />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerData: PropTypes.array,
  customBreadcrumbs: PropTypes.array,
  nodeTitle: PropTypes.string,
  type: PropTypes.string,
  showHeaderForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  parentBreadcrumb: PropTypes.string,
  parentTitle: PropTypes.string,
  isAlternativeHeader: PropTypes.bool,
  isDefaultBreadcrumbs: PropTypes.bool,
};
Layout.defaultProps = {
  headerData: [],
};
export default Layout;
