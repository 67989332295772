import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/index';

const Wrapper = ({ field_display_as_link, children }) => {
  const uri = field_display_as_link?.uri;

  return uri ? <Link to={uri}>{children}</Link> : <>{children}</>;
};

Wrapper.propTypes = {
  field_display_as_link: PropTypes.shape({
    uri: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

Wrapper.defaultProps = {
  field_display_as_link: null,
};

export default Wrapper;
