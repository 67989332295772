import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Obfuscate from 'react-obfuscate';
import { Collapse } from 'react-collapse';
import { useCookies } from 'react-cookie';
import CN from 'classnames';

import { useWindowSize } from 'hooks';
import { parser, sanitize } from 'utils';

import { ArrowBottom, Arrow, ContactIcon, FooterLogo } from 'icons';
import { Navigation, Socials, ImgOrSvg, Link } from 'components';

const Footer = ({
  socialLinks,
  footerInfo: {
    node: {
      body: { value },
      relationships: { field_awards },
      field_email,
      field_tel,
      field_tel_formatted,
    },
  },
}) => {
  const {
    footerMenu: { secondaryMenu, primaryMenu },
  } = useStaticQuery(graphql`
    query {
      footerMenu: footerMenuTree {
        secondaryMenu {
          menuItem {
            link {
              title
              url
              enabled
            }
          }
        }
        primaryMenu {
          link {
            title
            url
            enabled
          }
        }
      }
    }
  `);
  const [isMobile, setMobile] = useState(false);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);
  const { width } = useWindowSize();
  const [, , removeCookie] = useCookies();

  const handleOpenCookie = () => {
    removeCookie('CookieConsent', { path: '/' });
  };

  const toggleAccordion = (index) => () => {
    if (index === activeAccordionIndex) {
      setActiveAccordionIndex(-1);
    } else {
      setActiveAccordionIndex(index);
    }
  };

  useEffect(() => {
    setMobile(width <= 767);
  }, [width]);

  return (
    <footer className="footer">
      <span className="green-light-bg__inner" />

      <div className="footer-header">
        <Link className="footer-header__logo" to="/">
          <FooterLogo />
        </Link>

        <Navigation primaryMenu={primaryMenu} />
      </div>

      <div className="footer-content">
        {isMobile ? (
          <ul className="footer-content__navigation footer-accordion">
            {secondaryMenu.map(({ menuItem }, index) => {
              const mainItem = menuItem[0]?.link;
              const hasNestedLinks = menuItem.length - 1 > 0;
              const isOpen = index === activeAccordionIndex;

              if (mainItem) {
                return (
                  <li
                    className={CN('item', {
                      expandedItem: isOpen,
                    })}
                    role="presentation"
                  >
                    <div className="item-box">
                      <Link to={mainItem.url} className="item-link">
                        {mainItem.title}
                      </Link>
                      <button
                        className="accordion__button"
                        onClick={toggleAccordion(index)}
                        type="button"
                      >
                        <ArrowBottom />
                      </button>
                    </div>
                    <Collapse isOpened={isOpen}>
                      {hasNestedLinks && (
                        <ul className="sub-navigation__list">
                          {[...menuItem].slice(1).map(({ link: { title, url } }) => (
                            <li className="navigation__sub-item" key={title}>
                              <Link to={url}>
                                {title} <Arrow />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Collapse>
                  </li>
                );
              }
              return <React.Fragment key="empty menu" />;
            })}
          </ul>
        ) : (
          <ul className="footer-content__navigation">
            {secondaryMenu.map(({ menuItem }) => {
              const hasNestedLinks = menuItem.length > 0;

              return (
                <React.Fragment key={`${menuItem[0]?.link.title}desktop`}>
                  {hasNestedLinks && (
                    <li className="navigation__main-item">
                      <ul className="sub-navigation__list">
                        {menuItem.map(({ link: { title, url } }, index) => {
                          const isMainItem = index === 0;

                          return (
                            <React.Fragment key={`${title}desktop`}>
                              {isMainItem ? (
                                <li className="navigation__main-link">
                                  <Link to={url}>{title}</Link>
                                </li>
                              ) : (
                                <li className="navigation__sub-link">
                                  <Link to={url}>{title}</Link>
                                </li>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </ul>
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        )}

        <div className="footer-content__contact-information">
          <div className="footer-awards">
            {field_awards &&
              field_awards.map((item) => (
                <Link key={item.field_image.alt} to={item.field_discover_more.uri}>
                  <ImgOrSvg image={item.relationships.field_image} alt={item.field_image.alt} />
                </Link>
              ))}
          </div>

          <div className="our-addresses">
            {value && <div className="info addresses">{parser(sanitize(value))}</div>}
          </div>

          <div className="contact-us">
            {field_email && (
              <div className="info email">
                <Obfuscate email={field_email} linkText=" " />
                <ContactIcon iconType="email" />
              </div>
            )}

            {field_tel && field_tel_formatted && (
              <div className="info call">
                <Obfuscate tel={field_tel} linkText=" ">
                  {field_tel_formatted}
                </Obfuscate>
                <ContactIcon iconType="call" />
              </div>
            )}
          </div>

          <Socials socialLinks={socialLinks} />
        </div>
      </div>

      <div className="footer-bottom">
        <div className="copyright">
          Created by <strong>AnyforSoft</strong> / All Rights Reserved
        </div>

        <div className="privacy-policy-links">
          <Link to="/privacy-policy/">Privacy policy</Link>
          <Link to="/terms-service/">Terms of Service</Link>
          <button type="button" onClick={handleOpenCookie}>
            Cookie Settings
          </button>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  socialLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  footerInfo: PropTypes.shape({
    node: PropTypes.shape({
      body: PropTypes.shape({
        value: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        field_awards: PropTypes.arrayOf(PropTypes.object),
      }),
      field_email: PropTypes.string,
      field_tel: PropTypes.string,
      field_tel_formatted: PropTypes.string,
    }),
  }),
};
export default Footer;
