import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick/lib/slider';
import Obfuscate from 'react-obfuscate';

import { parser, sanitize } from 'utils';

import { ImgOrSvg } from 'components';

const OurTeamSlider = ({ rel, rel: { length } }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    customPaging(i) {
      const total = Math.ceil(length / this.slidesToShow);
      return (
        <>
          <span className="current">{`0${i + 1} `}</span>/{' '}
          <span className="total">{`0${total}`}</span>
        </>
      );
    },
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {rel &&
        rel.map(
          (
            {
              id,
              title,
              field_position,
              field_linkedin,
              field_email,
              field_description,
              field_image: { alt },
              relationships: { field_image },
            },
            index,
          ) => {
            // @todo need to restrict duplicate field reference in drupal side
            const newKey = id + index;

            return (
              <div key={newKey} className="member">
                <div className="member-top">
                  {field_image && (
                    <div className="member-list__image">
                      <ImgOrSvg image={field_image} alt={alt} />
                    </div>
                  )}

                  <div className="member-name-position-social">
                    {title && <h4 className="name">{title}</h4>}
                    {field_position && <div className="position">{field_position}</div>}
                    <div className="bottom-socials">
                      {field_linkedin && (
                        <div className="linkedin">
                          <a href={field_linkedin} target="_blank" rel="noopener noreferrer">
                            {field_linkedin}
                          </a>
                        </div>
                      )}
                      {field_email && (
                        <div className="email">
                          <Obfuscate email={field_email} linkText=" ">
                            {field_email}
                          </Obfuscate>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="member-bottom">
                  {field_description && (
                    <div className="description">
                      {parser(sanitize(field_description.processed))}
                    </div>
                  )}
                </div>
              </div>
            );
          },
        )}
    </Slider>
  );
};

OurTeamSlider.propTypes = {
  rel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      field_description: PropTypes.shape({
        processed: PropTypes.string,
      }),
      field_email: PropTypes.string,
      field_linkedin: PropTypes.string,
      field_position: PropTypes.string,
      field_image: PropTypes.shape({
        alt: PropTypes.string,
      }),
      relationships: PropTypes.shape({
        field_image: PropTypes.object,
      }),
    }),
  ),
};

export default OurTeamSlider;
